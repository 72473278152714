// This should match the server and react-client. I couldn't make a symlink.
const Permissions: {
  SUPER_CONFIGURATION: 'Can get on config page as an administrator',
  CREATE_UPDATE_CP: 'Can create and modify CPs',
  IMPACT_CP_ON_TEAM: 'Can impact a CP',
  MANAGE_USERS_ON_TEAM: 'Can assign users to teams when a team owner'
} = {
  SUPER_CONFIGURATION: 'Can get on config page as an administrator',
  CREATE_UPDATE_CP: 'Can create and modify CPs',
  IMPACT_CP_ON_TEAM: 'Can impact a CP',
  MANAGE_USERS_ON_TEAM: 'Can assign users to teams when a team owner'
}

export default Permissions

export type PossiblePermissionStrings = typeof Permissions[keyof typeof Permissions]
