import React from 'react'

/**
 * ConfigContext is a React context which will contain the configuration for this instance of the app.
 * As its a context, any updates (including loading it initially) can be reflected in components where it's used.
 */
var context = React.createContext<IConfigContext | null>(null)
export default context

/**
 * A higher order component (HOC). Any component exported through this function will
 * receive the configContext as a prop. Handy, eh?
 * The manic Pick prop things are the result of help from StackOverflow: https://stackoverflow.com/a/50613946.
 */
export function withConfigContext<P extends ConfigContextProps> (Component: React.ComponentType<P>) {
  var wrapper = function (props: Pick<P, Exclude<keyof P, keyof ConfigContextProps>>) {
    return (
      <context.Consumer>
        {(configContext) => {
          if (configContext === null) return <h1>configContext was null! (are you below a provider?)</h1>
          return <Component configContext={configContext} {...props as P} />
        }}
      </context.Consumer>
    )
  }
  Object.defineProperty(wrapper, 'name', { value: `withConfigContext(${Component.name})` })
  return wrapper
}

export const LocalConfigKey = 'localConfig'
export const DarkModeKey = 'darkMode'

export interface IConfigContext {
  config: Config
  updateConfig: (config: Config) => void
  darkMode: boolean
  toggleDarkMode: () => void
  helpActive: boolean
  toggleHelp: () => void
}

export interface ConfigContextProps {
  configContext: IConfigContext
}
