import React, { Component } from 'react'
import Dialog from '../../common/Dialog'
import { TableRow, TableCell } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'

interface Props extends RouteComponentProps {
  grade: Grade
  contracts: Contract[]
  onUpdate (): void
}

interface State {
  dialogOpen: boolean
}

class GradeListItem extends Component<Props, State> {
  constructor (props: Props, state: State) {
    super(props)
    this.state = {
      dialogOpen: false
    }
  }

  handleOpen = () => {
    this.setState({ dialogOpen: true })
  }

  handleClose = () => {
    this.setState({ dialogOpen: false })
    this.props.onUpdate()
  }

  render () {
    return (
      <React.Fragment>
        <TableRow onClick={this.handleOpen} hover>
          <TableCell align='center' colSpan={6}>{this.props.grade.name}</TableCell>
          <TableCell align='center' colSpan={6}>{this.props.grade.isDisabled ? 'Yes' : 'No'}</TableCell>
          <TableCell align='center' colSpan={6}>{(this.props.contracts.find(contract => contract.id === this.props.grade.contractId) || { name: 'Unknown' }).name}</TableCell>
        </TableRow>
        <Dialog open={this.state.dialogOpen} handleClose={this.handleClose} grade={this.props.grade} type={'Grade'} />
      </React.Fragment>
    )
  }
}

export default withRouter(GradeListItem)
