import React, { Component } from 'react'
import { TableRow, TableCell, IconButton, CircularProgress, Tooltip } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import isPartOfTeam from '../../utils/IsPartOfTeam'
import api, { isErrorResponse } from '../../api'
import LockIcon from '@material-ui/icons/Lock'
import UnlockIcon from '@material-ui/icons/LockOpen'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import PersonIcon from '@material-ui/icons/Person'
import { hasPermissions } from '../common/permissionControl'
import Permissions from '../../permissionsList'

interface Props extends RouteComponentProps {
  name: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  impactingDays: number
  teamID: number
  cpID: number
  CPLocked: boolean
  isLocked: boolean
  noImpact: boolean
  noNLI: boolean
  noCAC: boolean
  submittedImpact: boolean
  impactCount: number
}

interface State {
  loading: boolean
  error: null | string
  isLocked: boolean
}

class TeamListItem extends Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      error: null,
      loading: false,
      isLocked: props.isLocked
    }
  }

  toggleTeamLock = async () => {
    this.setState({ loading: true })
    var response = await api('cptp/cp/' + this.props.cpID +
                             '/team/' + this.props.teamID,
      {
        method: 'POST', body: JSON.stringify({
          isLocked: !this.state.isLocked,
          cpID: this.props.cpID,
          teamID: this.props.teamID })
      })
    if (isErrorResponse(response)) return this.setState({ loading: false, error: response.error })
    return this.setState({ isLocked: response.payload.isLocked, loading: false })
  }

  componentDidUpdate (prevProps: Props) {
    if (prevProps.CPLocked !== this.props.CPLocked) {
      this.setState({
        isLocked: this.props.CPLocked
      })
    }
  }

  render () {
    var canImpactForTeam = hasPermissions([Permissions.IMPACT_CP_ON_TEAM]) && isPartOfTeam(this.props.teamID)
    var isTeamOwner = isPartOfTeam(this.props.teamID, true) // Or they could be a SUPER. Life is never equal.
    return (
      <TableRow hover>
        <TableCell>
          <Tooltip title={canImpactForTeam ? isTeamOwner ? 'You can manage this team' : 'You can impact for this team' : undefined }>
            <div>
              {canImpactForTeam ? isTeamOwner ? <BusinessCenterIcon /> : <PersonIcon /> : undefined}
            </div>
          </Tooltip>
        </TableCell>
        <TableCell style={{ cursor: 'pointer' }}
                   align='center'
                   onClick={this.props.onClick}>{this.props.name}
        </TableCell>
        <TableCell align='center'>{this.props.noImpact ? // if no impact
                                    this.props.noCAC ? // if no cac
                                      'No Impact' : // say no impact
                                      'No Impact - Comments' : // else say no comments
                                    (this.props.submittedImpact === false && this.props.impactCount === 0) ? // else if no submitted impacts
                                      <i>Not attempted</i> : // display not attempted
                                      <b>{this.props.impactingDays} days impacted</b>}</TableCell>
        <TableCell>
          <Tooltip title={this.props.CPLocked ? 'This CP is locked' : !isTeamOwner ? 'Only team owners or admins can toggle locking' : 'Toggle locking for this team'}>
            <div>
              {/* Div required for pointer events to be fired if the button is disabled */}
              <IconButton
                aria-label='Lock Team'
                onClick={this.toggleTeamLock}
                disabled={this.props.CPLocked || !isTeamOwner}
              >
                {this.state.loading ? <CircularProgress size={24} /> : this.state.isLocked === false ? <UnlockIcon /> : <LockIcon />}
              </IconButton>
            </div>
          </Tooltip>
        </TableCell>
      </TableRow>
    )
  }
}

export default withRouter(TeamListItem)
