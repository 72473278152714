import React, { Component } from 'react'
import { TableRow, TableCell, CircularProgress } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import moment from 'moment'

interface Props extends RouteComponentProps {
  audit: Audit
  id: number
  key: number
}

interface State {
  auditVal: string
  error: string | null
  loading: Boolean
}
class AuditListItem extends Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      auditVal: '',
      error: null,
      loading: false
    }
    this.auditValue = this.auditValue.bind(this)
  }

  async auditValue () {
    var isGuideline = false
    var isDeadlineDate = false
    var isTeamUsers = false
    var isDefaultIds = false
    var isPermissions = false
    var value
    if (this.props.audit.subType === 'deadlineDate') {
      isDeadlineDate = true
    }
    if (this.props.audit.subType === 'guideline') {
      isGuideline = true
    }
    if (this.props.audit.subType === 'teamUsers' || this.props.audit.subType === 'defaultIds') {
      isTeamUsers = true
      value = 'Users'
    }
    if (this.props.audit.subType === 'defaultIds') {
      isDefaultIds = true
      value = 'Activities'
    }
    if (this.props.audit.subType === 'permissions') {
      isPermissions = true
      value = 'Permissions'
    }

    var valueDiff: string
    if (this.props.audit.fromValue === null || this.props.audit.toValue === null) {
      if (this.props.audit.fromValue === null) {
        if (isGuideline) {
          valueDiff = 'Change made to ' + this.props.audit.type +
                      ': ' + this.props.audit.description +
                      ', "' + this.props.audit.toValue + '" added'
        } else {
          valueDiff = this.props.audit.type +
                      ' added ' +
                      this.props.audit.description +
                      ',Value: ' + this.props.audit.toValue
        }
      } else {
        if (isGuideline) {
          valueDiff = 'Change made to ' + this.props.audit.type +
                      ': ' + this.props.audit.description +
                      ', "' + this.props.audit.fromValue + '" deleted'
        } else {
          valueDiff = this.props.audit.type +
                      ' ' +
                      this.props.audit.fromValue +
                      ' deleted'
        }
      }
    } else {
      valueDiff = 'Change made to ' + this.props.audit.type +
                  ': ' + this.props.audit.description + ','
      if (isDeadlineDate) {
        valueDiff += 'Old Value: ' +
                    moment.unix(parseInt(this.props.audit.fromValue, 10)).format('DD/MM/YYYY') +
                    ', New Value:  ' +
                    moment.unix(parseInt(this.props.audit.toValue, 10)).format('DD/MM/YYYY')
      } else if (isTeamUsers || isDefaultIds || isPermissions) {
        var fromVals = Array.isArray(this.props.audit.fromValue) ? this.props.audit.fromValue : this.props.audit.fromValue.split(',') // Was having issues with types since for TeamUsers and DefaultIds, from value is an array, but for Permissions, it's a string.
        var toVals = Array.isArray(this.props.audit.toValue) ? this.props.audit.toValue : this.props.audit.toValue.split(',')
        var valsAdded = toVals.filter(val => !fromVals.includes(val))
        var valsRemoved = fromVals.filter(val => !toVals.includes(val))
        if (valsAdded.length > 0) {
          valueDiff += value + ' added: '
          valsAdded.forEach(function (val) {
            valueDiff += val + ','
          })
        }
        if (valsRemoved.length > 0) {
          valueDiff += value + ' removed: '
          valsRemoved.forEach(function (val) {
            valueDiff += val + ','
          })
        }
      } else {
        valueDiff += 'Old Value: ' +
                    this.props.audit.fromValue +
                    ', New Value: ' +
                    this.props.audit.toValue
      }
    }
    return this.setState({ auditVal: valueDiff })
  }

  async componentDidMount () {
    return this.auditValue()
  }

  render () {
    return (
      <TableRow hover style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(this.props.audit.route)}>
       {this.state.loading && <React.Fragment><CircularProgress /></React.Fragment>}
        <TableCell align='center'>{this.props.audit.type}</TableCell>
        <TableCell align='center'>{this.props.audit.subType}</TableCell>
        <TableCell align='center'>{this.state.auditVal.split(',').map((item, key) => {
          return <span key={key}>{item}<br/></span>
        })}
        </TableCell>
        <TableCell align='center'>{moment.unix(this.props.audit.auditDate).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
        <TableCell align='center'>{this.props.audit.name}</TableCell>
      </TableRow>
    )
  }
}
export default withRouter(AuditListItem)
