import React, { Component } from 'react'
import MyTeamListItem from './MyTeamListItem'
import { Table, TableBody, TableFooter, TableRow, TablePagination, TableHead, TableCell, CircularProgress } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions'
import api, { isErrorResponse, isPagedResponse } from '../../api'

interface Props extends RouteComponentProps {
  teams: User[]
}
interface State {
  page: number
  rowsPerPage: number
  total: number
  teams: User[]
  loading: boolean
  error: null | string
}

class MyTeamList extends Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      error: null,
      loading: false,
      teams: [],
      page: 0,
      rowsPerPage: 20,
      total: 0
    }
    this.loadTeam = this.loadTeam.bind(this)
  }

  handleChangePage = (event: any, page: number) => {
    this.setState({ page }, this.loadTeam)
  }

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) }, this.loadTeam)
  }

  async loadTeam () {
    this.setState({ loading: true, teams: [] })
    var userResponse = await api<User[]>(`users/${window.sessionContext.token!.user.id}/team?page=${this.state.page + 1}&pageSize=${this.state.rowsPerPage}`, { method: 'GET' })
    if (isErrorResponse(userResponse)) return this.setState({ loading: false, error: userResponse.error })
    if (!isPagedResponse(userResponse)) return
    return this.setState({ teams: userResponse.payload, total: userResponse.total, loading: false })
  }

  async componentDidMount () {
    this.loadTeam().catch()
  }

  render () {
    return (
      <Table >
        <TableHead>
          <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 25]}
                count={this.state.total}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                SelectProps={{
                  native: true
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions} />
            </TableRow>
              </TableHead>
              {this.state.loading && <TableBody><TableRow><TableCell align='center'colSpan={4}><CircularProgress /></TableCell></TableRow></TableBody>}
       {!this.state.loading && <TableBody>
          {this.state.teams.map(team => (<MyTeamListItem
          name={team.name}
          contractId={team.contractId}
          teamId={team.id}
          key={team.id}
          onClick={() => this.props.history.push('/myteams/' + team.contractId + '/team/' + team.id + '/cpstati')}/>))}
        </TableBody>}
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 20, 25]}
              count={this.state.total}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              SelectProps={{
                native: true
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions} />
          </TableRow>
        </TableFooter>
      </Table>
    )
  }
}
export default withRouter(MyTeamList)
