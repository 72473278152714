import React, { Component } from 'react'
import PaperSheet from '../components/common/Paper'
import image from '../assets/notImplementedYet.jpg'

export default class NotImplementedYetPage extends Component {
  render () {
    return (
      <PaperSheet title='Early bird warning'>
        <div style={{ textAlign: 'center' }}>
          <h1>No page here, but there is one to come!</h1>
          <img src={image} alt='Doge is "much excite"' style={{ width: '20em' }} />
        </div>
      </PaperSheet>
    )
  }
}
