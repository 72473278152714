import React from 'react'
import { Formik, Form } from 'formik'
import { Grid, Fab } from '@material-ui/core'
import FormikTextField from '../common/FormikTextField'
import ActivitySelectorField from './ActivitySelectorField'
import GradeSelectorField from './GradeSelectorField'
import { impactCheck } from '../../utils/ValidationFunctions'
import Delete from '@material-ui/icons/Delete'
import Pencil from '@material-ui/icons/Create'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import PermissionControl from '../common/permissionControl'
import Permissions from '../../permissionsList'
import { ImpactFields } from './ImpactingForm'
import Tooltip from '@material-ui/core/Tooltip'

var otherId = 0
// If you want this row to be a Create Impact row, ignore the optional props
// If you want this row to be an Edit Impact row, you need all the props
interface Props {
  onSubmit: (values: ImpactFields) => Promise<void>
  grades: Grade[]
  activities: Activity[]
  editing: boolean
  deleteButtonEnabled: boolean
  canEdit?: boolean
  values?: ImpactFields
  row?: number
  deleteImpactRow?: (isDefault: boolean, index: number, id?: number) => void
  toggleEdit?: (index: number) => void
  isDisabled?: boolean
  newRowStyling?: boolean
  otherId: number
}

const initialValues = {
  activityId: null,
  gradeId: null,
  days: 0,
  documentsAffected: '',
  comment: ''
} as ImpactFields

export default class NewImpactRow extends React.PureComponent<Props> {
  deleteRow = () => {
    if (!this.props.deleteImpactRow) return
    // if it's a default you want to send the list index
    // if not default, it should exist in the db, and can be deleted by id
    if (this.props.values!.default) {
      this.props.deleteImpactRow(true, this.props.row!)
    } else {
      this.props.deleteImpactRow(false, this.props.row!, this.props.values!.id!)
    }
  }

  editRow = () => {
    if (!this.props.toggleEdit) return
    this.props.toggleEdit(this.props.row!)
  }

  componentDidMount () {
    otherId = this.props.otherId
  }

  render () {
    otherId = this.props.otherId
    return (
      <Formik
        initialValues={this.props.values || initialValues}
        validate={validateImpact}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(false)
          await this.props.onSubmit(values)
          // Only reset if a 'new impact' row.
          actions.resetForm(this.props.values ? values : initialValues)
        }}
        render={formProps => (
          <Form>
            <Grid id={'impactRow' + this.props.row} container item xs={12} justify='space-around'>
              <Grid item xs={'auto'}>
                {this.props.deleteButtonEnabled ?
                  <PermissionControl allowedPermissions={[Permissions.IMPACT_CP_ON_TEAM]}>
                    <Tooltip title='Delete' placement='left'>
                      <Fab
                        className='impactDeleteRowButton'
                        color='primary'
                        onClick={this.deleteRow}
                        aria-label='Delete'
                        disabled={this.props.isDisabled}
                      >
                        <Delete />
                      </Fab>
                    </Tooltip>
                  </PermissionControl>
                : <div style={{ width: 56, height: 56 }} /> }
              </Grid>
              <ActivitySelectorField name={'activityId'} activities={this.props.activities} isDisabled={!this.props.editing}/>
              <GradeSelectorField name={'gradeId'} grades={this.props.grades} isDisabled={!this.props.editing}/>
              <FormikTextField className='impactDaysBox' name={'days'} label='Impact' placeholder='Impact' type='number' xs={1} required inputProps={ { maxLength: 4, step: 0.5, min: 0 } } validate={impactCheck} disabled={!this.props.editing}/>
              <FormikTextField name={'documentsAffected'} label='Documents Affected' placeholder='Documents Affected' type='string' xs={2} multiline rowsMax='3' inputProps={ { maxLength: 400 } } disabled={!this.props.editing}/>
              <FormikTextField name={'comment'} label='Comments' placeholder='Comments' type='string' xs={2} multiline rowsMax='3' inputProps={ { maxLength: 400 } } disabled={!this.props.editing}/>
              <Grid item xs={'auto'}>
                <PermissionControl allowedPermissions={[Permissions.IMPACT_CP_ON_TEAM]}>
                  {this.props.newRowStyling ?
                  <Tooltip title='Add' placement='right'>
                    <Fab className='impactEditRowButton' color='primary' onClick={formProps.submitForm} aria-label='Edit' disabled={this.props.isDisabled} style={{ backgroundColor: 'green' }}>
                      <Add />
                    </Fab>
                  </Tooltip> : this.props.editing ?
                  <Tooltip title='Save' placement='right'>
                    <Fab className='impactEditRowButton' color='primary' onClick={formProps.submitForm} aria-label='Edit' disabled={this.props.isDisabled}>
                      <Check />
                    </Fab>
                  </Tooltip> : this.props.canEdit ?
                  <Tooltip title='Edit' placement='right'>
                    <Fab className='impactEditRowButton' color='primary' onClick={this.editRow} aria-label='Edit' disabled={this.props.isDisabled}>
                      <Pencil />
                    </Fab>
                  </Tooltip> : <div style={{ width: 56, height: 56 }} />}
                </PermissionControl>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    )
  }
}

function validateImpact (formValues: ImpactFields) {
  var errors: { [x in keyof ImpactFields]?: string | undefined } = {}
  if (formValues.days === 0 && ((formValues.documentsAffected || '').length === 0 && (formValues.comment || '').length === 0)) {
    errors.documentsAffected = 'Please enter a comment or a documents affected if the impact is 0.'
    errors.comment = 'Please enter a comment or a documents affected if the impact is 0.'
  }
  if (formValues.activityId === (otherId) && ((formValues.documentsAffected || '').length === 0 && (formValues.comment || '').length === 0)) {
    errors.documentsAffected = 'Please enter a comment or a documents affected if the activity is other.'
    errors.comment = 'Please enter a comment or a documents affected if the activity is other.'
  }
  return errors
}
