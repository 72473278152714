import React, { Component } from 'react'
import api, { isErrorResponse, isPagedResponse } from '../../api'
import CPListItem from './CPListItem'
import { Table, TableBody, TableFooter, TableRow, TablePagination, TableHead, TableCell, CircularProgress, Divider, TableSortLabel } from '@material-ui/core'
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions'
import PaperSheet from '../common/Paper'
import AddButton from '../common/buttons/AddButton'
import { Link } from 'react-router-dom'
import PermissionControl from '../common/permissionControl'
import Permissions from '../../permissionsList'
import CPDateForm, { Fields } from './CPDateForm'
import CustomJoyride from '../common/CustomJoyride'

interface State {
  loading: boolean
  cps: CPSC[]
  page: number
  rowsPerPage: number
  total: number
  error: null | string
  column: string
  direction: 'asc' | 'desc'
  fromDate: number | null
  toDate: number | null
  statusId: number
}

export default class CPList extends Component<{}, State> {
  constructor (props: {}) {
    super(props)
    this.state = {
      error: null,
      loading: false,
      cps: [],
      page: 0,
      rowsPerPage: 25,
      total: 0,
      column: 'CPNumber',
      direction: 'desc',
      fromDate: null,
      toDate: null,
      statusId: -1
    }
    this.loadCP = this.loadCP.bind(this)
  }

  async loadCP () {
    this.setState({ loading: true, cps: [] })
    var CPQuery = `cp?column=${this.state.column}&direction=${this.state.direction}&page=${this.state.page + 1}&pageSize=${this.state.rowsPerPage}`
    if (this.state.fromDate) CPQuery += `&fromDate=${this.state.fromDate}`
    if (this.state.toDate) CPQuery += `&toDate=${this.state.toDate}`
    if (this.state.statusId !== -1) CPQuery += `&statusId=${this.state.statusId}`
    var response = await api<CPSC[]>(CPQuery)
    if (isErrorResponse(response)) return this.setState({ loading: false, error: response.error })
    if (!isPagedResponse(response)) return
    return this.setState({
      cps: response.payload,
      total: response.total,
      loading: false
    })
  }

  async componentDidMount () {
    return this.loadCP().catch()
  }

  handleChangePage = (event: any, page: number) => {
    this.setState({ page }, this.loadCP)
  }

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10) },
      this.loadCP
     )
  }

  handleSort = (columnName: string) => {
    return () => {
      this.setState({
        column: columnName,
        direction: this.state.direction === 'asc' ? 'desc' : 'asc'
      }, this.loadCP)
    }
  }

  onSubmit = async (values: Fields) => {
    this.setState({
      fromDate: values.fromDate,
      toDate: values.toDate,
      statusId: values.statusId
    }, this.loadCP)
  }

  onClear = async () => {
    this.setState({ fromDate: NaN, toDate: NaN, statusId: -1 }, this.loadCP)
  }

  render () {
    return (
      <PaperSheet title='View CPs' rightSideContent={
        <PermissionControl allowedPermissions={[Permissions.CREATE_UPDATE_CP]}>
          <Link to='cp/add' style={{ textDecoration: 'none' }}>
            <AddButton id='addNewCP' title='Add a New CP'/>
          </Link>
        </PermissionControl>
      }>
        <CPDateForm id='dateFilterForm'
                    onSubmit={this.onSubmit}
                    onClick={this.onClear}/>
        <br />
        <CustomJoyride
          continuous
          scrollToFirstStep
          steps={[
            {
              target: '#paperSheet',
              content: `This is the CP list page. From here you can see all of the CPs stored on the system in paged chunks.`
            },
            {
              target: '#topPaginationButtons',
              content: `This system may have a fair few CPs stored, so it is broken into pages to avoid bogging down your machine. You can increase the number loaded at one time if you wish.`
            },
            {
              target: '#firstSortLabel',
              content: `By default this view will load with the highest CP number ordered first. You can click to the left of the column titles to sort by another column or reverse the sorting direction.`
            },
            {
              target: '#dateFilterForm',
              content: `Additionally you may wish to filter between two deadline dates to find CPs from a certain time. This will work in addition to the sorting functionality.`
            },
            {
              target: '#addNewCP',
              content: `You have the ability to create and update CPs. Lucky you! Clicking this button will take you to the new CP page. It's a very simple form and should only take a minute.`
            },
            {
              target: '#firstCPRow',
              content: `Clicking on a row will take you to the detailed view for that CP. You'll want to do this to impact it, or view and export the current impacts for teams.`
            }
          ]}
        />
        <Divider />
        <Table padding='dense'>
          <TableHead>
            <TableRow>
                <TablePagination
                id='topPaginationButtons'
                  rowsPerPageOptions={[10, 25, 40, 50]}
                  count={this.state.total}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  SelectProps={{
                    native: true
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
              </TableRow>
            <TableRow>
              <TableCell align='center'>
                <TableSortLabel id='firstSortLabel'
                                active={this.state.column === 'CPNumber'}
                                direction={this.state.column === 'CPNumber' ? this.state.direction : undefined}
                                onClick={this.handleSort('CPNumber')}
                >CP Number</TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel active={this.state.column === 'title'}
                                direction={this.state.column === 'title' ? this.state.direction : undefined}
                                onClick={this.handleSort('title')}
                >Title</TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel active={this.state.column === 'deadlineDate'}
                                direction={this.state.column === 'deadlineDate' ? this.state.direction : undefined}
                                onClick={this.handleSort('deadlineDate')}
                >Deadline Date</TableSortLabel>
                </TableCell>
              <TableCell align='center'>
                <TableSortLabel active={this.state.column === 'technicalOwner'}
                                direction={this.state.column === 'technicalOwner' ? this.state.direction : undefined}
                                onClick={this.handleSort('technicalOwner')}
                >Technical Owner</TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel active={this.state.column === 'projectManager'}
                                direction={this.state.column === 'projectManager' ? this.state.direction : undefined}
                                onClick={this.handleSort('projectManager')}
                >Project Manager</TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel active={this.state.column === 'contractType'}
                                direction={this.state.column === 'contract.id' ? this.state.direction : undefined}
                                onClick={this.handleSort('contract.id')}
                >Contract Type</TableSortLabel>
              </TableCell>
              <TableCell align='center'>
                <TableSortLabel active={this.state.column === 'cpStatus'}
                                direction={this.state.column === 'status.id' ? this.state.direction : undefined}
                                onClick={this.handleSort('status.id')}
                >CP Status</TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
            this.state.loading &&
            <TableRow>
              <TableCell align='center'
                         colSpan={7}>
                         <CircularProgress />
              </TableCell>
            </TableRow>}
            {this.state.cps.map(function (cp, i) {
              return <CPListItem id={i === 0 ? 'firstCPRow' : undefined} cp={cp} key={cp.id} />
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 40, 50]}
                count={this.state.total}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                SelectProps={{
                  native: true
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions} />
            </TableRow>
          </TableFooter>
        </Table>
      </PaperSheet>
    )
  }
}
