import React, { Component } from 'react'
import PaperSheet from '../components/common/Paper'
import api, { isErrorResponse } from '../api'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import UserListItem from '../components/configuration/user/UserListItem'
import CPListItem from '../components/cps/CPListItem'
import TeamListItem from '../components/configuration/teams/TeamListItem'
import { TableBody, TableHead, TableRow, TableCell, TableSortLabel, Table, CircularProgress, TextField, Grid, Fab } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import CustomJoyride from '../components/common/CustomJoyride'
import SearchIcon from '@material-ui/icons/Search'
import queryString from 'query-string'
interface Props extends RouteComponentProps<{searchTerm: string}> {
  defaultExpanded: true
}

interface State {
  results: any
  loading: boolean
  error: any
  cps: CPSC[]
  users: User[]
  teams: Team[]
  roles: []
  searched: boolean
  searchTerm: string
}

class Search extends Component <Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      results: null,
      loading: false,
      error: null,
      cps: [],
      users: [],
      teams: [],
      roles: [],
      searched: false,
      searchTerm: ''
    }
  }

  async loadRoles () {
    var response = await api('/role')
    if (isErrorResponse(response)) return
    this.setState({ roles: response.payload })
  }

  async componentDidMount () {
    this.loadRoles().catch()
    var getTerm = queryString.parse(this.props.location.search).query
    if (!getTerm || getTerm instanceof Array) return null
    this.setState({ searchTerm: getTerm }, this.search)
    console.log(this.props.location.state)
  }

  async componentDidUpdate (nextProps: Props) {
    if (this.props.location.search !== nextProps.location.search) {
      var getTerm = queryString.parse(this.props.location.search).query
      if (!getTerm || getTerm instanceof Array) return null
      this.setState({ searchTerm: getTerm }, this.search)
    }
  }

  search = async () => {
    this.setState({ loading: true })
    var response = await api('search/?query=' + this.state.searchTerm)
    if (isErrorResponse(response)) return this.setState({ loading: false, error: response.error })
    return this.setState({ cps: response.payload.cps, users: response.payload.users, teams: response.payload.teams, loading: false, searched: true })
  }

  render () {
    return (
      <PaperSheet title='Search' rightSideContent={<Typography>{this.state.cps.length + this.state.users.length + this.state.teams.length} results found</Typography>}>
            <CustomJoyride
              continuous
              scrollToFirstStep
              steps={[
                {
                  target: '#paperSheet',
                  content: `This is the Search page. This is where your search results will appear. here you can search all the CPs, Users, Teams and Roles stored on the system.`
                },
                {
                  target: '#Search',
                  content: `Here you can search all the CPs, Users, Teams and Roles stored on the system. Enter the CPs/Users/Teams/Roles in this text field`
                }
              ]}
            />
        <Grid container spacing={8}>
          <Grid item xs={4}>
            <form onSubmit={e => { e.preventDefault(); this.search().catch() }}>
            <TextField fullWidth name='search' id='search' placeholder='Search...' type='string' required value={this.state.searchTerm} onChange={e => this.setState({ searchTerm: e.target.value })} />
            </form>
          </Grid>
          <Grid item xs={4}>
            <Fab variant='extended' aria-label='Search' onClick={this.search}>
             <SearchIcon />
            </Fab>
          </Grid>
        </Grid>
        {this.state.loading && <CircularProgress />}
        <br />
        {!this.state.loading && this.state.searched && this.state.cps.length > 0 && <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>CP Search Results ({this.state.cps.length})</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center'><TableSortLabel >CP Number</TableSortLabel></TableCell>
                <TableCell align='center'><TableSortLabel >CP Title</TableSortLabel></TableCell>
                <TableCell align='center'><TableSortLabel >Deadline Date</TableSortLabel></TableCell>
                <TableCell align='center'><TableSortLabel >Technical Owner</TableSortLabel></TableCell>
                <TableCell align='center'><TableSortLabel >Project Manager</TableSortLabel></TableCell>
                <TableCell align='center'><TableSortLabel>Contract Type</TableSortLabel></TableCell>
                <TableCell align='center'><TableSortLabel>CP Status</TableSortLabel></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.cps.map(function (cp) {
                  return <CPListItem cp={cp} key={cp.CPNumber} />
                })}
              </TableBody>
          </Table>
        </ExpansionPanelDetails>
      </ExpansionPanel>}
      {!this.state.loading && this.state.searched && this.state.users.length > 0 && <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>User Search Results ({this.state.users.length})</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center' colSpan={6}><TableSortLabel >Name</TableSortLabel></TableCell>
                <TableCell align='center' colSpan={6}><TableSortLabel >Email</TableSortLabel></TableCell>
                <TableCell align='center' colSpan={6}><TableSortLabel >Role</TableSortLabel></TableCell>
                <TableCell align='center' colSpan={6}><TableSortLabel >Inactive?</TableSortLabel></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.users.map((user) => {
                return <UserListItem user={user} roles={this.state.roles} key={user.id} onUpdate={this.search} />
              })}
            </TableBody>
          </Table>
        </ExpansionPanelDetails>
      </ExpansionPanel>}

      {!this.state.loading && this.state.searched && this.state.teams.length > 0 && <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Team Search Results ({this.state.teams.length})</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center' colSpan={6}><TableSortLabel >Team Name</TableSortLabel></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.teams.map((team) => {
                return <TeamListItem name={team.name} key={team.id} onClick={() => this.props.history.push('/config/teams/' + team.id + '/details')}/>
              })}
            </TableBody>
            </Table>
        </ExpansionPanelDetails>
      </ExpansionPanel>}
      {this.state.searched && this.state.cps.length + this.state.users.length + this.state.teams.length === 0 && <Typography variant='h5'>No results found</Typography>}
      </PaperSheet>
    )
  }
}
export default withRouter(Search)
