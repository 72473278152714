import { hasPermissions } from '../components/common/permissionControl'
import Permissions from '../permissionsList'

/**
 * Returns true if you are an admin (SUPER_CONFIGURATION) or are included in the list of teamIDs for that user.
 */
export default function isPartOfTeam (teamId: number, isTeamOwner = false) {
  if (hasPermissions([Permissions.SUPER_CONFIGURATION])) return true
  var user = window.sessionContext.token!.user
  return (isTeamOwner ? user.teamOwnerIDs : user.teamIDs).includes(teamId)
}
