import React, { Component } from 'react'
import { RouteChildrenProps } from 'react-router'
import CPForm, { Fields } from './CPForm'
import Typography from '@material-ui/core/Typography'
import PaperSheet from '../common/Paper'
import api, { isErrorResponse } from '../../api'
import Snackbar from '../common/Snackbar'
import PermissionControl from '../common/permissionControl'
import Permissions from '../../permissionsList'
import CustomJoyride from '../../components/common/CustomJoyride'

interface Props extends RouteChildrenProps {
  // None of our own.
}

interface State {
  submitted: boolean,
  submitting: boolean,
  error: null | string
}

export default class NewCPPage extends Component<Props, State> {
  state = {
    submitting: false,
    submitted: false,
    error: null
  }

  onSubmit = async (values: Fields) => {
    this.setState({ submitting: true })
    var response = await api('cp', { method: 'POST', body: JSON.stringify(values) })
    if (isErrorResponse(response)) return this.setState({ submitting: false, error: response.error })
    this.setState({ submitted: true, submitting: false })
    setTimeout(() => this.props.history.push('/cp/' + (response as ApiResponse<CP>).payload.id + '/details'), 2000)
  }

  render () {
    return (
      <PaperSheet title='Create a CP'>
         <CustomJoyride
         continuous
         scrollToFirstStep
         steps={[
           {
             target: '#paperSheet',
             content: `This is the CP page. From here you can add new CPs.`
           }]}
          />
         <PermissionControl allowedPermissions={[Permissions.CREATE_UPDATE_CP]}>
            <Typography variant='h6' gutterBottom>Fill out the form below to create a new CP.</Typography>
            <CPForm submitted={this.state.submitted} submitting={this.state.submitting} onSubmit={this.onSubmit} />
            {this.state.error && <Snackbar isError autoHideDuration={false} message={this.state.error} />}
         </PermissionControl>
      </PaperSheet>
    )
  }
}
