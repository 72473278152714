import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Select from 'react-select'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import CancelIcon from '@material-ui/icons/Cancel'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import api, { isErrorResponse } from '../../../api'
import { Field } from 'formik'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  input: {
    display: 'flex',
    padding: 0
  },
  vC: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'auto',
    maxHeight: 200
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 500,
    maxWidth: 500,
  },
  select: {
    Height: 250,
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    maxHeight: 500
  },
  divider: {
    height: theme.spacing.unit * 2
  },
  option: {
    maxHeight: 500
  }
})

function NoOptionsMessage (props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent ({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control (props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Option (props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
      className={props.selectProps.classes.option}
    >
      {props.children}
    </MenuItem>
  )
}

function Placeholder (props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function ValueContainer (props) {
  return <div className={props.selectProps.classes.vC}>{props.children}</div>
}

function MultiValue (props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

function Menu (props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer
}

/**
 * A Formik-compatible field that allows a contract type to be selected. It returns a number type - not a string!
 */
export default class UserMultiSelectorField extends React.Component {
  render () {
    return (
      <Field {...this.props} component={stylishSelect} />
    )
  }
}

class UnstyledSelectorField extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selected: [],
      users: []
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = (value) => {
    this.setState({ selected: value })
    this.props.form.setFieldValue(this.props.field.name, value)
  }

  async componentDidMount () {
    var response = await api(this.props.isTeamOwner ? '/teamusers/teamOwners' : '/users/unpaginated?isDisabled=false')
    if (isErrorResponse(response)) return
    this.setState({ users: response.payload }, this.showInitialSelectedItems)
  }

  showInitialSelectedItems = () => {
    if (!this.props.field.value) return
    var selectedFromProps = this.props.field.value.map(teamUser => ({
      value: teamUser.userId,
      label: this.findUser(teamUser.userId).name + ' <' + this.findUser(teamUser.userId).email + '>'
    }))
    this.setState({ selected: selectedFromProps })
  }

  findUser = (userId) => {
    return this.state.users.find(user => user.id === userId)
  }

  render () {
    console.log(this.state.selected)
    const { classes, theme } = this.props

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        }
      })
    }

    return (
      <div className={classes.root}>
        <Grid item xs={8}>
          <FormControl className={classes.formControl}>
            <Select
              className={classes.select}
              isDisabled={this.props.isDisabled}
              multiple
              native
              classes={classes}
              styles={selectStyles}
              textFieldProps={{
                label: this.props.label,
                InputLabelProps: {
                  shrink: true
                }
              }}
              options={this.state.users.map(user => ({
                value: user.id,
                label: user.name + ' <' + user.email + '>'
              }))}
              components={components}

              onChange={this.handleChange}
              value={this.state.selected}

              placeholder='Select users'
              isMulti
              maxMenuHeight={250}
              isSearchable
              menuPlacement='auto'
              menuPosition='absolute'
            />
          </FormControl>
        </Grid>
      </div>
    )
  }
}

UnstyledSelectorField.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

const stylishSelect = withStyles(styles, { withTheme: true })(UnstyledSelectorField)
