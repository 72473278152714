import React, { Component } from 'react'
import { ListItem, Checkbox } from '@material-ui/core'
import api, { isErrorResponse } from '../../../api'
import { Field, FieldProps, FieldConfig } from 'formik'
import { FormDisabledProps, withFormDisabled } from '../../common/CustomFormik'

export default class PermissionBoxBoss extends Component<FieldConfig & Props> {
  render () {
    // Use of Field from Formik hooks it into the form, and passing it a component allows it to show our text field.
    return (
      <Field {...this.props} component={WrappedForm}>
        {this.props.children}
      </Field>
    )
  }
}

interface Props {
  isDisabled?: boolean
}
interface State {
  permissions: Permission[]
}
class PermissionListForm extends Component<FieldProps & Props & FormDisabledProps, State> {
  constructor (props: FieldProps & Props & FormDisabledProps) {
    super(props)
    this.state = {
      permissions: []
    }
  }
  async componentDidMount () {
    var response = await api<Permission[]>('/Permission')
    if (isErrorResponse(response)) return
    this.setState({ permissions: response.payload })
  }

  toggleChecked = (id: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      var checkedIds: number[] = this.props.field.value || []
      if (checkedIds.find(checkedId => id === checkedId)) {
        checkedIds = checkedIds.filter(checkedId => checkedId !== id)
      } else {
        checkedIds.push(id)
      }
      this.props.form.setFieldValue(this.props.field.name, checkedIds)
    }
  }

  render () {
    var checkedIds: number[] = this.props.field.value || []
    return (
      <React.Fragment>
        {this.state.permissions.map((permission: Permission, index: number) => (
          <ListItem key={index}>
            <Checkbox
              checked={checkedIds.find(checkedId => permission.id === checkedId) ? true : false}
              onChange={this.toggleChecked(permission.id)}
              disabled={this.props.isDisabled}/>
            <span>{permission.name}</span>
          </ListItem>
        ))}
      </React.Fragment>
    )
  }
}

const WrappedForm = withFormDisabled(PermissionListForm)
