import React, { Component } from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Dialog from '../../common/Dialog'

interface Props extends RouteComponentProps {
  role: Role
  onDelete (): void
  handleClose (): void
}

interface State {
  dialogOpen: boolean
  shouldBeDisabled: boolean
}

class RoleListItem extends Component<Props, State> {
  constructor (props: Props, state: State) {
    super(props)
    this.state = {
      dialogOpen: false,
      shouldBeDisabled: this.props.role.id === 1 || this.props.role.id === 2
    }
  }

  handleOpen = () => {
    this.setState({ dialogOpen: true })
  }

  handleClose = () => {
    this.setState({ dialogOpen: false })
    if (this.props.handleClose) {
      this.props.handleClose()
    }
  }

  render () {
    return (
      <React.Fragment>
        <TableRow onClick={this.handleOpen} hover>
          <TableCell align='center'>{this.props.role.name}</TableCell>
          <TableCell align='center'>{this.props.role.isDisabled ? 'Yes' : 'No'}</TableCell>
        </TableRow>
        <Dialog isDisabled={this.state.shouldBeDisabled}
          open={this.state.dialogOpen}
          handleClose={this.handleClose}
          onDelete={this.props.onDelete}
          type='Role'
          role={this.props.role}/>
      </React.Fragment>
    )
  }
}

export default withRouter(RoleListItem)
