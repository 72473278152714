import React, { Component } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import ConfigProvider from './ConfigProvider'
import SessionProvider from './utils/SessionProvider'
import SessionWatcher from './SessionWatcher'
import Router from './components/Router'
import CookieConsent from './components/common/CookieConsent'

/**
 * This is the entrypoint for the application. It doesn't do a lot.
 */
export default class App extends Component {
  render () {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ConfigProvider>
          <SessionProvider>
            <CookieConsent />
            <Router />
            <SessionWatcher />
            <CssBaseline />
          </SessionProvider>
        </ConfigProvider>
      </MuiPickersUtilsProvider>
    )
  }
}
