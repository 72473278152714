import React, { Component } from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'

interface Props extends RouteComponentProps {
  CPNumber: number
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  title: string
  totalDays: number
  noImpact?: boolean
  submittedImpact?: boolean
}

class MTSListItem extends Component<Props> {
  render () {
    return (
      <TableRow style={{ cursor: 'pointer' }} hover onClick={this.props.onClick}>
        <TableCell align='center'>{this.props.CPNumber}</TableCell>
        <TableCell align='center'>{this.props.title}</TableCell>
        <TableCell align='center'>{this.props.noImpact ? 'No Impact' : (this.props.submittedImpact === false && this.props.totalDays === 0) ? 'Not Attempted' : this.props.totalDays + ' Days impacted'}</TableCell>
      </TableRow>
    )
  }
}

export default withRouter(MTSListItem)
