import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { withStyles, WithStyles, Theme, createStyles } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({
  switch: {
    color: '#A30B1A'
  },
  label: {
    fontSize: '1.1rem !important',
    float: 'right'
  }
})
interface Props extends WithStyles {
  handleNoImpact: (e: React.ChangeEvent<HTMLInputElement>) => void
  noImpact: boolean
}
class NoImpactSwitch extends React.Component<Props> {

  render () {
    return (
      <div className={ this.props.classes.label }>
        <Switch checked={ this.props.noImpact } color='primary' onChange={ this.props.handleNoImpact } name='noImpact' className={ this.props.classes.switch } style={ this.props.noImpact ? { color: '#A30B1A' } : { color: 'green' } }/>
        <span>No Impact</span>
      </div>
    )
  }
}

export default withStyles(styles)(NoImpactSwitch)
