import React, { Component } from 'react'
import api, { isErrorResponse } from '../../../api'
import TypeListItem from './TypeListItem'
import { Table, TableBody, TableFooter, TableRow, TableHead, TableCell, Dialog, Button, DialogActions, DialogContent, DialogTitle, Grid, CircularProgress } from '@material-ui/core'
import PaperSheet from '../../common/Paper'
import AddButton from '../../common/buttons/AddButton'
import { hasPermissions } from '../../common/permissionControl'
import Permissions from '../../../permissionsList'
import TypeForm, { Fields } from './TypeForm'
import { RouteChildrenProps } from 'react-router'
import Snackbar from '../../common/Snackbar'
import CustomJoyride from '../../common/CustomJoyride'
import NLITFormScreenshot from '../../../assets/joyride/AddingNLIT.png'
import NLITViewScreenshot from '../../../assets/joyride/ViewingNLIT.png'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

interface Props extends RouteChildrenProps {
  // None.
}

interface State {
  types: NonLabourItemType[]
  dialogOpen: boolean
  submitted: boolean
  submitting: boolean
  error: null | string
  showDisabled: boolean
  loading: boolean
}

export default class TypeList extends Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      types: [],
      dialogOpen: false,
      submitting: false,
      submitted: false,
      error: null,
      showDisabled: false,
      loading: false
    }
    this.loadType = this.loadType.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  async loadType () {
    this.setState({ loading: true })
    var response = await api(`types?showDisabled=${this.state.showDisabled}`) as PagedApiResponse<NonLabourItemType[]>
    if (response.status === 200) return this.setState({ types: response.payload, loading: false })
  }

  async componentDidMount () {
    this.loadType().catch(e => console.log(e))
  }

  handleOpen () {
    this.setState({ dialogOpen: true })
  }

  handleClose () {
    this.setState({ dialogOpen: false })
  }

  handleDisableToggle () {
    this.setState({ showDisabled: !this.state.showDisabled }, this.loadType)
  }

  onSubmit = async (values: Fields) => {
    this.setState({ submitting: true, error: null })
    var response = await api('types/add', { method: 'POST', body: JSON.stringify(values) })
    if (isErrorResponse(response)) return this.setState({ submitting: false, error: response.error })
    this.setState({ submitted: true, submitting: false })
    setTimeout(() => this.props.history.push('/config/types'), 2000)
    this.setState({ dialogOpen: false }, this.loadType)
  }

  render () {
    const types: any[] = []
    this.state.types.forEach(type => {
      types.push(<TypeListItem key={type.id} name={type.name} description={type.description} isDisabled={type.isDisabled} type={type} onUpdate={this.loadType} />)
    })
    return (
      <PaperSheet title='View Non Labour Item Types' rightSideContent={<AddButton title='Add a New Non Labour Type' id='add' onClick={this.handleOpen} />}>
        <CustomJoyride
          continuous
          steps={[
            {
              target: '#add',
              content: (
                <div>
                  Click this link to open the Non-Labour Types form, where you can add a non-labour type
                  <br />
                  <img alt='Non Labour Item Form Screenshot' style={{ maxWidth: '100%' }} src={NLITFormScreenshot} />
                </div>)
            },
            {
              target: '#list',
              content: (
                <div>
                  This is the Non-Labour Types list where you can view all the non-labour types in the system, clicking on a non-labour type will allow you to view and edit it
                  <br />
                  <img alt='Non Labour Item View Screenshot' style={{ maxWidth: '100%' }} src={NLITViewScreenshot} />
                </div>
              )
            }
          ]}
        />
        {hasPermissions([Permissions.SUPER_CONFIGURATION]) && <Table id='list'>
          <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
          <Grid container justify='space-between'>
              <DialogTitle> Add New Non-Labour Item Type</DialogTitle>
              <DialogActions>
              <Button onClick={this.handleClose} color='primary' >
                Cancel
              </Button>
            </DialogActions>
            </Grid>
            <DialogContent>
              <TypeForm
                submitted={false}
                submitting={this.state.submitting}
                onSubmit={this.onSubmit} />
            </DialogContent>
          </Dialog>
          <TableHead>
            <FormControlLabel control={
              <Checkbox
                name={'showDisabled'}
                checked={this.state.showDisabled}
                onChange={ () => this.handleDisableToggle() }
              />}
            label = 'Show disabled types' />
            <TableRow>
              <TableCell align='center' colSpan={6}>Name</TableCell>
              <TableCell align='center' colSpan={6}>Description</TableCell>
              <TableCell align='center' colSpan={6}>Disabled?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.loading && <TableRow><TableCell align='center'colSpan={4}><CircularProgress /></TableCell></TableRow>}
            {types}
          </TableBody>
          <TableFooter>
            <TableRow />
          </TableFooter>
        </Table>}
        {this.state.error && <Snackbar message={this.state.error}></Snackbar>}
        {this.state.submitted && <Snackbar message='Submitted successfully!' />}
      </PaperSheet>
    )
  }
}
