import React from 'react'
import MyTeamList from './MyTeamList'
import PaperSheet from '../common/Paper'
import { TabProps } from '@material-ui/core/Tab'
import PermissionControl from '../common/permissionControl'
import Permissions from '../../permissionsList'
import MyAdminsTeamListView from './MyAdminsTeamListView'
import CustomJoyride from '../common/CustomJoyride'

interface State {
  teams: User[]
}

interface Props extends TabProps {
  // nothing here!
}

class MyTeamsListView extends React.Component <Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      teams: []
    }
  }
  render () {
    return (
      <PaperSheet title='My Teams'>
        <CustomJoyride
          continuous
          scrollToFirstStep
          scrollOffset={400}
          steps={[
            {
              target: '#list',
              content: 'Welcome to the My Teams module. Here you can view and interact with the teams you are part of. Click on a team to see the CPs for that team. Alternatively if you\'re an admin or team owner you could click on the add users button next to each to add users to that.'
            }
          ]}
        />
        <div id='list'><PermissionControl allowedPermissions={[Permissions.SUPER_CONFIGURATION]} renderNoAccess={(<MyTeamList teams={this.state.teams} />)}>
          <MyAdminsTeamListView/>
        </PermissionControl></div>
      </PaperSheet>
    )
  }
}

export default(MyTeamsListView)
