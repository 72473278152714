import React from 'react'
import TeamList from './TeamList'
import PaperSheet from '../../common/Paper'
import { Tabs, Tab, AppBar } from '@material-ui/core'
import api, { isErrorResponse } from '../../../api'
import { TabProps } from '@material-ui/core/Tab'
import AddButton from '../../common/buttons/AddButton'
import { Link } from 'react-router-dom'
import { hasPermissions } from '../../common/permissionControl'
import Permissions from '../../../permissionsList'
import CustomJoyride from '../../common/CustomJoyride'

interface State {
  contracts: Contract[]
  selectedContractID: number
}

interface Props extends TabProps {
  // nothing here!
}

class TeamsListView extends React.Component <Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      contracts: [],
      selectedContractID: 1
    }
  }
  handleChange = (newContractId: number) => {
    this.setState({ selectedContractID: newContractId })
  }
  async componentDidMount () {
    var response = await api<Contract[]>('/contract')
    if (isErrorResponse(response)) return
    this.setState({ contracts: response.payload })
  }
  render () {
    return (
      <PaperSheet title='View Teams' rightSideContent={
        <Link to='teams/add' style={{ textDecoration: 'none' }}>
          <AddButton title='Add a Team' id='add'/>
        </Link>
        }>
        <CustomJoyride
          continuous
          steps={[
            {
              target: '#add',
              content: 'Click this Button to open the Teams form and add a team'
            },
            {
              target: '#tabs',
              content: 'These are the contract-types that different teams belong to, you can click on each contract-type to view the teams that are part of that contract-type'
            },
            {
              target: '#list',
              content: 'This is the Teams list, clicking on a team will take you to the team\'s details page where you can view and edit the team'
            }
          ]}
        />
        <AppBar position={'static'}>
          <Tabs value={this.state.selectedContractID} variant='fullWidth' id='tabs'>
            {this.state.contracts.map(contract => (
              <Tab value={contract.id} label={contract.name} key={contract.id} onClick={() => this.handleChange(contract.id)}/>
            ))}
          </Tabs>
        </AppBar>
        {hasPermissions([Permissions.SUPER_CONFIGURATION]) &&
          <div id='list'>
            <TeamList contractID={this.state.selectedContractID} />
          </div>}
      </PaperSheet>
    )
  }
}
export default(TeamsListView)
