import React from 'react'
import { Form } from 'formik'
import { Grid } from '@material-ui/core'
import moment from 'moment'
import RoleContractSelectorField from '../common/selects/RoleContractSelectorField'
import FormikTextField from '../common/FormikTextField'
import SubmitButton from '../common/buttons/SubmitButton'
import FormikDatePicker from '../common/FormikDatePicker'
import { cpNumberCheck, PMCheck, TOCheck } from '../../utils/ValidationFunctions'
import Permissions from '../../permissionsList'
import PermissionControl from '../common/permissionControl'
import CustomFormik from '../common/CustomFormik'

interface Props {
  onSubmit: (values: Fields) => void
  submitted: boolean
  submitting: boolean
  values?: Fields
  detailed?: boolean
  disabled?: boolean
}

export interface Fields {
  id: number
  CPNumber: number | null
  deadlineDate: number | null
  projectManager: string
  technicalOwner: string
  contractId: number | null
}

export default class CPForm extends React.Component<Props> {
  render () {
    return (
      <CustomFormik
        initialValues={this.props.values || {
          CPNumber: null,
          deadlineDate: moment().startOf('day').unix(),
          contractId: null,
          projectManager: '',
          technicalOwner: ''
        } as Fields}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false) // I'm not using Formik's submitting tracking. It doesn't have a concept of submitted, so both states should be tracked by the parent.
          this.props.onSubmit(values)
        }}
        submitted={this.props.submitted}
        isDisabled={this.props.disabled}
        render={(formProps) => (
          <Form>
            <Grid container spacing={40}>
              <FormikTextField name='CPNumber' label='CP Number' type='number' required inputProps={ { maxLength: 5, min: 0 } } validate={cpNumberCheck} disabled={this.props.detailed}/>
              <FormikDatePicker name='deadlineDate' label='Deadline Date' />
              <FormikTextField name='title' label='Title' inputProps={ { maxLength: 255 } }/>
              <FormikTextField name='projectManager' label='Project Manager' type='string' required validate={PMCheck}/>
              <FormikTextField name='technicalOwner' label='Technical Owner' type='string' required validate={TOCheck}/>
              <RoleContractSelectorField name='contractId' label='Contract Type' validate={(v) => v === null ? 'A contract is required.' : undefined} disabled={this.props.detailed} route='/contract' xs={4} required/>
              <PermissionControl
                allowedPermissions={[Permissions.CREATE_UPDATE_CP]}
              >
              <Grid container spacing={0} justify='flex-end'>
                <Grid item xs={2} >
                  <SubmitButton onClick={formProps.submitForm} submitting={this.props.submitting} submitted={this.props.submitted} />
                </Grid>
              </Grid>
              </PermissionControl>
            </Grid>
          </Form>
        )}
      />
    )
  }
}
