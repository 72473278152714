import React from 'react'
import { Grid, ListItem, Theme, WithStyles, Fab } from '@material-ui/core'
import FormikTextField from '../common/FormikTextField'
import withStyles from '@material-ui/core/styles/withStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
import NonLabourItemSelectorField from './NonLabourItemsSelectorField'
import Delete from '@material-ui/icons/Delete'
import PermissionControl from '../common/permissionControl'
import Permissions from '../../permissionsList'
import { FormDisabledProps, withFormDisabled } from '../common/CustomFormik'
import Tooltip from '@material-ui/core/Tooltip'

interface Props extends WithStyles, FormDisabledProps {
  row: number,
  values?: Fields
  types: NonLabourItemType[]
  delete: (e: React.MouseEvent<HTMLElement>) => void
}

export interface Fields {
  typeId: any
  quantity: number
  cost: number
  Details: string
}

const styles = (theme: Theme) => ({
  root: {
    width: '100%'
  }
})

class NonLabourRow extends React.Component<Props> {
  render () {
    return (
      <Grid item>
        <ListItem id='noonlabourrow'>
          <Grid item xs={1}>
            <PermissionControl allowedPermissions={[Permissions.IMPACT_CP_ON_TEAM]}>
              <Tooltip title='Delete' placement='left'>
                <Fab color='primary' onClick={this.props.delete} aria-label='Delete' disabled={this.props.isDisabled}>
                  <Delete />
                </Fab>
              </Tooltip>
            </PermissionControl>
          </Grid>
          <Grid container spacing={40} style={ { width: '100%' } }>
            <NonLabourItemSelectorField types={this.props.types} name={`items[${this.props.row}].typeId`}/>
            <FormikTextField name={`items[${this.props.row}].quantity`} label='Quantity' placeholder='Quantity' type='number' xs={2} required inputProps={ { maxLength: 6, min: 0 } }/>
            <FormikTextField name={`items[${this.props.row}].cost`} label='Cost' type='number' xs={2} required inputProps={ { maxLength: 10, min: 0 } } InputProps={ { startAdornment: <InputAdornment position='start'>£</InputAdornment> } }/>
            <FormikTextField name={`items[${this.props.row}].detail`} label='Details' placeholder='Details' type='string' xs={3} required multiline rowsMax='3' inputProps={ { maxLength: 400 } }/>
          </Grid>
        </ListItem>
      </Grid>
    )
  }
}

export default withStyles(styles)(withFormDisabled(NonLabourRow))
