import React, { FormEvent } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import { Theme, WithStyles } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import Helmet from 'react-helmet'

import { withSessionContext, SessionContextProps } from '../SessionContext'
import { withConfigContext, ConfigContextProps } from '../ConfigContext'
import api, { isErrorResponse } from '../api'
import qrcode from 'qrcode-generator'

const styles = (theme: Theme) => createStyles({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
})

interface Props extends SessionContextProps, ConfigContextProps, WithStyles {
  embedded?: boolean
  embeddedWithLogo?: boolean
  deactivated?: boolean
}

interface State {
  processing: boolean
  error: null | string
  email: string
  password: string
  passwordReset: boolean
  hasMFA: boolean
  qrcodeURL: string
  doMFA: boolean
  totp: string
}

class LoginPage extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      processing: false,
      error: null,
      email: '',
      password: '',
      passwordReset: false,
      hasMFA: false,
      qrcodeURL: '',
      doMFA: false,
      totp: ''
    }
    this.onSubmit = this.onSubmit.bind(this)
  }

  async onSubmit (e: FormEvent) {
    e.preventDefault()
    if (this.props.deactivated) return this.setState({ error: 'Oops, nice try! This isn\'t a real functioning login form.' })
    this.setState({ processing: true, error: null })
    if (this.state.doMFA) {
      var response = await api<TokenResponse>('auth/verifyMFA', { method: 'POST', body: JSON.stringify({ email: this.state.email, totp: this.state.totp }) })
      if (isErrorResponse(response)) return this.setState({ processing: false, error: response.error })
      this.props.sessionContext.changeTokenObject(response.payload)
    } else {
      var result = await api<FirstAuthResponse>('auth', { method: 'POST', body: JSON.stringify({ email: this.state.email, password: this.state.password }) })
      if (isErrorResponse(result)) return this.setState({ processing: false, error: result.error })
      if (result.payload.mfaUrl === undefined) {
        return this.setState({ hasMFA: true, doMFA: true, processing: false })
      } else {
        var mfaText = result.payload.mfaUrl
        try {
          var qrImg = qrcode(10, 'L')
          qrImg.addData(mfaText)
          qrImg.make()
          this.setState({ qrcodeURL: qrImg.createDataURL() })
        } catch (error) {
          return this.setState({ processing: false, error: 'Could not create QR Code' })
        }
        return this.setState({ hasMFA: false, processing: false, doMFA: true })
      }
    }
  }

  render () {
    const { classes } = this.props
    var LoginContent = (
      <Paper className={classes.paper}>
        {!this.props.deactivated && <Helmet title={`Login - ${this.props.configContext.config.title}`} />}
        {this.state.processing ? <CircularProgress /> : <Avatar className={classes.avatar}><LockOutlinedIcon /></Avatar>}
        <Typography>{this.props.configContext.config.title}</Typography>
        <Typography component='h1' variant='h5'>{this.state.passwordReset ? 'Reset your password' : 'Sign in'}</Typography>
        {this.state.error && <Typography color='error'>{this.state.error}</Typography>}

        <form className={classes.form} onSubmit={this.state.processing ? undefined : this.onSubmit}>
        {!this.state.passwordReset && <React.Fragment>
            {!this.state.doMFA ?
            <React.Fragment>
              <FormControl margin='normal' required fullWidth>
                <InputLabel htmlFor='email'>Email Address</InputLabel>
                <Input id='email' name='email' autoComplete='email' autoFocus value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
              </FormControl>
              <FormControl margin='normal' required fullWidth>
                <InputLabel htmlFor='password'>Password</InputLabel>
                <Input name='password' type='password' id='password' autoComplete='current-password' value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })}/>
              </FormControl>
            </React.Fragment> :
            <React.Fragment>
              {!this.state.hasMFA &&
              <React.Fragment>
                <Typography>Scan this QRCode</Typography>
                <img src={this.state.qrcodeURL} />
              </React.Fragment>}
              <FormControl margin='normal' required fullWidth>
                <InputLabel htmlFor='totp'>Enter ToTP</InputLabel>
                <Input id='totp' name='totp' autoComplete='off' autoFocus value={this.state.totp} onChange={(e) => this.setState({ totp: e.target.value })} />
              </FormControl>
            </React.Fragment>}
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
            >
              Sign In
            </Button>
          </React.Fragment>}
          {this.state.passwordReset && <span>Please request a password reset link from an Impacting administrator.</span>}
          {!this.state.passwordReset && <Button color='primary' fullWidth onClick={() => this.setState({ passwordReset: true })}>Forgotten password?</Button>}
          {this.state.passwordReset && <Button color='primary' fullWidth onClick={() => this.setState({ passwordReset: false })}>Back</Button>}
        </form>
        <Typography>*This system now uses Multi-Factor Authentication. Please refer to the guidelines issued via POA Red Top to setup your access.</Typography>
      </Paper>
    )

    if (this.props.embedded) return LoginContent

    var AdditionalContent = (
      <React.Fragment>
        {LoginContent}
      </React.Fragment>
    )
    if (this.props.embeddedWithLogo) return AdditionalContent
    return (
      <main className={classes.main}>
        {AdditionalContent}
      </main>
    )
  }
}

export default withStyles(styles)(withConfigContext(withSessionContext(LoginPage)))
