import React, { Component } from 'react'
import { TableRow, TableCell, Fab } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'

interface Props extends RouteComponentProps {
  name: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  teamId: number
}
interface State {
  contract: any
  loading: boolean
}
class MyTeamListItem extends Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      contract: {},
      loading: false
    }
  }

  render () {
    return (
      <TableRow style={{ cursor: 'pointer' }} hover >
        <TableCell align='center' onClick={this.props.onClick}>{this.props.name}</TableCell>
         <TableCell align='center'>
          <Fab
          variant='extended'
          aria-label='Maintain Users'
          style={{ backgroundColor: 'green', color: '#fff' }}
          onClick={() => this.props.history.push('/myteams/team/' + this.props.teamId)}>
            Maintain Team
          </Fab></TableCell>
      </TableRow>
    )
  }
}

export default withRouter(MyTeamListItem)
