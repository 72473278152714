import React, { FormEvent } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Theme, WithStyles } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Helmet from 'react-helmet'
import { RouteComponentProps } from 'react-router'

import { withSessionContext, SessionContextProps } from '../SessionContext'
import { withConfigContext, ConfigContextProps } from '../ConfigContext'
import api, { isErrorResponse } from '../api'

const styles = (theme: Theme) => createStyles({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
})

interface Props extends SessionContextProps, ConfigContextProps, WithStyles, RouteComponentProps<{ resetToken: string }> {
  embedded?: boolean
}

interface State {
  attemptingReset: boolean
  resetComplete: boolean
  error: null | string
  email: string
  oldPassword: string
  newPassword: string
  repeatPassword: string
}

class ResetPassword extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      attemptingReset: false,
      resetComplete: false,
      error: null,
      email: '',
      oldPassword: '',
      newPassword: '',
      repeatPassword: ''
    }
    this.onSubmit = this.onSubmit.bind(this)
  }

  async onSubmit (e: FormEvent) {
    e.preventDefault()

    if (this.state.newPassword !== this.state.repeatPassword) return this.setState({ attemptingReset: false, error: 'New password fields do not match' })

    this.setState({ attemptingReset: true, error: null })
    var result
    if (this.props.match.params.resetToken) {
      result = await api<{ success: true }>('auth/password', {
        headers: {
          Authorization: 'Bearer ' + this.props.match.params.resetToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'put',
        body: JSON.stringify({ newPassword: this.state.newPassword })
      })
    } else {
      result = await api<{ success: true }>('auth/password', { method: 'put', body: JSON.stringify({ oldPassword: this.state.oldPassword, newPassword: this.state.newPassword }) })
    }

    if (isErrorResponse(result)) return this.setState({ attemptingReset: false, error: result.error })
    this.setState({ attemptingReset: false, resetComplete: true })
    if (this.props.match.params.resetToken) this.props.history.push('/')
  }

  render () {
    const { classes } = this.props
    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Helmet title={`Password Reset - ${this.props.configContext.config.title}`} />
          {this.state.attemptingReset ? <CircularProgress /> : <Avatar className={classes.avatar}><LockOutlinedIcon /></Avatar>}
          {this.props.configContext.config.title}
          <Typography component='h1' variant='h5'>
            Reset your password
          </Typography>
          {this.state.error && <Typography color='error'>{this.state.error}</Typography>}
          {this.state.resetComplete && <Typography>Password has been changed successfully.</Typography>}
          <form className={classes.form} onSubmit={this.onSubmit}>
          { /* we only want to ask for the old password if they arent going through the password reset token thing*/}
            {!this.props.match.params.resetToken && <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='oldPassword'>Old Password</InputLabel>
              <Input id='oldPassword' type='Password' name='oldPassword' autoComplete='old password' autoFocus value={this.state.oldPassword} onChange={(e) => this.setState({ oldPassword: e.target.value })} />
            </FormControl>}
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='newPassword'>New Password</InputLabel>
              <Input name='newPassword' type='Password' id='newPassword' autoComplete='new password' value={this.state.newPassword} onChange={(e) => this.setState({ newPassword: e.target.value })}/>
            </FormControl>
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='newPassword'>Confirm Password</InputLabel>
              <Input name='repeatPassword' type='Password' id='repeatPassword' autoComplete='repeat password' value={this.state.repeatPassword} onChange={(e) => this.setState({ repeatPassword: e.target.value })}/>
            </FormControl>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
            >
              Reset Password
            </Button>
          </form>
        </Paper>
      </main>
    )
  }
}

export default withStyles(styles)(withSessionContext(withConfigContext(ResetPassword)))
