import React, { Component } from 'react'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import { Field, FieldProps, FieldConfig } from 'formik'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { FormDisabledProps, withFormDisabled } from './CustomFormik'

/**
 * A helper to use `material-ui` text fields and have them automatically be picked up by Formik.
 * We render a Grid of xs=4 to put the field in, if that matters.
 */
export default class FormikCheckBox extends Component<FieldConfig & CheckboxProps & Props> {
  render () {
    // Use of Field from Formik hooks it into the form, and passing it a component allows it to show our text field.
    return (
      <Field {...this.props} component={WrappedCheckbox}>
        {this.props.children}
      </Field>
    )
  }
}

/**
 * Fields are like a HOC, and provide the field and form states as props to the child component. This is the child that provides our text box.
 */
class TheCheckBox extends Component<FieldProps & CheckboxProps & Props & FormDisabledProps> {
  render () {
    var { field, inForm, form, isDisabled, ...additionalProps } = this.props
    // if the checkbox is in the forms i want it to have a label, but if not then no label-enabling object should be around it (formatting goes weird)
    if (inForm) {
      return (
        <FormControlLabel control={<Checkbox
        {...additionalProps}
        name={field.name}
        checked={field.value || false}
        onChange={field.onChange}
        disableRipple
        disabled={isDisabled || this.props.disabled}
      />} label = {this.props.label || 'Disabled?'} />
      )
    } else {
      return (
        <Checkbox
        {...additionalProps}
        name={field.name}
        checked={field.value || false}
        onChange={field.onChange}
        onClick={this.props.onClick}
        disableRipple
        disabled={isDisabled || this.props.disabled}
      />
      )
    }
  }
}

const WrappedCheckbox = withFormDisabled(TheCheckBox)

interface Props {
  inForm?: boolean
  label?: string
}
