import React from 'react'
import { DatePicker } from 'material-ui-pickers'
import { Field, FieldProps, FieldConfig, getIn } from 'formik'
import Grid from '@material-ui/core/Grid'
import { withFormDisabled, FormDisabledProps } from './CustomFormik'
import moment, { Moment } from 'moment'
import { DatePickerModalProps } from 'material-ui-pickers/DatePicker/DatePickerModal'
import { Omit } from '@material-ui/core'

/**
 * This date picker will appear to use timestamps, or null. Internally it uses Moment.
 */
export default class FormikDatePicker extends React.Component<FieldConfig & CustomDatePickerProps> {
  render () {
    // Use of Field from Formik hooks it into the form, and passing it a component allows it to show our text field.
    return (
      <Field {...this.props} component={WrappedDateField}>
        {this.props.children}
      </Field>
    )
  }
}

interface CustomDatePickerProps extends Omit<DatePickerModalProps, 'value' | 'onChange'> {
  startOrEnd?: 'start' | 'end'
  allowInitialNull?: boolean
  /**
   * Caution, this will automatically submit without validation.
   */
  autoSubmitOnChange?: boolean
}

class DatePickerField extends React.Component<FieldProps & FormDisabledProps & CustomDatePickerProps> {
  onChange = (date: Moment) => {
    var timestamp: number
    try {
      if (this.props.startOrEnd === 'start') timestamp = date.startOf('day').unix()
      else if (this.props.startOrEnd === 'end') timestamp = date.endOf('day').unix()
      else timestamp = date.utc().unix()
    } catch {
      // TODO/comment: When will we encounter this? Would Moment throw?
      timestamp = NaN
    }
    // Cannot validate when autoSubmitting as it is internally asynchronous:
    this.props.form.setFieldValue(this.props.field.name, timestamp, this.props.autoSubmitOnChange ? false : true)
    if (this.props.autoSubmitOnChange) this.props.form.submitForm()
  }

  render () {
    var { field, form, isDisabled, startOrEnd, allowInitialNull, autoSubmitOnChange , ...additionalProps } = this.props
    var errorMessage = getIn(form.touched, field.name) && getIn(form.errors, field.name)
    if (!allowInitialNull && field.value == null) {
      if (startOrEnd === 'end') setTimeout(() => form.setFieldValue(field.name, moment().endOf('day').unix()), 10)
      else setTimeout(() => form.setFieldValue(field.name, moment().startOf('day').unix()), 10)
    }
    return (
    <Grid item xs={4}>
      <DatePicker
        {...additionalProps}
        disabled={isDisabled}
        keyboard
        clearable
        fullWidth
        name={field.name}
        maxDate='2038-01-19'
        value={field.value ? moment.unix(field.value) : null}
        format='DD/MM/YYYY'
        helperText={typeof errorMessage === 'object' ? '' : errorMessage}
        error={Boolean(errorMessage)}
        onError={(_, error: any) => form.setFieldError(field.name, error)}
        onChange={this.onChange}
        mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
      />
    </Grid>
    )
  }
}

const WrappedDateField = withFormDisabled(DatePickerField)
