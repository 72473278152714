import React from 'react'
import Fab from '@material-ui/core/Fab'
import Add from '@material-ui/icons/Add'
import { FormDisabledProps, withFormDisabled } from '../CustomFormik'

interface Props extends FormDisabledProps {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  title?: string
  id?: string
}

class AddButton extends React.Component<Props> {
  render () {
    return (
        <Fab id={this.props.id} variant='extended' color='primary' disabled={this.props.isDisabled} onClick={this.props.onClick} style={!this.props.isDisabled ? { backgroundColor: 'green', color: '#fff' } : undefined}>
          <Add />{this.props.title}
        </Fab>
    )
  }
}

export default withFormDisabled(AddButton)
