import React, { Component } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import api, { isErrorResponse } from '../../../api'
import { FieldConfig, Field, FieldProps } from 'formik'

/**
 * A Formik-compatible field that allows a role type to be selected. It returns a number type - not a string!
 */
export default class StatusSelectorField extends Component<FieldConfig & Props> {
  render () {
    return (
      <Field {...this.props} component={StatusSelector} />
    )
  }
}
interface Props {
  disabled?: boolean | undefined
  /**
   * Caution, will disable validation for this field.
   */
  autoSubmitOnChange?: boolean
}

interface State {
  selections: Status[]
}

class StatusSelector extends Component<FieldProps & Props, State> {
  constructor (props: FieldProps & Props) {
    super(props)
    this.state = {
      selections: []
    }
  }

  async componentDidMount () {
    var response = await api<Status[]>('/status', { method: 'GET' })
    if (isErrorResponse(response)) return
    this.setState({ selections: response.payload })
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    if (this.props.autoSubmitOnChange) {
      this.props.form.setFieldValue(this.props.field.name, e.target.value, false)
      this.props.form.submitForm()
    } else {
      this.props.field.onChange(e)
    }
  }

  render () {
    var errorMessage = this.props.form.touched[this.props.field.name] && this.props.form.errors[this.props.field.name]
    return (
      <Grid item xs={4}>
        <TextField
          select
          name={this.props.field.name}
          label='Status'
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          value={this.props.field.value || ''}
          onChange={this.onChange}
          onBlur={this.props.field.onBlur}
          disabled={this.state.selections.length === 0 || this.props.disabled}
          fullWidth
        >
          <MenuItem key={0} value={-1}>Any</MenuItem>
          {this.state.selections.map(selection => (
            <MenuItem key={selection.id + 1} value={selection.id}>
              {selection.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    )
  }
}
