import React from 'react'
import classNames from 'classnames'

import {
  withStyles, Drawer, List, Divider,
  IconButton, ListItem, ListItemIcon, ListItemText, Tooltip,
  Theme, WithStyles, createStyles
} from '@material-ui/core'

import ChevronLeft from '@material-ui/icons/ChevronLeft'
import Search from '@material-ui/icons/Search'
import Build from '@material-ui/icons/Build'
import Add from '@material-ui/icons/Add'
import Home from '@material-ui/icons/Home'
import Help from '@material-ui/icons/Help'
import ExitToApp from '@material-ui/icons/ExitToApp'
import History from '@material-ui/icons/History'
import DescriptionIcon from '@material-ui/icons/Description'
import Cached from '@material-ui/icons/Cached'

import { Link } from 'react-router-dom'
import { withSessionContext, SessionContextProps } from '../../SessionContext'
import { fade } from '@material-ui/core/styles/colorManipulator'
import red from '@material-ui/core/colors/red'
import PermissionControl from './permissionControl'
import Permissions from '../../permissionsList'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const drawerWidth = 240

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: red[500],
    display: 'flex'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  logOutItem: {
    marginTop: 'auto',
    padding: 0
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    marginRight: 12,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit,
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

interface Props extends WithStyles, SessionContextProps {
  open: boolean
  handleDrawerClose: () => void
  theme: any
}

class Menu extends React.Component<Props> {
  logout = async () => {
    this.props.sessionContext.logout()
  }

  state = {
    open: false
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    })
  }

  handleClickAway = () => {
    this.props.handleDrawerClose()
  }

  render () {
    const { classes } = this.props

    return (
      <div className={classes.root}>
       <ClickAwayListener onClickAway={this.handleClickAway}>
        <Drawer onClose={this.props.handleDrawerClose}
          variant='permanent'
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.props.open,
            [classes.drawerClose]: !this.props.open
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.props.open,
              [classes.drawerClose]: !this.props.open
            })
          }}
          open={this.props.open}
          style={{ background: '#a30b1a' }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={this.props.handleDrawerClose}>
              <ChevronLeft />
            </IconButton>
          </div>
          <Divider />
          <List onClick={this.props.handleDrawerClose}>
            <Tooltip title='Home' placement='right'>
              <ListItem button component={({ innerRef, ...props }) => <Link {...props} to='/' />}>
                <ListItemIcon><Home /></ListItemIcon>
                <ListItemText>Home</ListItemText>
              </ListItem>
            </Tooltip>
            <Tooltip title='CP Impacting' placement='right'>
              <ListItem button component={({ innerRef, ...props }) => <Link {...props} to='/cp' />}>
                <ListItemIcon><DescriptionIcon /></ListItemIcon>
                <ListItemText >CP Impacting</ListItemText>
              </ListItem>
            </Tooltip>
            <PermissionControl allowedPermissions={[Permissions.CREATE_UPDATE_CP]}>
              <Tooltip title='Add a CP' placement='right'>
                <ListItem button component={({ innerRef, ...props }) => <Link {...props} to='/cp/add' />}>
                  <ListItemIcon><Add /></ListItemIcon>
                  <ListItemText >Add a CP</ListItemText>
                </ListItem>
              </Tooltip>
            </PermissionControl>
            <Tooltip title='Search' placement='right'>
              <ListItem button component={({ innerRef, ...props }) => <Link {...props} to='/search' />}>
                <ListItemIcon><Search /></ListItemIcon>
                <ListItemText>Search</ListItemText>
              </ListItem>
            </Tooltip>
            <PermissionControl allowedPermissions={[Permissions.SUPER_CONFIGURATION]}>
              <Tooltip title='Configuration' placement='right'>
                <ListItem button component={({ innerRef, ...props }) => <Link {...props} to='/config' />}>
                  <ListItemIcon><Build /></ListItemIcon>
                  <ListItemText>Configuration</ListItemText>
                </ListItem>
              </Tooltip>
            </PermissionControl>
            <Tooltip title='Audit' placement='right'>
              <ListItem button component={({ innerRef, ...props }) => <Link {...props} to='/audit' />}>
                <ListItemIcon><History /></ListItemIcon>
                <ListItemText>Audit</ListItemText>
              </ListItem>
            </Tooltip>
            <Tooltip title='Help' placement='right'>
              <ListItem button component={({ innerRef, ...props }) => <Link {...props} to='/help' />}>
                <ListItemIcon><Help /></ListItemIcon>
                <ListItemText>Help</ListItemText>
              </ListItem>
            </Tooltip>
          </List>
          <List className={classes.logOutItem} onClick={this.props.handleDrawerClose}>
            <Tooltip title='Reset password' placement='right'>
              <ListItem button component={({ innerRef, ...props }) => <Link {...props} to='/passwordreset' />} >
                <ListItemIcon><Cached /></ListItemIcon>
                <ListItemText>Password Reset</ListItemText>
              </ListItem>
            </Tooltip>
            <Tooltip title='Log out' placement='right'>
              <ListItem button onClick={this.logout} >
                <ListItemIcon><ExitToApp /></ListItemIcon>
                <ListItemText>Log Out</ListItemText>
              </ListItem>
            </Tooltip>
          </List>
        </Drawer>
        </ClickAwayListener>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(withSessionContext(Menu))
