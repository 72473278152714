import React, { Component } from 'react'
import PaperSheet from '../../common/Paper'
import TeamForm, { Fields } from './TeamForm'
import api, { isErrorResponse } from '../../../api'
import Snackbar from '../../common/Snackbar'
import { hasPermissions } from '../../common/permissionControl'
import Permissions from '../../../permissionsList'
import CustomJoyride from '../../common/CustomJoyride'
import { RouteChildrenProps } from 'react-router'

interface State {
  submitting: boolean,
  submitted: boolean,
  error: string | null
}

export default class TeamPage extends Component<RouteChildrenProps, State> {
  constructor (props: RouteChildrenProps) {
    super(props)
    this.state = {
      submitting: false,
      submitted: false,
      error: null
    }
  }

  onTeamSubmit = async (values: Fields) => {
    this.setState({ submitting: true, error: null })
    var response = await api<Team>('team', { method: 'POST', body: JSON.stringify(values) })
    if (isErrorResponse(response)) return this.setState({ submitting: false, error: response.error })
    this.setState({ submitted: true, submitting: false })
    // Due to the creation of an anonymous function for the timeout, TypeScript no longer follows the `isErrorResponse` output.
    const id = response.payload.id
    setTimeout(() => this.props.history.push(`/config/teams/${id}/details`), 1500)
  }

  render () {
    return (
      <PaperSheet title='Create a Team'>
        <CustomJoyride
          continuous
          steps={[
            {
              target: '#paperSheet',
              content: 'You can create a new team here. The team must have a unique name on the selected contract type.'
            }
          ]}
        />
        {hasPermissions([Permissions.SUPER_CONFIGURATION]) && <TeamForm submitted={this.state.submitted} submitting={this.state.submitting} onSubmit={this.onTeamSubmit}/>}
        {this.state.error && <Snackbar message={this.state.error}></Snackbar>}
      </PaperSheet>
    )
  }
}
