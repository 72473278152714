import React, { Component } from 'react'
import api, { isErrorResponse, isPagedResponse } from '../../../api'
import TeamListItem from './TeamListItem'
import { Table, TableBody, TableFooter, TableRow, TablePagination, TableHead, TableCell, CircularProgress } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions'

interface Props extends RouteComponentProps {
  cpID?: number
  contractID: number
  cpTotal?: number
}
interface State {
  loading: boolean
  teams: Team[]
  page: number
  rowsPerPage: number
  total: number
  error: null | string
  contractID: number | null
}

class TeamList extends Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      error: null,
      loading: false,
      teams: [],
      page: 0,
      rowsPerPage: 20,
      total: 0,
      contractID: null
    }
    this.loadteam = this.loadteam.bind(this)
  }

  async loadteam () {
    this.setState({ loading: true, teams: [] })
    var response = await api<Team[]>(`/contract/${this.props.contractID}/team?page=${this.state.page + 1}&pageSize=${this.state.rowsPerPage}`, { method: 'GET' })
    if (isErrorResponse(response)) return this.setState({ loading: false, error: response.error })
    if (!isPagedResponse(response)) return
    return this.setState({ teams: response.payload, total: response.total, loading: false })
  }

  async componentDidMount () {
    this.loadteam().catch()
  }

  componentDidUpdate (prevProps: Props) {
    if (prevProps.contractID !== this.props.contractID) {
      this.setState({
        contractID: this.props.contractID
      })
      this.loadteam().catch()
    }
  }

  handleChangePage = (event: any, page: number) => {
    this.setState({ page }, this.loadteam)
  }

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) }, this.loadteam)
  }

  render () {
    return (
      <Table >
        <TableHead>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 20, 25]}
              count={this.state.total}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              SelectProps={{
                native: true
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions} />
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.loading && <TableRow><TableCell align='center'colSpan={4}><CircularProgress /></TableCell></TableRow>}
          {this.state.teams.map(team => (<TeamListItem
          name={team.name}
          key={team.id}
          onClick={() => this.props.history.push('/config/teams/' + team.id + '/details')}/>))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 20, 25]}
              count={this.state.total}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              SelectProps={{
                native: true
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions} />
          </TableRow>
        </TableFooter>
      </Table>
    )
  }
}
export default withRouter(TeamList)
