import React from 'react'
import PaperSheet from '../components/common/Paper'
import { Typography, Grid, Card, CardContent, CardMedia } from '@material-ui/core'
import Joyrideicon from '../assets/joyride/Joyrideicon.png'
import JoyrideRedDot from '../assets/joyride/JoyrideRedDot.png'
import { ConfigContextProps, withConfigContext } from '../ConfigContext'
import CustomJoyride from '../components/common/CustomJoyride'

interface Props extends ConfigContextProps {
}

interface State {
  email: string
  kenEmail: string,
  rosieEmail: string
}

class HelpPage extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      email: 'PostOfficeAccountChangeManagement@uk.fujitsu.com',
      kenEmail: 'Ken.Westfield@uk.fujitsu.com',
      rosieEmail: 'Rosie.Bacon@uk.fujitsu.com'
    }
  }
  render () {
    return (
      <PaperSheet title='Help guide' helmetTitle='Help'>
          <CustomJoyride
          continuous
          scrollToFirstStep
          steps={[
            {
              target: '#paperSheet',
              content: `This is the Help page. From here you can get quick guidence about the Web`
            },
            {
              target: '#toogle',
              content: `Yay! You did it!`
            }
          ]}
          />
        <Grid container spacing={40}>
          <Grid item xs={4}>
            <Card raised style={{ background: '#fff' }}>
              <CardMedia component='img' image={Joyrideicon} title='Joyride Button' />
              <CardContent style={{ textAlign: 'center' }}>
                <Typography gutterBottom style={{ color: '#000' }}>Clicking the question mark icon at the top right hand side of the website will activate the help guide on any page you are on.</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card raised style={{ background: '#fff' }}>
              <CardMedia component='img' image={JoyrideRedDot} title='Joyride Breadcrumb' style={{ height: '14' }}/>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography gutterBottom style={{ color: '#000' }}>Next click on the red dot that appears on the bottom of the screen to begin the tutorial.</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card raised style={{ background: '#fff' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography gutterBottom variant='h4' component='h2'>
                Contact
                </Typography>
                <Typography gutterBottom style={{ color: '#000' }} align='left'>If you have any questions please contact the <a href={'mailto:' + this.state.email}>Post Office Account Change Management</a> mailbox.</Typography>
                <Typography gutterBottom style={{ color: '#000' }} align='left'>If your query still isn't resolved, then please contact <a href={'mailto:' + this.state.kenEmail}>Ken Westfield</a> (+447867826109).</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </PaperSheet>
    )
  }
}

export default withConfigContext(HelpPage)
