import React from 'react'
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import DoneAll from '@material-ui/icons/DoneAll'
import Done from '@material-ui/icons/Done'
import Tooltip from '@material-ui/core/Tooltip'
import { connect, FormikContext } from 'formik'
import Snackbar from '../Snackbar'

const styles = (theme: Theme) => createStyles({
  buttonColors: {
    backgroundColor: '#3f53c6',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#3f53c6'
    }
  }
})

interface ExternalProps extends WithStyles {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  submitting?: boolean
  submitted?: boolean,
  isDisabled?: boolean
}

class SubmitButton extends React.Component<ExternalProps & { formik: FormikContext<{}> }> {
  render () {
    var hasValidationErrors = Object.keys(this.props.formik.errors).length > 0
    var Button = (
      <Fab id='submitButton' variant='extended' className={this.props.submitting ? undefined : hasValidationErrors ? undefined : this.props.classes.buttonColors} disabled={this.props.submitting || this.props.isDisabled || hasValidationErrors} onClick={this.props.submitting ? undefined : this.props.onClick}>
        {this.props.submitting ? 'Submitting' : this.props.submitted ? <React.Fragment><DoneAll />Submitted</React.Fragment> : <React.Fragment><Done />Submit</React.Fragment>}
      </Fab>
    )
    return (
        <React.Fragment>
          {hasValidationErrors && <Tooltip title='There are validation errors on this form.' placement='top'><div>{Button}</div></Tooltip>}
          {!hasValidationErrors && Button}
          {this.props.submitted && <Snackbar message='Submitted successfully!' />}
        </React.Fragment>
    )
  }
}

export default withStyles(styles)(connect<ExternalProps, {}>(SubmitButton))
