import { LocalTokenKey } from './SessionContext'

// A helper utility to fetch API endpoints, yo.

window.baseURL = process.env.NODE_ENV === 'development' ? `http://${window.location.hostname}:4000/api/` : '/api/'
/**
 * Returns data from the requested endpoint while handling tokens.
 * @param {string} endpoint The url of the desired endpoint
 * @param {object} [options] Customise the options given to the inner fetch request, such as changing it to a POST request.
 */
export default async function api<PayloadType = any> (endpoint: string, options?: RequestInit) {
  try {
    var token = 'NA'
    try {
      token = window.sessionContext.token!.tokenString
    } catch (error) {
      // console.warn('No tokenString could be found from sessionContext')
    }
    // We're used to API requests being really quick. But what if they're not? Let's fake the request being slow so we can check that loading spinners are used correctly, etc.
    // await delay(750)
    var request = await fetch(window.baseURL + endpoint, {
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      cache: 'no-cache',
      ...options
    })
    var response: ApiResponse<PayloadType> | PagedApiResponse<PayloadType> | ApiErrorResponse
    if (request.status !== 204) {
      response = JSON.parse(await request.text())
    } else {
      response = { status: 204 } as ApiResponse<never>
    }
    if (process.env.NODE_ENV === 'development') console.log('API Response', request.status, response)
    if (!response.status) throw new Error('No status was on the returned body - is .respond being used? This is an endpoint issue but I thought this scary red box would make it more obvious.')

    if (request.status !== 401) {
      extendToken()
    } else {
      if (((options || { }).method || 'GET').toUpperCase() !== 'GET') {
        window.sessionContext.setExpired()
      } else {
        window.sessionContext.logout(true)
      }
    }

    return response
  } catch (error) {
    if (error instanceof Error && error.message.includes('.respond')) throw error
    console.error(error)
    var fakeResponse: ApiErrorResponse = { error: 'Error during the API process.', status: 503 }
    return fakeResponse
  }
}

/**
 * Determine whether the response from the API is an error. It does this using the status code.
 */
export function isErrorResponse<PayloadType = any> (response: ApiResponse<PayloadType> | PagedApiResponse<PayloadType> | ApiErrorResponse): response is ApiErrorResponse {
  return response.status > 299
}

/**
 * Determine whether the response is paged. It looks for a page key.
 */
export function isPagedResponse<PayloadType = any> (response: ApiResponse<PayloadType> | PagedApiResponse<PayloadType>): response is PagedApiResponse<PayloadType> {
  return (response as PagedApiResponse<PayloadType>).page > 0
}

/**
 * Tokens renew their expiry time everytime they're used. We'll update the copy of ours stored locally so
 * the client doesn't kick us out if the browser refreshes.
 */
function extendToken () {
  try {
    if (!window.sessionContext.isLoggedIn) return
    var token = window.sessionContext.token!
    if (token.expiryTimestamp) {
      var newExpiry = Math.floor(Date.now() / 1000) + 1800
      if (process.env.NODE_ENV === 'development') console.log('API Extending stored token expiry:', token.expiryTimestamp + ' -> ' + newExpiry)
      token.expiryTimestamp = newExpiry
    }
    localStorage.setItem(LocalTokenKey, JSON.stringify(token))
  } catch (error) {
    console.warn('Naughtily extending the token didn\'t go that well', error)
  }
}
