import React, { Component } from 'react'
import classNames from 'classnames'
import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { fade } from '@material-ui/core/styles/colorManipulator'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import SearchIcon from '@material-ui/icons/Search'
import MenuIcon from '@material-ui/icons/Menu'
import Home from '@material-ui/icons/Home'
import { withRouter, RouteComponentProps } from 'react-router'
import Tooltip from '@material-ui/core/Tooltip'
import ArrowForward from '@material-ui/icons/ArrowForward'
import ArrowBack from '@material-ui/icons/ArrowBack'
import HelpIcon from '@material-ui/icons/Help'
import HelpOutlinedIcon from '@material-ui/icons/HelpOutline'
import { withSessionContext, SessionContextProps } from '../../SessionContext'
import { ConfigContextProps, withConfigContext } from '../../ConfigContext'
import { Fab, InputBase } from '@material-ui/core'
import PermissionControl from './permissionControl'
import Permissions from '../../permissionsList'

const drawerWidth = 240

const styles = (theme: Theme) => createStyles({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    paddingRight: '2%'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 5,
    marginRight: -10
  },
  hide: {
    display: 'none'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  grow: {
    flexGrow: 1
  },
  title: {
    display: 'none',
    paddingLeft: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    marginRight: 12,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit,
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200
      }
    }
  }
})

interface Props extends WithStyles, ConfigContextProps, RouteComponentProps, SessionContextProps {
  open: boolean
  handleDrawerOpen: () => void
}

interface State {
  searchTerm: string
}

interface KeyboardEvent {
  code: string
}

class NavBar extends Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchTerm: e.target.value })
  }

  render () {
    const { classes } = this.props

    return (
      <AppBar
        position='fixed'
        className={classNames(classes.appBar, {
          [classes.appBarShift]: this.props.open
        })}
      >
        <Toolbar disableGutters>
          <IconButton
            color='inherit'
            aria-label='Open drawer'
            onClick={this.props.handleDrawerOpen}
            className={classNames(classes.menuButton, {
              [classes.hide]: this.props.open
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant='h4' color='inherit' noWrap>
            {this.props.configContext.config.title}
          </Typography>
          <Tooltip title='Back' placement='right'>
            <IconButton aria-label='Back' color='inherit' style={{ marginLeft: 20 }} onClick={() => this.props.history.goBack()}>
              <ArrowBack/>
            </IconButton>
          </Tooltip>
          <Tooltip title='Home' placement='right'>
            <IconButton aria-label='Home' color='inherit' style={{ marginLeft: 20 }} onClick={() => this.props.history.push('/')}>
              <Home />
            </IconButton>
          </Tooltip>
          <Tooltip title='Forward' placement='right'>
            <IconButton aria-label='Forward' color='inherit' style={{ marginLeft: 20 }} onClick={() => this.props.history.goForward()}>
              <ArrowForward />
            </IconButton>
          </Tooltip>

          <div className={classes.grow} />
          <PermissionControl allowedPermissions={[Permissions.SUPER_CONFIGURATION]}>
            <a href='https://support.byte.enterprises' style={ { textDecoration: 'none' } }>
              <Fab variant='extended' style={ { backgroundColor: '#000', color: '#fff' } }>
                Report Bug
              </Fab>
            </a>
          </PermissionControl>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
              <InputBase
                id='Search'
                placeholder='Search…'
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={this.handleChange}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    this.props.history.push('/search?query=' + this.state.searchTerm)
                    ev.preventDefault()
                  }
                }}
              />
          </div>
          <Typography className={classes.title} variant='h6' color='inherit' noWrap>
            {this.props.sessionContext.token!.user.name}
          </Typography>
          {/* <IconButton aria-label='Dark Mode' color='inherit' style={{ marginLeft: '20' }} onClick={this.props.configContext.toggleDarkMode}>
            <InvertColorsIcon />
          </IconButton> */}
          <Tooltip title='Toggle help pins. Look out for the flashing circles!' itemID='tooglepin' placement='right'>
            <IconButton aria-label='Toggle help pins' id='toogle' color='inherit' style={{ marginLeft: '20' }} onClick={this.props.configContext.toggleHelp}>
              {this.props.configContext.helpActive ? <HelpIcon /> : <HelpOutlinedIcon />}
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    )
  }
}

export default withStyles(styles)(withRouter(withConfigContext(withSessionContext(NavBar))))
