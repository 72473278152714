import React, { Component } from 'react'
import PaperSheet from '../common/Paper'
import TeamUserForm, { Fields as TeamUserFields } from '../configuration/teams/TeamUserForm'
import GuidelineForm, { Fields as GuidelineFields } from './MyTeamGuidelineForm'
import api, { isErrorResponse } from '../../api'
import { RouteProps } from 'react-router-dom'
import { Tabs, Tab, Typography, AppBar } from '@material-ui/core'
import { TabProps } from '@material-ui/core/Tab'
import Snackbar from '../common/Snackbar'
import { hasPermissions } from '../common/permissionControl'
import Permissions from '../../permissionsList'
import isPartOfTeam from '../../utils/IsPartOfTeam'
import CustomJoyride from '../common/CustomJoyride'

// TODO: Sort out Joyride!!

interface Props {
  match: any
}

interface State {
  loading: boolean
  team?: TeamUserFields
  guideline?: GuidelineFields
  submitting: boolean
  submitted: boolean
  error: any
  value: number
}

export default class MyTeamPage extends Component<Props & RouteProps, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      loading: true,
      submitting: false,
      submitted: false,
      error: null,
      value: 0
    }
    this.loadTeam = this.loadTeam.bind(this)
  }

  async componentDidMount () {
    await this.loadTeam().catch(e => console.log(e))
    this.setState({ loading: false })
  }

  async loadTeam () {
    var response = await api('/team/' + this.props.match.params.Id, { method: 'GET' })
    if (isErrorResponse(response)) return this.setState({ loading: false, error: response.error })
    return this.setState({ team: response.payload, guideline: response.payload })
  }

  handleChange = (event: any, value: number) => {
    this.setState({ value, submitted: false, submitting: false })
  }

  onUserSubmit = async (values: TeamUserFields) => {
    this.setState({ submitting: true })
    var response = await api('teamuser/' + this.props.match.params.Id + '/array', { method: 'PUT', body: JSON.stringify(values) })
    if (isErrorResponse(response)) return this.setState({ submitting: false, error: response.error })
    // My original plan was to mutate the loaded team with the new values to fix the moving between tabs outdated bug.
    // However, the form gives us numbers (user IDs) which we can't make use of so we have to reload them.
    this.setState({ submitted: true, submitting: false }, this.loadTeam)
  }

  onGuidelineSubmit = async (values: GuidelineFields) => {
    this.setState({ submitting: true })
    var response = await api('/team/' + this.props.match.params.Id + '/guideline', { method: 'PUT', body: JSON.stringify(values) })
    if (isErrorResponse(response)) return this.setState({ submitting: false, error: response.error })
    this.setState({ submitted: true, submitting: false, guideline: values })
    setTimeout(() => this.setState({ submitted: false }), 2000)
  }

  render () {
    const { value } = this.state
    return (
      <PaperSheet title={this.state.team ? hasPermissions([Permissions.MANAGE_USERS_ON_TEAM]) ? this.state.team.name + ' - Maintain a Team' : this.state.team.name + ' - View Team Users' : ''}>
        <CustomJoyride
          continuous
          showSkipButton
          steps={[
            {
              target: '#tabs',
              content: 'There are two parts to the teams form, the first part is for adding the teams details and the second part (shown below) is for adding the users and assigning their positions within the team'
            },
            {
              target: '#impactguide',
              content: `Click on 'Impacting Guidelines' to add a description for impact.`
            },
            {
              target: '#impactGuidelinetxt',
              content: `This info will appear at the top of the impacting screen when anyone tries to impact for that team`
            }
          ]}
        />
        <AppBar position={'static'}>
          <Tabs value={value} variant='fullWidth' onChange={this.handleChange} id='tabs'>
            <Tab label='Maintain Users'/>
            <Tab label='Impacting Guidelines' id='impactguide' />
          </Tabs>
        </AppBar>
        {value === 0 &&
          <TabContainer id='userform'>
            {!this.state.loading && <TeamUserForm values={this.state.team} submitted={this.state.submitted} submitting={this.state.submitting} onSubmit={this.onUserSubmit} isDisabled={!(hasPermissions([Permissions.MANAGE_USERS_ON_TEAM]) && isPartOfTeam(this.state.team!.id, true))}/>}
            {this.state.error && <Snackbar message={this.state.error}></Snackbar>}
          </TabContainer>
        }
        {value === 1 &&
          <TabContainer id='guidelineform'>
            {!this.state.loading && <GuidelineForm values={this.state.guideline} submitted={this.state.submitted} submitting={this.state.submitting} onSubmit={this.onGuidelineSubmit} isDisabled={!(hasPermissions([Permissions.MANAGE_USERS_ON_TEAM]) && isPartOfTeam(this.state.team!.id, true))}/>}
            {this.state.error && <Snackbar message={this.state.error}></Snackbar>}
          </TabContainer>
        }
      </PaperSheet>
    )
  }
}

function TabContainer (props: TabProps) {
  return (
    <Typography component='div' style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  )
}
