import React, { Component } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { FieldConfig, Field, FieldProps } from 'formik'
import api, { isErrorResponse } from '../../api'
/**
 * A Formik-compatible field that allows a contract type to be selected. It returns a number type - not a string!
 */
export default class AuditSelectorField extends Component<FieldConfig & Props> {
  render () {
    return (
      <Field {...this.props} component={Selector} />
    )
  }
}

interface Props {
  disabled?: boolean | undefined,
  route: string,
  label: string
}

interface State {
  selections: (AuditType | AuditSubType | User)[]
}

class Selector extends Component<FieldProps & Props, State> {
  constructor (props: FieldProps & Props) {
    super(props)
    this.state = {
      selections: []
    }
  }

  async componentDidMount () {
    var response = await api<AuditType[] | AuditSubType[] | User[]>(this.props.route)
    let anySelect: any
    if (this.props.label === 'Users') {
      (anySelect as User) = ({
        id: 0,
        name: 'Any',
        email: 'User',
        isDisabled: true,
        hasMFA: false,
        roleId: 0,
        contractId: 0})
    } else {
      (anySelect as AuditType | AuditSubType) = ({ id: 0, name: 'Any' })
    }
    if (isErrorResponse(response)) return
    response.payload.unshift(anySelect)
    this.setState({ selections: response.payload })
  }
  render () {
    var errorMessage = this.props.form.touched[this.props.field.name] && this.props.form.errors[this.props.field.name]
    return (
      <Grid item xs={4}>
        <TextField
          select
          name={this.props.field.name}
          label={this.props.label}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          value={this.props.field.value || ''}
          onChange={this.props.field.onChange}
          onBlur={this.props.field.onBlur}
          disabled={this.state.selections.length === 0 || this.props.disabled}
          fullWidth
        >
          {this.state.selections.map(selection => (
            <MenuItem key={selection.id} value={selection.id}>
              {selection.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    )
  }
}
