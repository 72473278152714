import React from 'react'
import { Form } from 'formik'
import SubmitButton from '../../common/buttons/SubmitButton'
import UserMultiSelectorField from '../../common/selects/UserMultiSelectorField'
import { Grid } from '@material-ui/core'
import CustomFormik from '../../common/CustomFormik'

interface Props {
  onSubmit: (values: Fields) => void
  submitted: boolean,
  submitting: boolean,
  values?: Fields
  isDisabled?: boolean,
}

export interface Fields {
  id: number,
  name: string,
  contractId: number | null,
  defaultIds: number[] | null
  impactingUsers: number[] | null
  teamOwners: number[] | null
}

export default class TeamForm extends React.Component<Props> {
  render () {
    return (
      <CustomFormik
        initialValues={this.props.values || {
          impactingUsers: null,
          teamOwners: null
        } as Fields}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false) // I'm not using Formik's submitting tracking. It doesn't have a concept of submitted, so both states should be tracked by the parent.
          this.props.onSubmit(values)
        }}
        submitted={this.props.submitted}
        render={(formProps) => (
          <Form>
            <Grid container spacing={40}>
              <UserMultiSelectorField name='impactingUsers' label='Impacting Users' isDisabled={this.props.isDisabled}/>
              <UserMultiSelectorField name='teamOwners' label='Team Owners' isTeamOwner isDisabled={this.props.isDisabled}/>
            </Grid>
            <Grid container spacing={24} justify='flex-end'>
              <SubmitButton onClick={formProps.submitForm} submitting={this.props.submitting} submitted={this.props.submitted} isDisabled={this.props.isDisabled}/>
            </Grid>
          </Form>
        )}
      />
    )
  }
}
