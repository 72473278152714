export function passwordCheck (password: string | number | null): string | undefined {
  if (typeof password === 'number' || password === null) return 'Not a valid type'
  var passChars = 0
  var passNumber = false
  var passCapital = false
  var passSpecial = false
  var error = ''
  for (var i = 0; i < password.length; i++) {
    passChars ++
    var char = password.charCodeAt(i)
    if (char >= 65 && char <= 90) {
      passCapital = true
    } else if (char >= 48 && char <= 57) {
      passNumber = true
    } else if ((char >= 33 && char <= 47) || (char >= 58 && char <= 64) || (char >= 91 && char <= 96) || (char >= 123 && char <= 126)) {
      passSpecial = true
    }
  }
  if (passChars < 8) {
    error += 'Password is not 8 characters or more. '
  }
  if (!passCapital) {
    error += 'Password does not contain a capital letter. '
  }
  if (!passNumber) {
    error += 'Password does not contain a number. '
  }
  if (!passSpecial) {
    error += 'Password does not contain a special character. These can include: ! " # $ % & \' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _ ` '
  }
  if (error.length === 0) return undefined
  return error
}

export function emailCheck (email: string | number | null): string | undefined {
  if (typeof email === 'number' || email === null) return 'Not a valid type'
  var regex = /[A-Za-z0-9._%+-]+@([A-Za-z]+[A-Za-z]+(?:^|\W))?fujitsu\.com(?:$|\W)/
  return regex.test(email) ? undefined : 'Please enter a valid email in the format of x..x@xx.fujitsu.com or x...x@fujitsu.com'
}

export function impactCheck (impact: string | number | null): string | undefined {
  // impact === 0 requires a comment or documents affected. Handled at the form level.
  if (typeof impact === 'string' || impact === null) return 'A number is required. (You may delete the row)'

  if (impact < 0) return 'Must be a positive number'
  if (impact % 0.5 !== 0) return 'Impacts must be in increments of 0.5'
  return undefined
}

export function cpNumberCheck (CPNumber: string | number | null): string | undefined {
  if (typeof CPNumber === 'string' || CPNumber === null) return 'You must enter a number.'
  if (CPNumber > 99999) return 'CP number must not be more than 5 digits.'
  if (CPNumber < 1) return 'CP number must be greater than 0'
}

export function usernameCheck (name: string | number | null): string | undefined {
  if (typeof name === 'number' || name === null) return 'Not a valid type'
  var regex = /^[A-Za-z-' ]+$/
  return regex.test(name) ? undefined : 'Please enter a valid name. Names cannot contain numbers'
}
export function PMCheck (name: string | number | null): string | undefined {
  if (typeof name === 'number' || name === null) return 'Not a valid type'
  var regex = /^[A-Za-z-' ]+$/
  return regex.test(name) ? undefined : 'Please enter a valid name. Names cannot contain numbers'
}
export function TOCheck (name: string | number | null): string | undefined {
  if (typeof name === 'number' || name === null) return 'Not a valid type'
  var regex = /^[A-Za-z-' ]+$/
  return regex.test(name) ? undefined : 'Please enter a valid name. Names cannot contain numbers'
}
export function nameCheck (name: string): string | undefined {
  if ((name || '').length > 50) return 'Name cannot be more than 50 characters'
  var regex2 = /^([\w&.\-& ()]){0,50}$/
  return regex2.test(name) ? undefined : 'Please enter a valid name. Names can only contain letters, numbers, and &, -, (, )'
}

export function requiredTextFieldValidation (v: string | number | null) {
  return v !== null && v.toString().length > 0 ? undefined : 'This field is required.'
}
