import React from 'react'
import { Form } from 'formik'
import { Grid, Fab } from '@material-ui/core'
import FormikDatePicker from '../common/FormikDatePicker'
import CustomFormik from '../common/CustomFormik'
import StatusSelectorField from '../common/selects/StatusSelect'

interface Props {
  onSubmit: (values: Fields) => void
  values?: Fields
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  id?: string
}

export interface Fields {
  statusId: number
  fromDate: number | null
  toDate: number | null
}

export default class CPDateForm extends React.Component<Props> {
  render () {
    return (
      <CustomFormik
        disableUnsavedPrompt
        submitted
        initialValues={this.props.values || {
          statusId: -1,
          fromDate: null,
          toDate: null
        } as Fields}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false) // I'm not using Formik's submitting tracking. It doesn't have a concept of submitted, so both states should be tracked by the parent.
          this.props.onSubmit(values)
        }}
        render={formProps => (
          <Form id={this.props.id}>
            <Grid container spacing={40}>
              <StatusSelectorField name='statusId' autoSubmitOnChange />
              <FormikDatePicker name='fromDate' allowInitialNull autoSubmitOnChange label='From deadline date' startOrEnd='start' />
              <FormikDatePicker name='toDate' allowInitialNull autoSubmitOnChange label='To deadline date' startOrEnd='end' />
            </Grid>
          </Form>
        )}
      />
    )
  }
}
