import React, { Component } from 'react'
import { Grid, Card, CardContent, Fab, CircularProgress } from '@material-ui/core'
import PaperSheet from '../common/Paper'
import CPForm, { Fields } from './CPForm'
import api, { isErrorResponse } from '../../api'
import CPTeamList from './CPTeamList'
import { RouteComponentProps } from 'react-router'
import ExportButton from '../common/buttons/ExportButton'
import Lock from '@material-ui/icons/Lock'
import Unlock from '@material-ui/icons/LockOpen'
import Done from '@material-ui/icons/Done'
import Close from '@material-ui/icons/Close'
import PermissionControl, { hasPermissions } from '../common/permissionControl'
import Permissions from '../../permissionsList'
import CustomJoyride from '../common/CustomJoyride'
import Snackbar from '../common/Snackbar'

interface Props extends RouteComponentProps<{ id: string | undefined }> {
}

interface State {
  loading: boolean
  cp: CP | null
  submitting: boolean
  submitted: boolean
  cpTotal: number
  error: string | null
  locked: boolean
  cwoSubmitted: boolean
  togglingCPLock: boolean
}

export default class CPDetailedView extends Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      loading: true,
      cp: null,
      submitting: false,
      submitted: false,
      cpTotal: 0,
      error: null,
      locked: false,
      cwoSubmitted: false,
      togglingCPLock: false
    }
  }

  componentDidMount = async () => {
    this.loadTotal().catch(e => console.log(e))
    await this.loadCP()
  }

  loadCP = async () => {
    var response = await api('cp/' + this.props.match.params.id, { method: 'GET' })
    if (isErrorResponse(response)) return this.setState({ loading: false, error: response.error })
    var cp = response.payload
    // TODO: We need documentation or constants describing what this does. What is 3 for instance?
    // The permissions implementation avoids using magic numbers and I think it's more readable as a result.
    if (cp.statusId === 3) {
      this.setState({ locked: true, cwoSubmitted: false })
    } else if (cp.statusId === 4) {
      this.setState({ locked: true, cwoSubmitted: true })
    } else if (cp.statusId < 3) {
      this.setState({ locked: false, cwoSubmitted: false })
    }
    return this.setState({ loading: false, cp })
  }

  loadTotal = async () => {
    var response = await api<number>(`/cp/${this.props.match.params.id}/total`, { method: 'GET' })
    if (isErrorResponse(response)) return this.setState({ loading: false, error: response.error })
    return this.setState({ cpTotal: response.payload })
  }

  onSubmit = async (values: Fields) => {
    this.setState({ submitting: true })
    var response = await api('cp/' + this.props.match.params.id, { method: 'PUT', body: JSON.stringify(values) })
    if (isErrorResponse(response)) return this.setState({ submitting: false, error: response.error })
    this.setState({ submitted: true, submitting: false })
    setTimeout(() => this.setState({ submitted: false }), 2000)
  }

  async lockCP () {
    this.setState({ togglingCPLock: true })
    var lockResponse = await api('cp/' + this.props.match.params.id + '/status', { method: 'POST', body: JSON.stringify({ Locked: !this.state.locked }) })
    if (isErrorResponse(lockResponse)) return this.setState({ togglingCPLock: false, error: lockResponse.error })
    this.setState({ togglingCPLock: false, locked: !this.state.locked })
  }

  async submitCP () {
    if (this.state.locked === true && this.state.cwoSubmitted === false) {
      var lockResponse = await api('cp/' + this.props.match.params.id + '/status', { method: 'POST', body: JSON.stringify({ Submit: true }) })
      if (isErrorResponse(lockResponse)) return this.setState({ submitting: false, error: lockResponse.error })
      this.setState({ locked: true, cwoSubmitted: true })
    } else if (this.state.cwoSubmitted === true) {
      var unlockResponse = await api('cp/' + this.props.match.params.id + '/status', { method: 'POST', body: JSON.stringify({ Submit: false }) })
      if (isErrorResponse(unlockResponse)) return this.setState({ submitting: false, error: unlockResponse.error })
      this.setState({ locked: false, cwoSubmitted: false })
    }
  }

  render () {
    if (this.state.loading) {
      return (
        <PaperSheet title='CP Details'>
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        </PaperSheet>
      )
    }

    if (this.state.error) {
      return (
        <PaperSheet title='CP not found'>
          <Snackbar isError autoHideDuration={false} message={this.state.error.includes('not found') ? 'We couldn\'t find that CP.' : this.state.error}/>
        </PaperSheet>
      )
    }

    return (
      <PaperSheet
        title={'CP' + this.state.cp!.CPNumber + ' Details'}
        rightSideContent={
          <React.Fragment>
            <ExportButton id='export' fileLabel={'CP' + this.state.cp!.CPNumber.toString() } exportURL={`cp/${this.props.match.params.id}/xlsx`}/>
          </React.Fragment>
        }
        >
        <CustomJoyride
          continuous
          steps={[
            {
              target: '#paperSheet',
              content: 'This page has a few different functions, from viewing/editing a CP\'s details, to viewing an overview of impacts.'
            },
            {
              target: '#export',
              content: 'You can export a summary of the impacts on this CP into an Excel spreadsheet using this button.'
            },
            {
              target: '#form',
              content: 'This is the CP form. Those with permissions can change the details of the CP here, but anyone can see the values in these boxes.'
            },
            {
              target: '#impactList',
              content: 'This is a list of teams which are on the same contract type as this CP. Click on a team that you\'re on to impact for this team, or click on a different team to view what they have impacted.'
            }
          ]}
        />
        <Grid container spacing={40}>
          <Grid item xs={12} id='form'>
            <CPForm values={this.state.cp!} submitted={this.state.submitted} submitting={this.state.submitting} onSubmit={this.onSubmit} detailed disabled={this.state.cwoSubmitted || !hasPermissions([Permissions.CREATE_UPDATE_CP])} />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={8}>
          <PermissionControl allowedPermissions={[Permissions.SUPER_CONFIGURATION]}>
            <React.Fragment>
              <Grid item xs={2}>
                <Fab
                variant='extended'
                disabled={this.state.cwoSubmitted}
                aria-label='Lock a CP'
                style={this.state.cwoSubmitted ? undefined : { backgroundColor: '#000', color: '#fff' }}
                onClick={() => this.lockCP()}>
                  {this.state.togglingCPLock && <React.Fragment><CircularProgress /></React.Fragment>}
                  {!this.state.togglingCPLock && this.state.locked ? <React.Fragment><Unlock />Unlock CP</React.Fragment> : <React.Fragment><Lock />Lock CP</React.Fragment>}
                </Fab>
                </Grid>
                <Grid item xs={2}>
                <Fab
                variant='extended'
                disabled={!this.state.locked}
                aria-label='Submit all CP Impacts'
                style={this.state.locked ? { backgroundColor: 'red', color: '#fff' } : undefined }
                onClick={() => this.submitCP()}>
                {this.state.cwoSubmitted ? <React.Fragment><Close/>Re-Open CP</React.Fragment> : <React.Fragment><Done />Close CP</React.Fragment>}
                </Fab>
              </Grid>
            </React.Fragment>
          </PermissionControl>
        </Grid>
        <br />
        <Grid container spacing={40}>
          <Grid item xs={12} id='impactList'>
            <Card>
              <CardContent component='div'>
                <CPTeamList cp={this.state.cp!} CPLocked={this.state.locked} cpTotal={this.state.cpTotal} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </PaperSheet>
    )
  }
}
