import React, { Component } from 'react'
import Grid, { GridSize } from '@material-ui/core/Grid'
import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField'
import { Field, FieldProps, FieldConfig, getIn } from 'formik'
import { requiredTextFieldValidation } from '../../utils/ValidationFunctions'
import { FormDisabledProps, withFormDisabled } from './CustomFormik'
import { Typography } from '@material-ui/core'

interface FieldConfigImprovedNumber {
  type: 'number'
  validate?: (value: number | null) => string | Promise<string | undefined> | undefined
}

interface FieldConfigImprovedString {
  type?: 'string' | 'password'
  validate?: (value: string) => string | Promise<string | undefined> | undefined
}

// The validate function in FieldConfig takes a value parameter of type any, but our text fields will only ever produce, well... text. Unless they're of type number.
type FieldConfigImproved = (FieldConfigImprovedNumber | FieldConfigImprovedString) & Pick<FieldConfig, Exclude<keyof FieldConfig, 'validate'>>

type BaseProps = StandardTextFieldProps & { xs?: GridSize }
export type FormikTextFieldProps = FieldConfigImproved & BaseProps

/**
 * A helper to use `material-ui` text fields and have them automatically be picked up by Formik.
 * We render by default in a Grid of xs=4 to put the field in, if that matters.
 * If the required flag is set, a default validation function will be included to ensure the box has a value in Formik fields.
 * Setting a validation prop will override this behaviour with your own validation strategy.
 */
export default class FormikTextField extends Component<FormikTextFieldProps> {
  render () {
    // Use of Field from Formik hooks it into the form, and passing it a component allows it to show our text field.
    return (
      <Field {...this.props} component={WrappedTextField} validate={this.props.validate ? this.props.validate : this.props.required ? requiredTextFieldValidation : undefined}>
        {this.props.children}
      </Field>
    )
  }
}

/**
 * Fields are like a HOC, and provide the field and form states as props to the child component. This is the child that provides our text box.
 */
class TheActualTextField extends Component<BaseProps & FieldProps & FormDisabledProps> {
  render () {
    var { field, form, isDisabled, ...additionalProps } = this.props
    var errorMessage = getIn(form.touched, field.name) && getIn(form.errors, field.name)
    return (
      <React.Fragment>
        <Grid item xs={this.props.xs || 4 }>
          <TextField
            {...additionalProps}
            name={this.props.field.name}
            disabled={isDisabled || this.props.disabled}
            value={typeof this.props.field.value === 'number' ? this.props.field.value : this.props.field.value || '' }
            error={Boolean(errorMessage)}
            helperText={typeof errorMessage === 'object' ? '' : errorMessage}
            onChange={this.props.field.onChange}
            onBlur={this.props.field.onBlur}
            fullWidth
          />
          <Typography align={'right'} variant={'caption'}>
            { this.props.inputProps ? this.props.type === 'number' ? null : this.props.field.value ? this.props.field.value.toString().length + '/' + this.props.inputProps.maxLength : '0/' + this.props.inputProps.maxLength : null }
          </Typography>
        </Grid>
      </React.Fragment>
    )
  }
}

const WrappedTextField = withFormDisabled(TheActualTextField)
