import React, { Component } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Grid, { GridSize } from '@material-ui/core/Grid'
import { FieldConfig, Field, FieldProps, getIn } from 'formik'
import { FormDisabledProps, withFormDisabled } from '../common/CustomFormik'

/**
 * A Formik-compatible field that allows a contract type to be selected. It returns a number type - not a string!
 */
export default class ActivitySelectorField extends Component<FieldConfig & Props> {
  render () {
    return (
      <Field {...this.props} component={WrappedTextField} validate={(v: number | null) => v !== null && v.toString().length > 0 ? undefined : 'This field is required.'} />
    )
  }
}

interface Props {
  activities: Activity[]
  xs?: GridSize
  isDisabled?: boolean
}

class ActivitySelector extends Component<FieldProps & Props & FormDisabledProps> {
  render () {
    var { field, form, isDisabled, ...additionalProps } = this.props
    var errorMessage = getIn(form.touched, field.name) && getIn(form.errors, field.name)
    return (
      <Grid item xs={this.props.xs || 3}>
        <TextField
          {...additionalProps}
          className='activitySelectorField'
          select
          name={this.props.field.name}
          label='Activities'
          error={Boolean(errorMessage)}
          helperText={typeof errorMessage === 'object' ? '' : errorMessage}
          value={this.props.field.value || ''}
          onChange={this.props.field.onChange}
          onBlur={this.props.field.onBlur}
          required
          disabled={isDisabled || this.props.activities.length === 0 }
          fullWidth
        >
          {this.props.activities.map(defaultActivity => (
            <MenuItem key={defaultActivity.id} value={defaultActivity.id} disabled={defaultActivity.isDisabled}>
              {defaultActivity.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    )
  }
}

const WrappedTextField = withFormDisabled(ActivitySelector)
