import React, { Component } from 'react'
import Helmet from 'react-helmet'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import ConfigContext, { LocalConfigKey, IConfigContext, DarkModeKey } from './ConfigContext'
import api, { isErrorResponse } from './api'

interface State {
  config: Config | null
  darkMode: boolean
  helpActive: boolean
}

/**
 * This component will fetch the config for the current instance of the app and provide
 * it to components below it.
 */
export default class ConfigProvider extends Component<{}, State> {
  constructor (props: {}) {
    super(props)
    this.state = {
      config: null,
      darkMode: false,
      helpActive: false
    }
  }

  async componentDidMount () {
    try {
      var localConfigItem = localStorage.getItem(LocalConfigKey)
      if (localConfigItem) this.setState({ config: JSON.parse(localConfigItem) })
      if (localStorage.getItem(DarkModeKey) === 'on') this.setState({ darkMode: true })
    } catch (error) {
      console.error('Couldn\'t decode config JSON.', error)
    }

    var serverConfig = await this.getConfig()
    localStorage.setItem(LocalConfigKey, JSON.stringify(serverConfig))
    this.setState({ config: serverConfig })
  }

  async getConfig () {
    var response = await api<Config>('config')
    if (isErrorResponse(response)) throw new Error('Could not fetch system configuration from the API server')
    return response.payload
  }

  toggleHelp = () => {
    this.setState({ helpActive: !this.state.helpActive })
  }

  toggleDarkMode = () => {
    var darkMode = !this.state.darkMode
    this.setState({ darkMode })
    localStorage.setItem(DarkModeKey, darkMode ? 'on' : 'off')
  }

  render () {
    if (!this.state.config) return null
    var theme = createMuiTheme({
      palette: {
        primary: {
          main: '#A30B1A'
        },
        secondary: {
          main: '#2196f3',
          contrastText: '#000'
        },
        type: this.state.darkMode ? 'dark' : 'light'
      },
      typography: {
        useNextVariants: true,
        fontFamily: '"fujitsu"'
      }
    })

    var configContext: IConfigContext = {
      config: this.state.config,
      updateConfig: (config: Config) => this.setState({ config }),
      darkMode: this.state.darkMode,
      helpActive: this.state.helpActive,
      toggleHelp: this.toggleHelp,
      toggleDarkMode: this.toggleDarkMode
    }

    return (
      <ConfigContext.Provider value={configContext}>
        <Helmet>
          <link rel='shortcut icon' href={`data:image/png;base64,${this.state.config.favicon}`} />
        </Helmet>
        <MuiThemeProvider theme={theme}>
          {this.props.children}
        </MuiThemeProvider>
      </ConfigContext.Provider>
    )
  }
}
