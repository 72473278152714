import React, { Component } from 'react'
import SessionContext, { LocalTokenKey } from '../SessionContext'
import api from '../api'
interface State {
  token: TokenResponse | null,
  hasAttemptedLocalToken: boolean
  expired: boolean
}

/**
 * This component is almost near the root, and will handle recovering existing
 * sessions and providing the sessionContext to components below it.
 */
export default class SessionProvider extends Component<{}, State> {
  constructor (props: {}) {
    super(props)
    this.state = {
      hasAttemptedLocalToken: false,
      token: null,
      expired: false
    }
  }

  componentDidMount () {
    var localTokenItem = this.getTokenCookie()
    if (!localTokenItem) return this.setState({ hasAttemptedLocalToken: true }) // No token?
    var localToken: TokenResponse = JSON.parse(localTokenItem)
    if (Date.now() / 1000 > localToken.expiryTimestamp) return this.setState({ hasAttemptedLocalToken: true }) // An old and expired token?
    this.changeTokenObject(localToken) // Must be a good token!
  }

  changeTokenObject = (token: TokenResponse) => {
    this.setState({ token, hasAttemptedLocalToken: true, expired: false })
    this.setCookie(token)
  }

  logout = (noRevoke = false) => {
    if (!noRevoke) api('auth/revoke', { method: 'POST' }).catch(console.error)
    this.setState({ token: null })
    window.document.cookie = `${LocalTokenKey}=${''};secure;samesite=strict;expires=Thu, 01 Jan 1970 00:00:00 UTC` // Removing cookie by setting expiry to an old date time
  }

  getTokenCookie = () => {
    let name = LocalTokenKey + '='
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  setCookie = (text: any) => {
    document.cookie = `${LocalTokenKey}=${JSON.stringify(text)};secure;samesite=strict;max-age=3600`
  }

  setExpired = () => {
    this.setState({ expired: true })
  }

  render () {
    if (!this.state.hasAttemptedLocalToken) return null
    var isLoggedIn = Object.keys(this.state.token || {}).length > 1
    var sessionContext = {
      token: this.state.token,
      changeTokenObject: this.changeTokenObject,
      logout: this.logout,
      isLoggedIn,
      expired: this.state.expired,
      setExpired: this.setExpired
    }
    window.sessionContext = sessionContext

    return (
      <SessionContext.Provider value={sessionContext}>
        {this.props.children}
      </SessionContext.Provider>
    )
  }
}
