import React, { Component } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { FieldConfig, Field, FieldProps, getIn } from 'formik'
import { FormDisabledProps, withFormDisabled } from '../common/CustomFormik'

/**
 * A Formik-compatible field that allows a contract type to be selected. It returns a number type - not a string!
 */
export default class NonLabourItemSelectorField extends Component<FieldConfig & { types: NonLabourItemType[] }> {
  render () {
    return (
      <Field {...this.props} component={WrappedTextField} validate={(v: number | null) => v !== null && v.toString().length > 0 ? undefined : 'This field is required.'} />
    )
  }
}
interface Props extends FormDisabledProps, FieldProps {
  types: NonLabourItemType[]
}

class NonLabourItemSelector extends Component<Props> {
  constructor (props: Props) {
    super(props)
    this.state = {
    }
  }

  render () {
    var { field, form, isDisabled, ...additionalProps } = this.props
    var errorMessage = getIn(form.touched, field.name) && getIn(form.errors, field.name)
    return (
      <Grid item xs={3}>
        <TextField
        {...additionalProps}
          select
          name={this.props.field.name}
          label='Non-Labour Items'
          error={Boolean(errorMessage)}
          helperText={typeof errorMessage === 'object' ? '' : errorMessage}
          value={this.props.field.value || ''}
          onChange={this.props.field.onChange}
          onBlur={this.props.field.onBlur}
          disabled={this.props.isDisabled || this.props.types.length === 0}
          fullWidth
          required
        >
          {this.props.types.map(type => (
            <MenuItem key={type.id} value={type.id} disabled={type.isDisabled}>
              {type.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    )
  }
}

const WrappedTextField = withFormDisabled(NonLabourItemSelector)
