import React from 'react'
import { Form } from 'formik'
import RoleContractSelectorField from '../../common/selects/RoleContractSelectorField'
import FormikTextField from '../../common/FormikTextField'
import SubmitButton from '../../common/buttons/SubmitButton'
import ActivityMultiSelectorField from '../../common/selects/ActivityMultiSelectorField'
import { Grid } from '@material-ui/core'
import CustomFormik from '../../common/CustomFormik'
import { nameCheck } from '../../../utils/ValidationFunctions'

interface Props {
  onSubmit: (values: Fields) => void
  submitted: boolean,
  submitting: boolean,
  values?: Fields,
  disabled?: boolean
}

export interface Fields {
  id: number,
  name: string,
  contractId: number | null,
  defaultIds: number[] | null,
  impactingUsers: number[] | null,
  teamOwners: number[] | null,
}

export default class TeamForm extends React.Component<Props> {
  render () {
    return (
      <CustomFormik
        submitted={this.props.submitted}
        initialValues={this.props.values || {
          name: '',
          contractId: null,
          defaultIds: null
        } as Fields}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false) // I'm not using Formik's submitting tracking. It doesn't have a concept of submitted, so both states should be tracked by the parent.
          this.props.onSubmit(values)
        }}
        render={(formProps) => (
          <Form>
            <Grid container spacing={8} justify='flex-start'>
              <Grid item xs={3}>
                <Grid container spacing={0}>
                  <FormikTextField name='name' label='Team Name' placeholder='Team' type='string' required xs={12} inputProps={ { maxLength: 100 } } validate={nameCheck}/>
                  <RoleContractSelectorField name='contractId' label='Contract Type' validate={(v) => v === null ? 'A contract is required.' : undefined} disabled={this.props.disabled} route='/contract' xs={12}/>
                </Grid>
              </Grid>
              <Grid item xs ={9}>
                <Grid container spacing={0}>
                 <ActivityMultiSelectorField name='defaultIds' />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={0} justify='flex-end'>
              <SubmitButton onClick={formProps.submitForm} submitting={this.props.submitting} submitted={this.props.submitted} />
            </Grid>
          </Form>
        )}
      />
    )
  }
}
