import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import Divider from '@material-ui/core/Divider'
import Helmet from 'react-helmet'
import Grid from '@material-ui/core/Grid'
import { ConfigContextProps, withConfigContext } from '../../ConfigContext'

const styles = (theme: Theme) => createStyles({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 3,
    // 8em is an educated guess. Is there a defined height for the AppBar component? Similar with footer - it's *about* 3em.
    minHeight: 'calc(100vh - 8em)',
    marginTop: 70,
    marginLeft: 80,
    marginRight: 10,
    marginBottom: '3em',
    backgroundSize: '100vw',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(' + process.env.PUBLIC_URL + '/img/Hexagons.png)',
    overflow: 'hidden'
  }
})

interface Props extends WithStyles, ConfigContextProps {
  title: React.ReactNode
  rightSideContent?: React.ReactNode
  subtitle?: React.ReactNode
  helmetTitle?: string
  rightSubtitle?: React.ReactNode
}

/**
 * A PaperSheet is the box for content on a page. It also handles setting the page title.
 * You may add elements to the right hand side of the title.
 */
class PaperSheet extends React.Component<Props> {

  render () {
    const { classes, title, children } = this.props

    return (
      <Paper className={classes.paper} id='paperSheet' elevation={2}>
        <Helmet title={`${this.props.helmetTitle ? this.props.helmetTitle : this.props.title } - ${this.props.configContext.config.title}`} />
        <Grid container justify='space-between' alignItems='center'>
          <Grid>
            <Typography variant='h5' style={{ paddingBottom: 10, textAlign: 'left' }}>{title}</Typography>
          </Grid>
          {this.props.rightSideContent && <Grid style={{ paddingBottom: 10 }}>
            {this.props.rightSideContent}
          </Grid>}
        </Grid>
        <Grid container justify='space-between' alignItems='center'>
          <Typography variant='h6' style={{ paddingBottom: 10, textAlign: 'left' }}>{this.props.subtitle}</Typography>
          <div style={{ float: 'right' }}>{this.props.rightSubtitle}</div>
        </Grid>
        <Divider style={{ marginBottom: 20 }} />
        <div>{children}</div>
      </Paper>
    )
  }
}

export default withStyles(styles)(withConfigContext(PaperSheet))
