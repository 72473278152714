import React from 'react'
import { Tooltip, Theme, createStyles, withStyles, Fab, WithStyles } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import green from '@material-ui/core/colors/green'
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment'

const styles = (theme: Theme) => createStyles({
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  }
})

interface Props extends WithStyles {
  exportURL: string,
  ids?: any
  fileLabel?: string
  id?: string
}

interface State {
  processing: boolean
  error: boolean
}

/**
 * A pretty button to represent exporting a thing to Excel.
 */
class ExportButton extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      processing: false,
      error: false
    }
    /**
     * Let's talk refs. Refs are something we don't really use elsewhere. They're a way of directly getting to an element React has rendered to the page.
     * We use them here to get access to a hidden a element we render below, as it needs to be a part of the page for Firefox to acknowledge it.
     * This a element holds a data URI which the browser 'clicks' and then proceeds to download.
     */
    this.linkRef = React.createRef()
  }

  linkRef: React.RefObject<HTMLAnchorElement>

  attemptExport = async () => {
    this.setState({ error: false, processing: true })
    var request: any
    if (this.props.ids) {
      request = await fetch(window.baseURL + this.props.exportURL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${window.sessionContext.token!.tokenString}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.props.ids)
      })
    } else {
      request = await fetch(window.baseURL + this.props.exportURL, {
        headers: {
          'Authorization': `Bearer ${window.sessionContext.token!.tokenString}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
    }
    if (request.status !== 200) {
      console.error('Couldn\'t get exported data')
      this.setState({ error: true, processing: false })
      return
    }
    var blob = await request.blob()

    // Weird, but this is how you trigger a browser to download a blob.
    var filename = 'Export ' + moment().format('DD-MM-YYYY') + '.xlsx'
    if (this.props.fileLabel) {
      filename = this.props.fileLabel + ' - ' + filename
    }
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename)
    } else {
      var a = this.linkRef.current!
      a.href = URL.createObjectURL(blob)
      a.download = filename
      a.click()
    }
    this.setState({ error: false, processing: false })
  }

  render () {
    // TODO: Handle error events.
    return (
      <Tooltip title='Export to an Excel spreadsheet' placement='left'>
        <Fab id={this.props.id} variant='extended' onClick={this.attemptExport} disabled={this.state.processing}>
          {this.state.processing ? <CircularProgress /> : <FileCopyIcon />}Export
          <span style={{ height: '1px', width: '1px', position: 'absolute', overflow: 'hidden', top: '-10px' }}>
            <a ref={this.linkRef} href={'/'}>Export</a>
          </span>
        </Fab>
      </Tooltip>
    )
  }
}

export default withStyles(styles)(ExportButton)
