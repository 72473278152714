import React from 'react'
import PaperSheet from '../common/Paper'
import { Tabs, Tab, AppBar, CircularProgress } from '@material-ui/core'
import api, { isErrorResponse } from '../../api'
import { TabProps } from '@material-ui/core/Tab'
import MTSList from './MTSList'
import ExportButton from '../common/buttons/ExportButton'
import { RouteComponentProps, withRouter } from 'react-router'
import CustomJoyride from '../common/CustomJoyride'

interface State {
  stati: Status[]
  selectedStatusID: number
  teamName: string
  loading: boolean
}

interface Props extends TabProps, RouteComponentProps<{contractId: string, teamId: string}> {
  // nothing here!
}

class MTSTabDisplay extends React.Component <Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      stati: [],
      selectedStatusID: 1,
      teamName: '',
      loading: true
    }
  }

  handleChange = (newStatusId: number) => {
    this.setState({ selectedStatusID: newStatusId })
  }

  async componentDidMount () {
    var team = this.props.match.params.teamId
    var response = await api<Status[]>('status')
    if (isErrorResponse(response)) return
    this.setState({ stati: response.payload, loading: false })
    var teamResponse = await api<Team>('team/' + team)
    if (isErrorResponse(teamResponse)) return
    this.setState({ teamName: teamResponse.payload.name, loading: false })
  }

  render () {
    var contract = this.props.match.params.contractId
    var team = this.props.match.params.teamId
    var teamId = parseInt(this.props.match.params.teamId, 10)
    return (
      <PaperSheet title={this.state.teamName + ' - Status Reports'} rightSideContent={<ExportButton id='export' exportURL={`cp/contract/${contract}/status/${this.state.selectedStatusID}/team/${team}/xlsx`} />}>
        <CustomJoyride
          continuous
          scrollToFirstStep
          scrollOffset={400}
          steps={[
            {
              target: '#paperSheet',
              content: 'This page shows you a breakdown of a teams effort across CPs grouped by their status. You can click on any of the CPs to open the impacting form for that cp.'
            },
            {
              target: '#export',
              content: 'Click this button to export the effort data to an excel spreadsheet.'
            }
          ]}
        />
      <React.Fragment>
        <AppBar position={'static'}>
          <Tabs value={this.state.selectedStatusID} variant='fullWidth'>
            {this.state.stati.map(status => (
              <Tab value={status.id} label={status.name} key={status.id} onClick={() => this.handleChange(status.id)}/>
            ))}
          </Tabs>
        </AppBar>
        {this.state.loading && <CircularProgress />}
        {!this.state.loading && <MTSList teamId={teamId} statusId={this.state.selectedStatusID} />}
        </React.Fragment>
      </PaperSheet>
    )
  }
}

export default withRouter(MTSTabDisplay)
