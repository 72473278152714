import React from 'react'
import { Form } from 'formik'
import SubmitButton from '../common/buttons/SubmitButton'
import { Grid } from '@material-ui/core'
import CustomFormik from '../common/CustomFormik'
import FormikTextField from '../common/FormikTextField'

interface Props {
  onSubmit: (values: Fields) => void
  submitted: boolean,
  submitting: boolean,
  values?: Fields,
  isDisabled?: boolean
}

export interface Fields {
  guideline: string,
}

export default class GuidelineForm extends React.Component<Props> {
  render () {
    return (
      <CustomFormik
        submitted={this.props.submitted}
        initialValues={this.props.values || { guideline: '' } as Fields}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false) // I'm not using Formik's submitting tracking. It doesn't have a concept of submitted, so both states should be tracked by the parent.
          this.props.onSubmit(values)
        }}
        render={(formProps) => (
          <Form>
            <Grid container justify='center'>
              <FormikTextField name='guideline' label='Impacting Guideline' id='impactGuidelinetxt' inputProps={ { maxLength: 1000 } } multiline disabled={this.props.isDisabled}/>
            </Grid>
            <Grid container justify='flex-end'><SubmitButton onClick={formProps.submitForm} submitting={this.props.submitting} submitted={this.props.submitted} isDisabled={this.props.isDisabled} /></Grid>
          </Form>
        )}
      />
    )
  }
}
