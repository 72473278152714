import React, { Component } from 'react'
import api, { isErrorResponse } from '../../../api'
import GradeListItem from './GradeListItem'
import { Table, TableBody, TableFooter, TableRow, TableHead, TableCell, Dialog, Button, DialogActions, DialogContent, DialogTitle, Grid, CircularProgress } from '@material-ui/core'
import PaperSheet from '../../common/Paper'
import AddButton from '../../common/buttons/AddButton'
import { hasPermissions } from '../../common/permissionControl'
import Permissions from '../../../permissionsList'
import GradeForm, { Fields } from './GradeForm'
import Snackbar from '../../common/Snackbar'
import CustomJoyride from '../../common/CustomJoyride'
import GradeFormScreenshot from '../../../assets/joyride/AddingGrade.png'
import GradeViewScreenshot from '../../../assets/joyride/ViewingGrade.png'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

interface Props {
  // None.
}

interface State {
  grades: Grade[]
  contracts: Contract[]
  dialogOpen: boolean
  submitted: boolean
  submitting: boolean
  error: null | string
  showDisabled: boolean
  loading: boolean
}

export default class GradeList extends Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      grades: [],
      contracts: [],
      dialogOpen: false,
      submitting: false,
      submitted: false,
      error: null,
      showDisabled: false,
      loading: false
    }
    this.loadGrade = this.loadGrade.bind(this)
    this.loadContracts = this.loadContracts.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  async loadGrade () {
    this.setState({ loading: true })
    var response = await api(`grade?showDisabled=${this.state.showDisabled}`) as PagedApiResponse<Grade[]>
    if (response.status === 200) return this.setState({ grades: response.payload, loading: false })
  }
  async loadContracts () {
    var response = await api('/contract')
    if (isErrorResponse(response)) return
    this.setState({ contracts: response.payload })
  }

  async componentDidMount () {
    this.setState({ loading: true })
    this.loadGrade().catch(e => console.log(e))
    this.loadContracts().catch(e => console.log(e))
    this.setState({ loading: false })
  }

  handleOpen () {
    this.setState({ dialogOpen: true })
  }

  handleClose () {
    this.setState({ dialogOpen: false })
  }

  handleDisableToggle () {
    this.setState({ showDisabled: !this.state.showDisabled }, this.loadGrade)
  }

  onSubmit = async (values: Fields) => {
    this.setState({ submitting: true, error: null })
    var response = await api('grade', { method: 'POST', body: JSON.stringify(values) })
    if (isErrorResponse(response)) return this.setState({ submitting: false, error: response.error })
    this.setState({ submitted: true, submitting: false })
    setTimeout(() => this.setState({ submitted: false }), 2000)
    this.setState({ dialogOpen: false }, this.loadGrade)
  }

  render () {
    const grades: any[] = []
    this.state.grades.forEach(grade => {
      grades.push(<GradeListItem grade={grade} key={grade.id} contracts={this.state.contracts} onUpdate={this.loadGrade} />)
    })
    return (
      <PaperSheet title='View Grades' rightSideContent={<AddButton title='Add a New Grade' id='add' onClick={this.handleOpen}/>}>
        <CustomJoyride
          continuous
          steps={[
            {
              target: '#add',
              content: (
                <div>
                  Click this button to add a Contract Type
                  <br />
                  <img alt='Grade Form Screenshot' style={{ maxWidth: '100%' }} src={GradeFormScreenshot} />
                </div>
              )
            },
            {
              target: '#list',
              content: (
              <div>
                This is the Grade list where you can view all the contract-types in the system, clicking on a grade will allow you to view and edit it
                <br />
                <img alt='Grade View Screenshot' style={{ maxWidth: '100%' }} src={GradeViewScreenshot} />
              </div>
              )
            }
          ]}
        />
        {hasPermissions([Permissions.SUPER_CONFIGURATION]) && <Table id='list'>
          <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
          <Grid container justify='space-between'>
              <DialogTitle> Add New Grade</DialogTitle>
              <DialogActions>
              <Button onClick={this.handleClose} color='primary' >
                Cancel
              </Button>
            </DialogActions>
            </Grid>
            <DialogContent>
              <GradeForm
                submitted={false}
                submitting={this.state.submitting}
                onSubmit={this.onSubmit} />
            </DialogContent>
          </Dialog>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormControlLabel control={
                  <Checkbox
                    name={'showDisabled'}
                    checked={this.state.showDisabled}
                    onChange={ () => this.handleDisableToggle() }
                  />}
                label = 'Show disabled activities' />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='center' colSpan={6}>Grade name</TableCell>
              <TableCell align='center' colSpan={6}>Disabled?</TableCell>
              <TableCell align='center' colSpan={6}>Contract</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.loading && <TableRow><TableCell align='center'colSpan={4}><CircularProgress /></TableCell></TableRow>}
            {grades}
          </TableBody>
          <TableFooter>
            <TableRow />
          </TableFooter>
        </Table>}
        {this.state.error && <Snackbar message={this.state.error}></Snackbar>}
        {this.state.submitted && <Snackbar message='Submitted successfully!' />}
      </PaperSheet>
    )
  }
}
