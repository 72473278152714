import React from 'react'
import { FormikConfig, FormikValues, Formik, FormikActions } from 'formik'
import { Prompt } from 'react-router'

interface Props<Values = FormikValues> extends FormikConfig<Values> {
  isDisabled?: boolean
  disableUnsavedPrompt?: boolean
  /**
   * This will stop the unsaved changes prompt appearing. When toggled to true the current form values are captured as initial so further changes are recognised.
   */
  submitted: boolean
}

interface State<Values> {
  capturedFormValues: Values | null
  hasSubmitted: boolean
}

export default class CustomFormik<Values> extends React.Component<Props<Values>, State<Values>> {
  state = {
    hasSubmitted: false,
    capturedFormValues: null
  }

  hasValidated = false

  componentDidUpdate (prevProps: Props<Values>) {
    if (this.props.submitted !== prevProps.submitted) {
      if (this.props.submitted) this.setState({ hasSubmitted: true })
    }
  }

  onSubmitForm = (values: Values, formikActions: FormikActions<Values>) => {
    this.setState({ capturedFormValues: values }, () => this.props.onSubmit(values, formikActions))
  }

  componentWillUnmount () {
    window.dirtyFormikExists = false
  }

  render () {
    var { isDisabled, render, disableUnsavedPrompt, initialValues, onSubmit, ...otherProps } = this.props

    return (
      <context.Provider value={isDisabled || false}>
        <Formik {...otherProps} enableReinitialize initialValues={this.state.hasSubmitted ? this.state.capturedFormValues! : initialValues} onSubmit={this.onSubmitForm} render={formProps => {
          if (!this.hasValidated) {
            setTimeout(formProps.validateForm, 10)
            this.hasValidated = true
          }
          if (!disableUnsavedPrompt) window.dirtyFormikExists = formProps.dirty
          return (
            <React.Fragment>
              {/* <pre>{JSON.stringify(formProps, null, 2)}</pre> */}
              {!disableUnsavedPrompt && <Prompt message='You have unsaved changes on this form. Do you wish to continue leaving?' when={formProps.dirty} />}
              {render && render(formProps)}
            </React.Fragment>
          )
        }} />
      </context.Provider>
    )
  }
}

var context = React.createContext<boolean>(false)

export interface FormDisabledProps {
  isDisabled: boolean
}

/**
 * A higher order component (HOC). Any component exported through this function will
 * receive a boolean. Handy, eh?
 * The manic Pick prop things are the result of help from StackOverflow: https://stackoverflow.com/a/50613946.
 */
export function withFormDisabled<P extends FormDisabledProps> (Component: React.ComponentType<P>) {
  var wrapper = function (props: Pick<P, Exclude<keyof P, keyof FormDisabledProps>>) {
    return (
      <context.Consumer>
        {(isDisabled) => {
          return <Component isDisabled={isDisabled} {...props as P} />
        }}
      </context.Consumer>
    )
  }
  Object.defineProperty(wrapper, 'name', { value: `withFormDisabled(${Component.name})` })
  return wrapper
}
