import React, { Component } from 'react'
import Dialog from '../../common/Dialog'
import { TableRow, TableCell } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'

interface Props extends RouteComponentProps {
  user: User
  roles: Role[]
  onUpdate (): void
}

interface State {
  dialogOpen: boolean
}

class UserListItem extends Component<Props, State> {
  constructor (props: Props, state: State) {
    super(props)
    this.state = {
      dialogOpen: false
    }
  }

  handleOpen = () => {
    this.setState({ dialogOpen: true })
  }

  handleClose = () => {
    this.setState({ dialogOpen: false })
    this.props.onUpdate()
  }
  render () {
    return (
      <React.Fragment>
        <TableRow onClick={this.handleOpen} hover>
          <TableCell align='center' colSpan={6}>{this.props.user.name}</TableCell>
          <TableCell align='center' colSpan={6}>{this.props.user.email}</TableCell>
          <TableCell align='center' colSpan={6}>{(this.props.roles.find(role => role.id === this.props.user.roleId) || { name: 'Unknown' }).name}</TableCell>
          <TableCell align='center' colSpan={6}>{this.props.user.isDisabled ? 'Yes' : 'No'}</TableCell>
        </TableRow>
        <Dialog open={this.state.dialogOpen} handleClose={this.handleClose} user={this.props.user} type={'User'} reloadUserData={this.props.onUpdate}/>
      </React.Fragment>
    )
  }
}

export default withRouter(UserListItem)
