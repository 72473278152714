import React, { Component } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Grid, { GridSize } from '@material-ui/core/Grid'
import api, { isErrorResponse } from '../../../api'
import { FieldConfig, Field, FieldProps } from 'formik'

/**
 * A Formik-compatible field that allows a role or contract type to be selected. It returns a number type - not a string!
 */
export default class RoleContractSelectorField extends Component<FieldConfig & Props> {
  render () {
    return (
      <Field {...this.props} component={Selector} />
    )
  }
}
interface Props {
  disabled?: boolean | undefined
  route: string
  label: string
  xs?: GridSize
  required?: boolean
}
interface State {
  selections: (Role | Contract)[]
}

class Selector extends Component<FieldProps & Props, State> {
  constructor (props: FieldProps & Props) {
    super(props)
    this.state = {
      selections: []
    }
  }

  async componentDidMount () {
    var response = await api<Role[] | Contract[]>(this.props.route + '?isDisabled=false')
    if (isErrorResponse(response)) return
    this.setState({ selections: response.payload })
  }

  render () {
    var errorMessage = this.props.form.touched[this.props.field.name] && this.props.form.errors[this.props.field.name]
    return (
      <Grid item xs={this.props.xs || 4}>
        <TextField
          select
          name={this.props.field.name}
          label={this.props.label}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          value={this.props.field.value || ''}
          onChange={this.props.field.onChange}
          onBlur={this.props.field.onBlur}
          disabled={this.state.selections.length === 0 || this.props.disabled}
          fullWidth
          required={this.props.required}
        >
          {this.state.selections.map(selection => (
            <MenuItem key={selection.id} value={selection.id} disabled={selection.isDisabled}>
              {selection.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    )
  }
}
