import React from 'react'
import PaperSheet from '../components/common/Paper'
import BuildIcon from '@material-ui/icons/Build'
import GroupIcon from '@material-ui/icons/Group'
import DescriptionIcon from '@material-ui/icons/Description'
import SearchIcon from '@material-ui/icons/Search'
import HistoryIcon from '@material-ui/icons/History'
import HelpIcon from '@material-ui/icons/Help'
import withStyles from '@material-ui/core/styles/withStyles'
import { Theme } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import Grid from '@material-ui/core/Grid'
import Cardify from '../components/common/Cardify'
import PermissionControl from '../components/common/permissionControl'
import Permissions from '../permissionsList'
import CustomJoyride from '../components/common/CustomJoyride'

const styles = (theme: Theme) => createStyles({
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing.unit * 2
  }
})

interface Props {
}

class Home extends React.Component {
  render () {
    return (
      <PaperSheet title='Home'>
        <CustomJoyride
          continuous
          steps={[
            {
              target: '#paperSheet',
              content: `Welcome to impacting. This is likely a fresh look on what you're used to.`
            },
            {
              target: '#cpLink',
              content: `The most important feature will likely be here! Clicking this button will take you to a list of all of the CPs, and from there you'll be able to impact on them.`
            },
            {
              target: '#myTeamsLink',
              content: `As an impacting user you should be part of one or more teams. You can view the teams you're a part of here, and click on them to view breakdowns for CPs by their current status and the total impacted days.`
            },
            {
              target: '#searchLink',
              content: `After something in particular? You can search for CPs, users, and teams by their name, or enter a number to try and find CPs which partially match that CP number.`
            },
            {
              target: '#configLink',
              content: `Good news, you're an admin! With great power comes great responsiblity. This page will open to something that looks similar to this one, where you can then go to lists of users, teams, grades, activities, contracts, and non-labour item types and manage them accordingly. Phew.`
            },
            {
              target: '#auditLink',
              content: `Where there's blame there's a claim, probably. The auditing feature allows you to view any changes to the data stored on the system, who did it, and when.`
            },
            {
              target: '#helpLink',
              content: `You won't need this. You've discovered the help tool already!`
            },
            {
              target: '#contactLink',
              content: `If there's any concerns or requests, feel free to message the change management team using the contact details found here.`
            }
          ]}
        />
        <Grid container spacing={40} alignItems='flex-end'>
          <Cardify to='/cp' id='cpLink' label='CPs' iconLabel={DescriptionIcon} background='#2874D0' />
          <PermissionControl
            allowedPermissions={[Permissions.IMPACT_CP_ON_TEAM]}
          >
            <Cardify to='/myteams' id='myTeamsLink' label='My Teams' iconLabel={GroupIcon} background='#9C1EB4' />
          </PermissionControl>
          <Cardify to='/Search' id='searchLink' label='Search' iconLabel={SearchIcon} background='#3C3CC0' />
          <PermissionControl
            allowedPermissions={[Permissions.SUPER_CONFIGURATION]}
          >
            <Cardify to='/config' id='configLink' label='Configuration' iconLabel={BuildIcon} background='#D02863' />
          </PermissionControl>
          <Cardify to='/audit' id='auditLink' label='Audit' iconLabel={HistoryIcon} background='#4B27B0' />
          <Cardify to='/help' id='helpLink' label='Help' iconLabel={HelpIcon} background='#D82C2C' />
        </Grid>
      </PaperSheet>
    )
  }
}
export default withStyles(styles, { withTheme: true })(Home)
