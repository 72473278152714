import React from 'react'
import { Form } from 'formik'
import FormikTextField from '../common/FormikTextField'
import SubmitButton from '../common/buttons/SubmitButton'
import { Grid } from '@material-ui/core'
import PermissionControl from '../common/permissionControl'
import Permissions from '../../permissionsList'
import CustomFormik from '../common/CustomFormik'
import CustomJoyride from '../common/CustomJoyride'

interface Props {
  onSubmit: (values: CACFields) => void
  submitted: boolean,
  submitting: boolean,
  values: CACFields
  isDisabled: boolean
}

export interface CACFields {
  id?: number
  comments: string
  assumptions: string
  caveats: string
}

export default class CACForm extends React.Component<Props> {
  render () {
    return (
      <CustomFormik
        submitted={this.props.submitted}
        isDisabled={this.props.isDisabled}
        initialValues={this.props.values}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false) // I'm not using Formik's submitting tracking. It doesn't have a concept of submitted, so both states should be tracked by the parent.
          this.props.onSubmit(values)
        }}
        render={(formProps) => (
          <Form>
             <CustomJoyride
                scrollOffset={400}
                continuous
                scrollToFirstStep
                steps={[
                  {
                    target: '#commentstext',
                    content: `Add any comments.`
                  },
                  {
                    target: '#assumptiontext',
                    content: `Add any Assumptions.`
                  },
                  {
                    target: '#caveatstext',
                    content: `Add any Caveats.`
                  },
                  {
                    target: '#submitButton',
                    content: `Once you're ready, click this button to submit your comment for this team. They'll then show up on any exports and the totals on the CP details page.`
                  }
                ]}
                />
            <Grid container spacing={40}>
              <FormikTextField name='comments' label='Comments' inputProps={ { maxLength: 400 } } multiline id='commentstext'/>
              <FormikTextField name='assumptions' label='Assumptions' inputProps={ { maxLength: 400 } } multiline id='assumptiontext'/>
              <FormikTextField name='caveats' label='Caveats' inputProps={ { maxLength: 400 } } multiline id='caveatstext' />
              {!this.props.isDisabled && <PermissionControl allowedPermissions={[Permissions.IMPACT_CP_ON_TEAM]}>
                <Grid item xs={2}><SubmitButton onClick={formProps.submitForm} submitting={this.props.submitting} submitted={this.props.submitted} /></Grid>
              </PermissionControl>}
            </Grid>
          </Form>
        )}
      />
    )
  }
}
