import React from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '../components/common/Paper'

/**
 * Any components passed through here will be dynamically loaded using code splitting.
 * The syntax is a bit odd though.
 * @param Component The React.lazy'ed' component. `const codeSplitThing = asyncComponent(React.lazy(() => import('./largeComponent')))`
 */
const asyncComponent = function (Component: React.LazyExoticComponent<any>) {
  return function (props: any) {
    return (
      <React.Suspense fallback={
        <Paper title='Loading page'>
          <Typography align='center' variant='h5'>
            This page is being loaded dynamically...
          </Typography>
        </Paper>
      }>
        <Component {...props} />
      </React.Suspense>
    )
  }
}

export default asyncComponent
