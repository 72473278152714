import React from 'react'
import Joyride, { Props as JoyrideProps } from 'react-joyride'
import { ConfigContextProps, withConfigContext } from '../../ConfigContext'

interface Props extends JoyrideProps, ConfigContextProps {
  disableAutoStart?: boolean
}

/**
 * Imagine if react-joyride hooked into the 'helpActive' of ConfigContext. Well this is that reality, baby!
 */
class CustomJoyride extends React.Component<Props> {
  render () {
    var { configContext, disableAutoStart, callback, ...otherProps } = this.props
    if (!configContext.helpActive) return null

    if (!configContext.helpActive || otherProps.steps.length === 0) return null

    if (!disableAutoStart) otherProps.steps[0].disableBeacon = true

    // 1600 is higher than anything in material-ui.
    return (
      <Joyride
      callback={props => { callback && callback(props); props.lifecycle === 'init' && props.action === 'reset' && configContext.toggleHelp() }}
      scrollOffset={this.props.scrollOffset || 100}
      styles={{ options: { zIndex: 1600 } }}
      {...otherProps} />
    )
  }
}

export default withConfigContext(CustomJoyride)
