import React, { Component } from 'react'
import api, { isErrorResponse } from '../../api'
import TeamListItem from './CPTeamListItem'
import { Table, TableBody, CircularProgress, TableHead, TableCell, Typography } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import isPartOfTeam from '../../utils/IsPartOfTeam'

interface Props extends RouteComponentProps {
  cp: CP
  CPLocked: boolean
  cpTotal: number
}

interface State {
  loading: boolean
  teams: TeamWithTotalLocked[]
  error: null | string
}

class CPTeamList extends Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      error: null,
      loading: false,
      teams: []
    }
    this.loadteam = this.loadteam.bind(this)
  }

  async loadteam () {
    this.setState({ loading: true, teams: [] })
    var response = await api<TeamWithTotalLocked[]>(`/cp/${this.props.cp.id}/team`)
    if (isErrorResponse(response)) return this.setState({ loading: false, error: response.error })
    return this.setState({ teams: response.payload, loading: false })
  }

  async componentDidMount () {
    this.loadteam().catch()
  }

  render () {
    if (this.state.loading) {
      return (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
          <p>Loading team statuses and totals...</p>
        </div>
      )
    }

    return (
      <Table>
        <TableHead>
          {this.state.teams.length > 0 ? <TableCell align='left' colSpan={6} variant='head'><Typography variant='body1'>Impact Days Total: {this.props.cpTotal}</Typography></TableCell> : 'No teams for this contract.' }
        </TableHead>
        <TableBody>
          {/* Sort teams you *are* a part of first. How does this work? I truthfully can't answer that. */}
          {this.state.teams.sort((a, b) => isPartOfTeam(a.id) ? isPartOfTeam(b.id) ? 0 : -1 : 1).map(team => (
            <TeamListItem
              name={team.name}
              key={team.id}
              onClick={() => this.props.history.push('/cp/' + this.props.cp.id + '/impact?team=' + team.id, { cp: this.props.cp, totalDays: team.totalDays })}
              impactingDays={team.totalDays}
              submittedImpact={team.submittedImpact}
              impactCount={team.impactCount}
              teamID={team.id}
              isLocked={team.isLocked}
              noImpact={team.noImpact}
              cpID={this.props.cp.id}
              CPLocked={this.props.CPLocked}
              noNLI={team.noNLI}
              noCAC={team.noCAC}
            />
          ))}
        </TableBody>
      </Table>
    )
  }
}
export default withRouter(CPTeamList)
