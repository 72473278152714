import { InputAdornment, withStyles, createStyles, Theme, WithStyles, Omit } from '@material-ui/core'
import FormikTextField, { FormikTextFieldProps } from './FormikTextField'
import RemoveRedEye from '@material-ui/icons/RemoveRedEye'
import React, { Component } from 'react'

const styles = (theme: Theme) => createStyles({
  eye: {
    cursor: 'pointer'
  }
})

type Props = Omit<FormikTextFieldProps, 'type'> & { validate?: (value: string) => string | Promise<string | undefined> | undefined } & WithStyles

class PasswordInput extends Component<Props, { passwordIsMasked: boolean }> {
  constructor (props: Props) {
    super(props)
    this.state = {
      passwordIsMasked: true
    }
  }

  togglePasswordMask = () => {
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked
    }))
  }

  render () {
    const { classes } = this.props
    const { passwordIsMasked } = this.state

    return (
      <FormikTextField
        type={passwordIsMasked ? 'password' : 'string'}
        {...this.props}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <RemoveRedEye
                className={classes.eye}
                onClick={this.togglePasswordMask}
              />
            </InputAdornment>
          )
        }}
      />
    )
  }
}

const PasswordInputStyled = withStyles(styles)(PasswordInput)

export default PasswordInputStyled
