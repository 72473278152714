// Routes (pages) are defined here.
import React from 'react'
import { Route, BrowserRouter, Switch, RouteProps } from 'react-router-dom'
import asyncComponent from '../utils/AsyncComponent'
import { SessionContextProps, withSessionContext } from '../SessionContext'
import PageNotFound from '../routes/PageNotFound'
import ErrorBoundary from './common/ErrorBoundary'
import Sidebar from './common/Sidebar'
import Footer from './common/Footer'
import NavBar from './common/NavBar'
import Home from '../routes/HomePage'
import CPList from './cps/CPList'
import CPDetailedView from './cps/CPDetailedView'

import Configuration from '../routes/ConfigPage'
import Audit from '../routes/AuditPage'
import NotImplementedYet from '../routes/NotImplementedYetPage'
import Users from './configuration/user/UserList'
import Contracts from './configuration/contracts/ContractsList'
import Activities from './configuration/activities/ActivitiesList'
import Login from '../routes/LoginPage'
import GradeList from './configuration/grade/GradeList'
import CPPage from './cps/CPPage'
import TeamListView from './configuration/teams/TeamListView'
import PasswordReset from '../routes/PasswordReset'
import TeamPage from './configuration/teams/TeamPage'
import TeamDetailedView from './configuration/teams/TeamDetailedView'
import RoleList from './configuration/roles/RoleList'
import ImpactingTabDisplay from './impacting/ImpactingTabDisplay'
import TypeList from './configuration/nonLabourTypes/TypeList'
import MyTeamListView from './myTeams/MyTeamListView'
import MTSTabDisplay from './myTeams/MTSTabDisplay'
import MyTeamPage from './myTeams/MyTeamPage'
import Search from '../routes/SearchPage'
import HelpPage from '../routes/HelpPage'
const SystemPage = asyncComponent(React.lazy(() => import('./configuration/system/SystemPage')))

class Router extends React.Component<SessionContextProps> {
  /**
   * A drop-in replacement to Route that will show the logon screen if the session doesn't exist.
   * WHY IS IN CLASS WTF? We'll steal the router's access to sessionContext from here. This saves passing it through to each AuthenticatedRoute definition.
   */
  AuthenticatedRoute = (props: RouteProps) => {
    if (this.props.sessionContext.isLoggedIn) return <Route {...props} />
    return <Login />
  }

  render () {
    var AuthenticatedRoute = this.AuthenticatedRoute

    return (
      <BrowserRouter>
        <React.Fragment>
          {this.props.sessionContext.isLoggedIn && <PageDecorations />}
          <ErrorBoundary>
            <Switch>
              <Route path='/passwordreset/:resetToken' exact component={PasswordReset} />
              <AuthenticatedRoute path='/passwordreset' exact component={PasswordReset} />
              <AuthenticatedRoute path='/' exact component={Home} />
              <AuthenticatedRoute path='/config' exact component={Configuration} />
              <AuthenticatedRoute path='/search' exact component={Search} />
              <AuthenticatedRoute path='/audit' exact component={Audit} />
              <AuthenticatedRoute path='/help' exact component={HelpPage} />
              <AuthenticatedRoute path='/myteams' exact component={MyTeamListView} />
              <AuthenticatedRoute path='/myteams/team/:Id' exact component={MyTeamPage} />
              <AuthenticatedRoute path='/myteams/:contractId/team/:teamId/cpstati' exact component={MTSTabDisplay} />
              <AuthenticatedRoute path='/notImplementedYet' exact component={NotImplementedYet} />
              <AuthenticatedRoute path='/config/users' exact component={Users} />
              <AuthenticatedRoute path='/config/teams' exact component={TeamListView} />
              <AuthenticatedRoute path='/config/teams/add' exact component={TeamPage} />
              <AuthenticatedRoute path='/config/teams/:Id/details' exact component={TeamDetailedView} />
              <AuthenticatedRoute path='/config/contracts' exact component={Contracts} />
              <AuthenticatedRoute path='/config/activities' exact component={Activities} />
              <AuthenticatedRoute path='/config/grades' exact component={GradeList} />
              <AuthenticatedRoute path='/config/types' exact component={TypeList} />
              <AuthenticatedRoute path='/config/system' exact component={SystemPage} />
              <AuthenticatedRoute path='/cp' exact component={CPList} />
              <AuthenticatedRoute path='/cp/add' exact component={CPPage} />
              <AuthenticatedRoute path='/cp/:id/details' exact component={CPDetailedView} />
              <AuthenticatedRoute path='/cp/:cpID/impact' exact component={ImpactingTabDisplay} />
              <AuthenticatedRoute path='/config/roles' exact component={RoleList} />
              <Route component={PageNotFound} />
            </Switch>
          </ErrorBoundary>
        </React.Fragment>
      </BrowserRouter>
    )
  }
}

export default withSessionContext(Router)

class PageDecorations extends React.Component<{}, { open: boolean }> {
  state = {
    open: false
  }

  handleDrawerClose = () => {
    this.setState({ open: false })
  }

  handleDrawerOpen = () => {
    this.setState({ open: true })
  }

  render () {
    return (
      <React.Fragment>
        <NavBar open={this.state.open} handleDrawerOpen={this.handleDrawerOpen} />
        <Sidebar open={this.state.open} handleDrawerClose={this.handleDrawerClose} />
        <Footer open={this.state.open} />
      </React.Fragment>
    )
  }
}
