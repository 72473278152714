import React, { Component } from 'react'
import { Grid, Typography, Divider } from '@material-ui/core'
import ImpactRow from './ImpactRow'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string'
import CustomJoyride from '../common/CustomJoyride'
import ImpactRowScreenshot from '../../assets/joyride/ImpactRow.png'
import ActivitySelectorScreenshot from '../../assets/joyride/ActivitySelector.png'
import ImpactDaysNoZeroScreenshot from '../../assets/joyride/ImpactDaysNoZero.png'
import RemoveRowButtonScreenshot from '../../assets/joyride/RemoveRowButton.png'
import NoImpactScreenshot from '../../assets/joyride/NoImpact.png'
import api, { isErrorResponse } from '../../api'
import { isBoolean } from 'util'

interface State {
  teamId: number
  currentlyEditingIndex: number
  error: null | string
  otherId: number
  localImpacts: Impact[]
}

interface Props extends RouteComponentProps {
  impacts: Impact[]
  grades: Grade[]
  activities: Activity[]
  deleteImpactRow: (id: number) => void
  refreshImpacts: () => void
  submitImpact: () => void
  cpID: string
  onRequestSubmittingStatus: (state: boolean) => void
  isDisabled: boolean
}

export interface ImpactFields {
  activityId: number | null
  gradeId: number | null
  days: number | null
  documentsAffected: string
  comment: string
  id?: number
  default?: boolean
}

class ImpactForm extends Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      teamId: 0,
      currentlyEditingIndex: -1,
      error: null,
      otherId: 0,
      localImpacts: []
    }
  }

  componentDidMount = async () => {
    var getteam = queryString.parse(this.props.location.search).team
    if (!getteam || getteam instanceof Array) return null
    this.setState({ teamId: parseInt(getteam, 10) })
    var other = await api<Activity>('/activity/name/Other')
    if (isErrorResponse(other)) return
    this.setState({ otherId: other.payload.id })
    this.setState({ localImpacts: this.props.impacts })
  }

  deleteImpactRow = (isDefault: boolean, arrayIndex: number, impactID?: number) => {
    // if it's default, it'll send the id of where it is in the array
    if (!isDefault && impactID) {
      this.props.deleteImpactRow(impactID)
    }
    // removing selected impact from local impacts
    var tempimpacts = this.state.localImpacts
    tempimpacts.splice(arrayIndex, 1)
    this.setState({ localImpacts: tempimpacts, currentlyEditingIndex: -1 })
  }

  changeEditFocus = (index: number) => {
    this.setState({ currentlyEditingIndex: index })
  }

  submitEdit = async (values: ImpactFields) => {
    this.props.onRequestSubmittingStatus(true)
    var currIndex = this.state.currentlyEditingIndex
    this.setState({ currentlyEditingIndex: -1 })
    var getteam = queryString.parse(this.props.location.search).team
    if (!getteam || getteam instanceof Array) return
    var teamId = parseInt(getteam, 10)
    var getcp = this.props.cpID
    var body = JSON.stringify({
      cpId: getcp,
      teamId: teamId,
      activityId: values.activityId,
      gradeId: values.gradeId,
      days: values.days,
      documentsAffected: values.documentsAffected,
      comment: values.comment
    })
    var response
    if (values.id) {
      response = await api('/impacts/' + values.id, { method: 'PUT', body: body })
    } else {
      response = await api('/impacts/', { method: 'POST', body: body })
    }
    this.props.onRequestSubmittingStatus(false)
    if (isErrorResponse(response)) return this.setState({ error: response.error })
    var tempimpacts = this.state.localImpacts
    var newImpact = response.payload
    newImpact.activityId = newImpact.activity
    newImpact.gradeId = newImpact.grade
    if (currIndex >= 0) {
      tempimpacts[currIndex] = newImpact
    } else {
      tempimpacts.unshift(newImpact)
    }
    this.setState({ localImpacts: tempimpacts }) // setState to make sure new value is called
    this.props.submitImpact()
  }

  render () {
    return (
      <Grid id='impactForm' container spacing={0} direction='column'>
        <CustomJoyride
          scrollOffset={400}
          continuous
          scrollToFirstStep
          steps={[
            {
              placementBeacon: 'top',
              target: '#impactForm',
              content: `This is the impacting form. Any impacts for this team on this CP can be entered here. Each row represents an activity, grade, the number of days, and optionally a comment and lists of documents affected.`
            },
            {
              target: document.getElementById('impactRow0') ? '#impactRow0' : '#impactForm',
              content: (
                <div>
                  You're in control of how many rows you use.
                  An existing row is only editable and removable if you click the pencil button on the right of it first.
                  This option may not be available if the team or CP is currently locked, or you do not have permissions to do so.
                  <br/>
                  <img alt='Impact Row' style={{ maxWidth: '100%' }} src={ImpactRowScreenshot} />
                </div>
              )
            },
            {
              target: document.getElementsByClassName('activitySelectorField').length > 0 ? '.activitySelectorField' : '#impactForm',
              content: (
                <div>
                  The activity and grade columns are simple selectors. Pick the combinations you require.
                  <br/>
                  <img alt='Activity Selector Screenshot' style={{ maxWidth: '50%' }} src={ActivitySelectorScreenshot} />
                </div>
              )
            },
            {
              target: '#addImpactButton',
              content: `You are free to add a new row at any time - this will appear as a blank row at the bottom. You'll want to do this if an activity needs an impact at more than one grade or if the default rows have been deleted.`
            },
            {
              target: document.getElementsByClassName('impactDaysBox').length > 0 ? '.impactDaysBox' : '#impactForm',
              content: (
                <div>
                  The impact is the number of days of work for this row's grade and activity. If you are entering an impact of 0, you must specify some documents affected or a comment.
                  If you do not wish to impact for this activity or grade and no comment is neccessary, remove the row using the button on the left.
                  <br/>
                  <img alt='Impact Days Screenshot' style={{ maxWidth: '100%' }} src={ImpactDaysNoZeroScreenshot} />
                </div>
              )
            },
            {
              target: document.getElementsByClassName('impactRemoveRowButton').length > 0 ? '.impactRemoveRowButton' : '#impactForm',
              content: (
                <div>
                  Any rows surplus to requirements can easily be removed using the bin icon to the left of them when in editing mode.
                  <br/>
                  <img alt='Remove Row Button Screenshot' style={{ maxWidth: '50%' }} src={RemoveRowButtonScreenshot} />
                </div>
              )
            },
            {
              target: '#impactForm',
              content: (
                <div>
                  What if this team has no impacts? An option will appear below the form to submit an explicit "no impact".
                  You must have no impacts and non-labour items for this option to appear.
                  <br/>
                  <img alt='No Impact Screenshot' style={{ maxWidth: '100%' }} src={NoImpactScreenshot} />
                </div>
              )
            },
            {
              target: '#submitButton',
              content: `Once you're ready, click this button to submit your impacts for this team. They'll then show up on any exports and the totals on the CP details page.`
            },
            {
              target: '#non-labour',
              content: `Click here to add Non-Labour Items`
            },
            {
              target: '#comments',
              content: `Click here to add comments, assumptions & caveats`
            },
            {
              target: '#audit',
              content: `Click here to check audits`
            },
            {
              target: '#non-labourtable',
              content: `Click here to add Labour Items`
            }
          ]}
        />
        {!this.props.isDisabled &&
          <React.Fragment>
            <ImpactRow
              newRowStyling
              grades={this.props.grades}
              activities={this.props.activities}
              onSubmit={this.submitEdit}
              editing={true}
              deleteButtonEnabled={false}
              otherId={this.state.otherId}
            />
            <div style={{ marginBottom: '15px', marginTop: '10px' }}>
              <Divider />
            </div>
          </React.Fragment>}

        {this.state.localImpacts.map((impact: Impact, index: number) => (
          <ImpactRow
            values={impact}
            canEdit={this.state.currentlyEditingIndex === -1 && !this.props.isDisabled}
            row={index}
            key={'index' + impact.activityId + impact.id}
            grades={this.props.grades}
            activities={this.props.activities}
            editing={this.state.currentlyEditingIndex === index}
            toggleEdit={this.changeEditFocus}
            onSubmit={this.submitEdit}
            deleteButtonEnabled={this.state.currentlyEditingIndex === index}
            deleteImpactRow={this.deleteImpactRow}
            otherId={this.state.otherId}/>
        ))}
        {this.props.impacts.length === 0 &&
        <Typography align='center' variant='h4' style={{ fontStyle: 'italic' }}>
          No impacts
        </Typography>}
      </Grid>
    )
  }
}

export default withRouter(ImpactForm)
