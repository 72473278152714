import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Select from 'react-select'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import CancelIcon from '@material-ui/icons/Cancel'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import api, { isErrorResponse } from '../../../api'
import { Field } from 'formik'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  input: {
    display: 'flex',
    padding: 0
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'auto',
    maxHeight: 200
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 500,
    [theme.breakpoints.up('md')]: {
      minWidth: 600,
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 900,
    },
    ['@media(min-width: 1400px)'] : {
      minWidth: 1000
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: 1100,
    },
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    maxHeight: 500
  },
  divider: {
    height: theme.spacing.unit * 2
  },
  option: {
    maxHeight: 500
  }
})

function NoOptionsMessage (props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent ({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control (props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Option (props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
      className={props.selectProps.classes.option}
    >
      {props.children}
    </MenuItem>
  )
}

function Placeholder (props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function ValueContainer (props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

function MultiValue (props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.children === 'Other' ? null : props.removeProps.onClick}
      deleteIcon={props.children === 'Other' ? null : <CancelIcon {...props.removeProps} />}
    />
  )
}

const components = {
  Control,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer
}

/**
 * A Formik-compatible field that allows a contract type to be selected. It returns a number type - not a string!
 */
export default class ActivityMultiSelectorField extends React.Component {
  render () {
    return (
      <Field {...this.props} component={stylishSelect} />
    )
  }
}

class UnstyledSelectorField extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activities: []
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = (value) => {
    var IDs = value.map(v => v.value) // We just want the IDs, none of the label bollocks.
    this.props.form.setFieldValue(this.props.field.name, IDs)
  }

  async componentDidMount (IDBObjectStore) {
    var response = await api('/activity/?showDisabled=false')
    if (isErrorResponse(response)) return
    this.setState({ activities: response.payload })
  }

  findActivity = (activityId) => {
    return (this.state.activities.find(activity => activity.id === activityId) || { name: 'Unknown' }).name
  }

  render () {
    const { classes, theme } = this.props

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        }
      })
    }

    var selected = (this.props.field.value || []).map((value) => ({ value: value, label: this.findActivity(value) }))
    return (
      <div className={classes.root}>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <Select
              classes={classes}
              styles={selectStyles}
              textFieldProps={{
                label: 'Default Activities',
                InputLabelProps: {
                  shrink: true
                }
              }}
              options={this.state.activities.map(activity => ({
                value: activity.id,
                label: activity.name
              }))}
              isDisabled={this.state.activities.length === 0}
              components={components}
              onChange={this.handleChange}
              value={selected}
              placeholder='Select Activities'
              isMulti
              maxMenuHeight={190}
              isSearchable
              menuPlacement='auto'
              menuPosition='absolute'
            />
          </FormControl>
        </Grid>
      </div>
    )
  }
}

UnstyledSelectorField.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

const stylishSelect = withStyles(styles, { withTheme: true })(UnstyledSelectorField)
