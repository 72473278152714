import React, { Component } from 'react'
import { TableRow, TableCell, Fab, Tooltip } from '@material-ui/core'
import api, { isErrorResponse } from '../../api'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { hasPermissions } from '../common/permissionControl'
import Permissions from '../../permissionsList'
import isPartOfTeam from '../../utils/IsPartOfTeam'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import PersonIcon from '@material-ui/icons/Person'

interface Props extends RouteComponentProps {
  name: string
  contractId: number
  teamId: number
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}
interface State {
  contract: any
  loading: boolean
}
class MyTeamListItem extends Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      contract: {},
      loading: false
    }
  }

  async componentDidMount () {
    this.setState({ loading: true })
    var response = await api<Contract>('/contract/' + this.props.contractId)
    if (isErrorResponse(response)) return
    this.setState({ contract: response.payload, loading: false })
  }

  render () {
    var isInTeam = isPartOfTeam(this.props.teamId)
    var isTeamOwner = isPartOfTeam(this.props.teamId, true)
    var contract: Contract = this.state.contract
    return (
      <TableRow style={{ cursor: 'pointer' }} hover>
        <TableCell>
          <Tooltip title={isInTeam ? isTeamOwner ? 'You can manage this team' : 'You can impact for this team' : undefined }>
            <div>
              {isInTeam ? isTeamOwner ? <BusinessCenterIcon /> : <PersonIcon /> : undefined}
            </div>
          </Tooltip>
        </TableCell>
        <TableCell align='center' onClick={this.props.onClick}>{this.props.name}</TableCell>
        <TableCell align='center' onClick={this.props.onClick}>{contract.name}</TableCell>
        <TableCell align='center'>
          <Fab
          variant='extended'
          aria-label='Maintain Users'
          style={{ backgroundColor: 'green', color: '#fff' }}
          size='small'
          onClick={() => this.props.history.push('/myteams/team/' + this.props.teamId)}>
            {hasPermissions([Permissions.MANAGE_USERS_ON_TEAM]) && isPartOfTeam(this.props.teamId, true) ? 'Maintain Team' : 'View users'}
          </Fab>
        </TableCell>
      </TableRow>
    )
  }
}

export default withRouter(MyTeamListItem)
