import React from 'react'
import api, { isErrorResponse } from '../../api'
import { TabProps } from '@material-ui/core/Tab'
import { AppBar, Tabs, Tab } from '@material-ui/core'
import MyAdminTeamsList from './MyAdminTeamsList'

interface State {
  contracts: Contract[]
  selectedContractID: number
}

interface Props extends TabProps {
  // nothing here!
}

class MyAdminsTeamsListView extends React.Component <Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      contracts: [],
      selectedContractID: 1
    }
  }

  handleChange = (newContractId: number) => {
    this.setState({ selectedContractID: newContractId })
  }

  async componentDidMount () {
    var response = await api<Contract[]>('/contract')
    if (isErrorResponse(response)) return
    this.setState({ contracts: response.payload })
  }
  render () {
    return (
      <React.Fragment>
        <AppBar position={'static'}>
        <Tabs value={this.state.selectedContractID} variant='fullWidth'>
          {this.state.contracts.map(contract => (
            <Tab value={contract.id} label={contract.name} key={contract.id} onClick={() => this.handleChange(contract.id)}/>
          ))}
        </Tabs>
        </AppBar>
        <MyAdminTeamsList contractID={this.state.selectedContractID} />
      </React.Fragment>
    )
  }
}

export default(MyAdminsTeamsListView)
