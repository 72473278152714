import React from 'react'
import { Fab, CircularProgress, Dialog, DialogContent, TextField, DialogTitle, Divider, Typography, Button, DialogActions, Grid } from '@material-ui/core'
import KeyIcon from '@material-ui/icons/VpnKey'
import api, { isErrorResponse } from '../../../api'

interface Props {
  userID: number
  userName: string
}

interface State {
  isRequesting: boolean
  dialogOpen: boolean
  token?: TokenResponse
}

export default class UserPasswordReset extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      isRequesting: false,
      dialogOpen: false
    }
  }

  onRequest = async () => {
    if (this.state.token) return this.setState({ dialogOpen: true })
    this.setState({ isRequesting: true })
    var response = await api<TokenResponse>(`user/${this.props.userID}/reset`, { method: 'POST' })
    if (isErrorResponse(response)) return
    this.setState({ token: response.payload, dialogOpen: true, isRequesting: false })
  }

  handleClose = () => this.setState({ dialogOpen: false })

  render () {
    return (
      <React.Fragment>
        <Fab variant='extended' onClick={this.onRequest} disabled={this.state.isRequesting}>
          {this.state.isRequesting ? <CircularProgress /> : <KeyIcon />} Reset password
        </Fab>
        {this.state.token && <Dialog open={this.state.dialogOpen} fullWidth onClose={this.handleClose}>
          <Grid container justify='space-between'>
            <DialogTitle>Password Reset</DialogTitle>
            <DialogActions>
              <Button onClick={this.handleClose} color='primary' >
                Close
              </Button>
            </DialogActions>
          </Grid>
          <DialogContent>
            <Typography>Please share this link with {this.props.userName}. It will be valid for the next 12 hours.</Typography>
            <Divider />
            <TextField fullWidth value={`${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/passwordreset/${this.state.token.tokenString}`} />
          </DialogContent>
        </Dialog>}
      </React.Fragment>
    )
  }
}
