import React, { Component } from 'react'
import PaperSheet from '../components/common/Paper'
import { Grid, Card, CardContent, Table, TableBody, TableRow, TableFooter, TablePagination, TableHead, TableCell, CircularProgress, TableSortLabel } from '@material-ui/core'
import api, { isErrorResponse, isPagedResponse } from '../api'
import { RouteComponentProps } from 'react-router'
import AuditForm, { Fields } from '../components/auditing/AuditForm'
import ExportButton from '../components/common/buttons/ExportButton'
import Snackbar from '../components/common/Snackbar'
import AuditListItem from '../components/auditing/AuditListItem'
import Typography from '@material-ui/core/Typography'
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions'
import CustomJoyride from '../components/common/CustomJoyride'

interface Props extends RouteComponentProps<{ id: string | undefined }> {
}

interface State {
  submitting: boolean
  submitted: boolean
  loading: boolean
  audits: Audit[]
  error: string | null
  exportIds: number[]
  page: number
  rowsPerPage: number
  total: number
  hasBeenSubmitted: boolean
  retainedValue: Fields
  users: User[]
}
export default class AuditPage extends Component<{}, State> {
  constructor (props: Props, fields: Fields) {
    super(props)
    this.state = {
      submitting: false,
      submitted: false,
      loading: false,
      audits: [],
      error: null,
      exportIds: [],
      page: 0,
      rowsPerPage: 25,
      total: 0,
      hasBeenSubmitted: false,
      retainedValue: {} as Fields,
      users: []
    }
  }
  timeOut () {
    setTimeout(() => this.setState({ submitted: false }), 6000)
  }
  onSubmit = async (values?: Fields) => {
    if (!values) {
      values = this.state.retainedValue
    }
    this.setState({ submitting: true, loading: true, hasBeenSubmitted: false, retainedValue: values })
    var response = await api<Audit[]>(
      `audit?name=${values.users}&type=${
        values.auditType}&subType=${
        values.auditSubType}&fromDate=${
        values.filterDateFrom}&toDate=${
        values.filterDateTo}&page=${
        this.state.page + 1}&pageSize=${
        this.state.rowsPerPage}`,
      { method: 'GET' }
     )
    if (isErrorResponse(response)) return this.setState({ submitting: false, error: response.error, loading: false })
    var ids: number[] = []
    response.payload.forEach(x => {
      ids.push(x.id)
    })
    if (!isPagedResponse(response)) return this.setState({ submitting: false, error: 'Server error - Pagination has failed', loading: false })
    this.setState({ audits: response.payload, total: response.total, submitted: true, hasBeenSubmitted: true, submitting: false, exportIds: ids, loading: false })
    setTimeout(() => this.setState({ submitted: false }), 2000)
  }
  handleChangePage = (event: any, page: number) => {
    this.setState({ page }, this.onSubmit)
  }
  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: event.target.value }, this.onSubmit)
  }
  async loadUsers () {
    this.setState({ loading: true, users: [] })
    var response = await api<User[]>('/users/unpaginated', { method: 'GET' })
    if (isErrorResponse(response)) return this.setState({ loading: false, error: response.error })
    return this.setState({ users: response.payload, loading: false })
  }
  async componentDidMount () {
    await this.loadUsers().catch()
  }

  render () {
    return (
      <PaperSheet
        title='Auditing History'
        rightSideContent={
          <ExportButton exportURL={`audit/xlsx`} ids={this.state.exportIds}/>
        }
      >
            <CustomJoyride
          continuous
          steps={[
            {
              target: '#paperSheet',
              content: 'This page displays any audit logs that you would like to see, based on the filters you submit.'
            },
            {
              target: '#auditForm',
              content: 'This is the form that allows you to filter out any audits you don\'t currently care about. Entering different options will get you different results. You can filter by which user completed an action, or by date, or by what you\'re expecting to have been changed.'
            },
            {
              target: '#export',
              content: 'You can export the audits that are displayed below into an Excel spreadsheet using this button.'
            },
            {
              target: '#pagination',
              content: 'Some searches yield a LOT of results, so the results are put into pages to avoid overloading your computer. Here, you can click on the arrows to navigate between pages, and you can adjust the page size too.'
            },
            {
              target: '#sorting',
              content: 'You can order the search results by clicking on these fields.'
            }
          ]}
        />
        {!this.state.loading && <AuditForm id='auditForm' submitted={ this.state.submitted } submitting={this.state.submitting} onSubmit={this.onSubmit} disabled={false} data={this.state.users}/>}
          {this.state.error && <Snackbar isError autoHideDuration={false} message={this.state.error} />}
        <br />
        <Grid container spacing={40}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Table >
                  <TableHead>
                    <TableRow>
                      <TablePagination
                        id='pagination'
                        rowsPerPageOptions={[10, 25, 40, 50]}
                        count={this.state.total}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        SelectProps={{
                          native: true
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions} />
                    </TableRow>
                    <TableRow id='sorting'>
                      <TableCell align='center'><TableSortLabel>Audit Type</TableSortLabel></TableCell>
                      <TableCell align='center'><TableSortLabel>Sub Type</TableSortLabel></TableCell>
                      <TableCell align='center'><TableSortLabel>Value Change</TableSortLabel></TableCell>
                      <TableCell align='center'><TableSortLabel>Date</TableSortLabel></TableCell>
                      <TableCell align='center'><TableSortLabel>User</TableSortLabel></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.loading && <TableRow><TableCell align='center' colSpan={7}><CircularProgress /></TableCell></TableRow>}
                    {this.state.audits.map(function (audits) {
                      return <AuditListItem audit={audits} key={audits.id} id={audits.id} />
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 40, 50]}
                        count={this.state.total}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        SelectProps={{
                          native: true
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions} />
                    </TableRow>
                  </TableFooter>
                </Table>
                {(this.state.hasBeenSubmitted && this.state.audits.length === 0) && <Typography variant='h5' style={{ marginTop: 20, textAlign: 'center' }}>No results found</Typography>}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </PaperSheet>
    )
  }
}
