import React, { Component } from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import moment from 'moment'

interface Props extends RouteComponentProps {
  cp: CPSC
  id?: string
}

class CPListItem extends Component<Props> {

  render () {
    var title = this.props.cp.title || ''
    if (title.length > 50) title = this.props.cp.title.substring(0, 50) + '...'

    return (
      <TableRow id={this.props.id} hover style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/cp/' + this.props.cp.id + '/details')}>
        <TableCell style={{ paddingLeft: 5 }} align='center' >{this.props.cp.CPNumber}</TableCell>
        <TableCell style={{ paddingLeft: 5 }} align='center'>{title}</TableCell>
        <TableCell align='center' style={{ paddingLeft: 5, color: moment().startOf('day').isAfter(moment.unix(this.props.cp.deadlineDate)) ? 'red' : 'default' }}>{moment.unix(this.props.cp.deadlineDate).format('DD/MM/YYYY')}</TableCell>
        <TableCell style={{ paddingLeft: 5 }} align='center'>{this.props.cp.technicalOwner}</TableCell>
        <TableCell style={{ paddingLeft: 5 }} align='center'>{this.props.cp.projectManager}</TableCell>
        <TableCell style={{ paddingLeft: 5 }} align='center'>{this.props.cp.contract.name}</TableCell>
        <TableCell style={{ paddingLeft: 5 }} align='center'>{this.props.cp.status.name}</TableCell>
      </TableRow>
    )
  }
}

export default withRouter(CPListItem)
