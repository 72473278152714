import React, { Component } from 'react'
import api, { isErrorResponse } from '../../../api'
import ContractsListItem from './ContractsListItem'
import { Table, TableBody, TableFooter, TableRow, TableHead, TableCell, Dialog, Button, DialogActions, DialogContent, DialogTitle, Grid, CircularProgress } from '@material-ui/core'
import PaperSheet from '../../common/Paper'
import AddButton from '../../common/buttons/AddButton'
import { hasPermissions } from '../../common/permissionControl'
import Permissions from '../../../permissionsList'
import ContractsForm, { Fields } from './ContractsForm'
import Snackbar from '../../common/Snackbar'
import CustomJoyride from '../../common/CustomJoyride'
import ContractFormScreenshot from '../../../assets/joyride/AddingContract.png'
import ContractViewScreenshot from '../../../assets/joyride/ViewingContract.png'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

interface Props {
  // None.
}

interface State {
  contracts: Contract[]
  dialogOpen: boolean
  submitted: boolean
  submitting: boolean
  error: null | string
  showDisabled: boolean
  loading: boolean
}

export default class ContractsList extends Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      contracts: [],
      dialogOpen: false,
      submitting: false,
      submitted: false,
      error: null,
      showDisabled: false,
      loading: false
    }
    this.loadContract = this.loadContract.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  async loadContract () {
    this.setState({ loading: true })
    var response = await api(`contract?showDisabled=${this.state.showDisabled}`) as PagedApiResponse<Contract[]>
    if (response.status === 200) return this.setState({ contracts: response.payload, loading: false })
  }

  async componentDidMount () {
    this.loadContract().catch(e => console.log(e))
  }

  handleOpen () {
    this.setState({ dialogOpen: true })
  }

  handleClose () {
    this.setState({ dialogOpen: false })
  }

  handleDisableToggle () {
    this.setState({ showDisabled: !this.state.showDisabled }, this.loadContract)
  }

  onSubmit = async (values: Fields) => {
    this.setState({ submitting: true, error: null })
    var response = await api('contract', { method: 'POST', body: JSON.stringify(values) })
    if (isErrorResponse(response)) return this.setState({ submitting: false, error: response.error })
    this.setState({ submitted: true, submitting: false })
    setTimeout(() => this.setState({ submitted: false }), 2000)
    this.setState({ dialogOpen: false }, this.loadContract)
  }

  render () {
    const contracts: any[] = []
    this.state.contracts.forEach(contract => {
      contracts.push(<ContractsListItem contract={contract} key={contract.id} onUpdate={this.loadContract} />)
    })
    return (
    <PaperSheet title='View Contracts' rightSideContent={<AddButton title='Add a New Contract' id='add' onClick={this.handleOpen}/>}>
      <CustomJoyride
        continuous
        steps={[
          {
            target: '#add',
            content: (
              <div>
                Click this button to add a Contract Type
                <br />
                <img alt='Contract Form Screenshot' style={{ maxWidth: '100%' }} src={ContractFormScreenshot} />
              </div>
            )
          },
          {
            target: '#list',
            content: (
              <div>
                This is the Contract-types list where you can view all the contract-types in the system, clicking on a contract will allow you to view and edit it
                <br />
                <img alt='Contract View Screenshot' style={{ maxWidth: '100%' }} src={ContractViewScreenshot} />
              </div>
            )
          }
        ]}
        />
        {hasPermissions([Permissions.SUPER_CONFIGURATION]) && <Table id='list'>
          <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
          <Grid container justify='space-between'>
              <DialogTitle> Add New Contract Type</DialogTitle>
              <DialogActions>
              <Button onClick={this.handleClose} color='primary' >
                Cancel
              </Button>
            </DialogActions>
            </Grid>
            <DialogContent>
              <ContractsForm
                submitted={false}
                submitting={this.state.submitting}
                onSubmit={this.onSubmit} />
            </DialogContent>
          </Dialog>
          <TableHead>
            <FormControlLabel control={
              <Checkbox
                name={'showDisabled'}
                checked={this.state.showDisabled}
                onChange={ () => this.handleDisableToggle() }
              />}
            label = 'Show disabled contracts' />
            <TableRow>
              <TableCell align='center' colSpan={6}>Contract type name</TableCell>
              <TableCell align='center' colSpan={6}>Disabled?</TableCell>
              <TableCell align='center' colSpan={6}>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.loading && <TableRow><TableCell align='center'colSpan={4}><CircularProgress /></TableCell></TableRow>}
            {contracts}
          </TableBody>
          <TableFooter>
            <TableRow />
          </TableFooter>
        </Table>}
        {this.state.error && <Snackbar message={this.state.error}></Snackbar>}
        {this.state.submitted && <Snackbar message='Submitted successfully!' />}
      </PaperSheet>
    )
  }
}
