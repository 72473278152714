import React, { Component } from 'react'
import PaperSheet from '../../common/Paper'
import TeamForm, { Fields as TeamFields } from './TeamForm'
import TeamUserForm, { Fields as UserFields } from './TeamUserForm'
import api, { isErrorResponse } from '../../../api'
import { RouteProps, Redirect } from 'react-router-dom'
import { Tabs, Tab, Typography, AppBar } from '@material-ui/core'
import { TabProps } from '@material-ui/core/Tab'
import { RouteComponentProps } from 'react-router'
import Snackbar from '../../common/Snackbar'
import { hasPermissions } from '../../common/permissionControl'
import Permissions from '../../../permissionsList'
import CustomJoyride from '../../common/CustomJoyride'
import TDetailsFormScreenshot from '../../../assets/joyride/ViewingTDetails.png'

interface Props extends RouteComponentProps<{Id: string}> {}

interface State {
  loading: boolean
  team?: TeamFields
  user?: UserFields
  submitting: boolean
  submitted: boolean
  error: any
  value: number
}

export default class TeamDetailedView extends Component<Props & RouteProps, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      loading: true,
      submitting: false,
      submitted: false,
      error: null,
      value: 0
    }
    this.loadTeam = this.loadTeam.bind(this)
  }

  async componentDidMount () {
    await this.loadTeam()
    this.setState({ loading: false })
  }

  handleChange = (event: any, value: number) => {
    if (window.dirtyFormikExists) {
      if (!window.confirm('You have unsaved changes on this tab. Are you sure you wish to lose them?')) return
    }
    this.setState({ value , submitted: false, submitting: false })
  }

  async loadTeam () {
    var response = await api('/team/' + this.props.match.params.Id, { method: 'GET' })
    if (isErrorResponse(response)) return this.setState({ loading: false, error: response.error })
    return this.setState({ team: response.payload, user: response.payload })
  }

  onTeamSubmit = async (values: TeamFields) => {
    this.setState({ submitting: true })
    var response = await api('team/' + this.props.match.params.Id, { method: 'PUT', body: JSON.stringify(values) })
    if (isErrorResponse(response)) return this.setState({ submitting: false, error: response.error })
    this.setState({ submitted: true, submitting: false })
    setTimeout(() => this.setState({ submitted: false }), 2000)
  }

  onUserSubmit = async (values: UserFields) => {
    this.setState({ submitting: true })
    var response = await api('teamuser/' + this.props.match.params.Id + '/array', { method: 'PUT', body: JSON.stringify(values) })
    if (isErrorResponse(response)) return this.setState({ submitting: false, error: response.error })
    this.setState({ submitted: true, submitting: false })
    setTimeout(() => this.setState({ submitted: false }), 2000)
  }

  render () {
    if (!hasPermissions([Permissions.SUPER_CONFIGURATION])) return <Redirect to={'/myteams/team/' + this.props.match.params.Id} />

    const { value } = this.state
    return (
      <PaperSheet title={(this.state.team ? this.state.team.name + ' - ' : '') + 'Team Details'}>
        <CustomJoyride
          continuous
          steps={[
            {
              target: '#tabs',
              content: (
                <div>
                  Here you can edit the teams details and users using the forms provided
                  <br />
                  <img alt='Team Details Form Screenshot' style={{ maxWidth: '100%' }} src={TDetailsFormScreenshot} />
                </div>
              )
            }
          ]}
        />
        <AppBar position={'static'}>
          <Tabs value={value} variant='fullWidth' onChange={this.handleChange} id='tabs'>
            <Tab label='Team Details'/>
            <Tab label='Add Users'/>
          </Tabs>
        </AppBar>
        {value === 0 && <TabContainer>{!this.state.loading && <TeamForm values={this.state.team} submitted={this.state.submitted} submitting={this.state.submitting} onSubmit={this.onTeamSubmit} disabled={true}/>}{this.state.error && <Snackbar message={this.state.error}></Snackbar>}</TabContainer>}
        {value === 1 && <TabContainer>{!this.state.loading && <TeamUserForm values={this.state.team} submitted={this.state.submitted} submitting={this.state.submitting} onSubmit={this.onUserSubmit}/>}{this.state.error && <Snackbar message={this.state.error}></Snackbar>}</TabContainer>}
      </PaperSheet>
    )
  }
}

function TabContainer (props: TabProps) {
  return (
    <Typography component='div' style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  )
}
