import React from 'react'
import { Snackbar, Fab } from '@material-ui/core'
import ThumbsUpIcon from '@material-ui/icons/ThumbUp'

const hasAccepted = localStorage.getItem('cookieConsent') === 'ok'
if (hasAccepted) console.log('The user has accepted the cookie consent.')

/**
 * A very simple Cookie Consent form to satisfy the EU regulations, probably.
 * Will remember whether the browser has accepted it before and stay closed.
 */
export default class CookieConsent extends React.Component<{}, { closed: boolean }> {
  state = {
    closed: hasAccepted
  }

  onAccept = () => {
    this.setState({ closed: true })
    localStorage.setItem('cookieConsent', 'ok')
  }

  render () {
    return (
      <Snackbar
        open={!this.state.closed}
        message={<span>
          This application uses cookies (HTML5 Web Storage) to uniquely identify active sessions should a refresh occur.
          By continuing you agree that we may store this data on your computer to allow this application to function.
        </span>}
        action={<Fab onClick={this.onAccept} variant='extended' style={{ backgroundColor: 'green', color: 'white' }}>
          <ThumbsUpIcon style={{ marginRight: '0.25rem' }} />
          Accept
        </Fab>}
      />
    )
  }
}
