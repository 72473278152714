import React from 'react'
import { Grid, Theme, WithStyles, Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core'
import FormikTextField from '../../common/FormikTextField'
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from 'formik'
import SubmitButton from '../../common/buttons/SubmitButton'
import PermissionBoxBoss from './PermissionListForm'
import FormikCheckBox from '../../common/FormikCheckbox'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { nameCheck } from '../../../utils/ValidationFunctions'
import CustomFormik from '../../common/CustomFormik'

interface Props extends WithStyles {
  values?: Fields,
  onSubmit: (values: Fields) => void,
  submitted: boolean,
  submitting: boolean,
  onDelete?: (id: number) => void,
  needDelete?: boolean
  isDisabled?: boolean
}
interface State {
  dialogOpen: boolean
}

export interface Fields {
  id: number
  name: string
  permissionIds: number[] | null
  isDisabled: boolean
}
const styles = (theme: Theme) => ({
  root: {
    width: '100%'
  }
})

class RoleForm extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      dialogOpen: false
    }
  }

  handleCancel = () => {
    this.setState({ dialogOpen: false })
  }

  handleOk = (values: any) => {
    this.setState({ dialogOpen: false })
    if (values && this.props.onSubmit) {
      this.props.onSubmit(values)
    }
  }

  handleOpen = () => {
    this.setState({ dialogOpen: true })
  }

  render () {
    return (
      <CustomFormik
        isDisabled={this.props.isDisabled}
        submitted={this.props.submitted}
        initialValues={this.props.values || {
          name: '',
          permissionIds: null,
          isDisabled: false
        } as Fields}
        onSubmit={(values: any, actions: any) => {
          actions.setSubmitting(false) // I'm not using Formik's submitting tracking. It doesn't have a concept of submitted, so both states should be tracked by the parent.
          if (values.isDisabled === true) {
            this.setState({ dialogOpen: true })
          } else {
            this.props.onSubmit(values)
          }
        }}
        render={(formProps: any) => (
          <Form>
            {this.props.values && <Grid item xs={12}>
            </Grid>}
            <FormikTextField name='name' label='Role Name' placeholder='Role Name' type='string' xs={3} required inputProps={ { maxLength: 50 } } validate={nameCheck}/>
            <PermissionBoxBoss name='permissionIds' />
            <Grid item>
              <FormikCheckBox name={'isDisabled'} inForm />
            </Grid>
            <Grid container spacing={24} justify='space-between'>
              <Grid item>
                <SubmitButton onClick={formProps.submitForm} submitting={this.props.submitting} submitted={this.props.submitted}/>
              </Grid>
            </Grid>
            <Dialog open={this.state.dialogOpen} onClose={this.handleCancel}>
              <DialogTitle>Confirmation</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Making a role inactive means that all users currently on this role will be reassigned to the Viewing User Role.
                  <br/><br/>If you reactivate this role, you must re-add users to it manually.
                  <br/><br/>Are you sure you want to do this?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCancel} color='primary'>
                  Cancel
                </Button>
                <Button onClick={() => this.handleOk(formProps.values)} color='primary'>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      />
    )
  }
}

export default withStyles(styles)(RoleForm)
