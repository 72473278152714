import React from 'react'
import { Grid } from '@material-ui/core'
import { Formik, Form } from 'formik'
import SubmitButton from '../common/buttons/SubmitButton'
import FormikDatePicker from '../common/FormikDatePicker'
import AuditSelectorField from './AuditSelectorField'
import EasyStyleMultiSelectorField from '../common/selects/EasyStyleMultiSelector'
import CustomJoyride from '../common/CustomJoyride'
interface Props {
  onSubmit: (values: Fields) => void
  submitted: boolean,
  submitting: boolean,
  values?: Fields,
  disabled?: boolean
  generic?: boolean
  data: User[]
  id?: string
}

export interface Fields {
  auditType: number | null
  users: number | null
  auditSubType: number | null
  filterDateFrom: number | null
  filterDateTo: number | null
}

export default class AuditForm extends React.Component<Props> {
  render () {
    return (
      <Formik
      initialValues={this.props.values || {
        auditType: null,
        users: null,
        auditSubType: null,
        filterDateFrom: null,
        filterDateTo: null
      } as Fields}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false) // I'm not using Formik's submitting tracking. It doesn't have a concept of submitted, so both states should be tracked by the parent.
        this.props.onSubmit(values)
      }}
      render={(formProps) => (
      <Form id={this.props.id}>
        <Grid container spacing={40} id='sorting1'>
            <EasyStyleMultiSelectorField
                name='users'
                route='users/unpaginated/selector'
                disabled={this.props.disabled}
                label='Users'
                placeholder='Select Users'
                data={this.props.data}
              />
              {!this.props.generic && <AuditSelectorField
                route='auditType'
                name='auditType'
                disabled={this.props.disabled}
                label='Audit Type'
              />}
              {!this.props.generic && <AuditSelectorField
                route='auditSubType'
                name='auditSubType'
                disabled={this.props.disabled}
                label='Audit Sub-Type'
              />}
              <FormikDatePicker
                name='filterDateFrom'
                label='Date to filter from'
                startOrEnd='start'
              />
              <FormikDatePicker
                name='filterDateTo'
                label='Date to filter to'
                startOrEnd='end'
              />
              <Grid item xs={2} >
                <SubmitButton
                  onClick={formProps.submitForm}
                  submitting={this.props.submitting}
                  submitted={this.props.submitted}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      />
    )
  }
}
