import React from 'react'
import api, { isErrorResponse, isPagedResponse } from '../../api'
import { Table, TableBody, TableFooter, TableRow, TablePagination, TableHead, TableCell, CircularProgress } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions'
import MTSListItem from './MTSListItem'

interface Props extends RouteComponentProps<{contractId: string, teamId: string}> {
  statusId: number
  teamId: number
}
interface State {
  loading: boolean
  cps: CP[]
  page: number
  rowsPerPage: number
  total: number
  error: null | string
  contractId: number | null
}

class MyTeamList extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      error: null,
      loading: false,
      cps: [],
      page: 0,
      rowsPerPage: 20,
      total: 0,
      contractId: 0
    }
    this.loadCPs = this.loadCPs.bind(this)
  }
  async loadCPs () {
    this.setState({ loading: true, cps: [] })
    var contract = this.props.match.params.contractId
    var response = await api<CP[]>(`cp/contract/${contract}/status/${this.props.statusId}/team/${this.props.teamId}?page=${this.state.page + 1}&pageSize=${this.state.rowsPerPage}`)
    if (isErrorResponse(response)) return this.setState({ loading: false, error: response.error })
    if (!isPagedResponse(response)) return
    return this.setState({ cps: response.payload, total: response.total, loading: false })
  }

  async componentDidMount () {
    this.loadCPs().catch()
  }

  async componentDidUpdate (prevProps: Props) {
    if (prevProps.statusId !== this.props.statusId) {
      this.loadCPs().catch()
    }
  }

  handleChangePage = (event: any, page: number) => {
    this.setState({ page }, this.loadCPs)
  }

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) }, this.loadCPs)
  }
  render () {
    return (
      <React.Fragment>
        <Table >
          <TableHead>
            <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 25]}
                  count={this.state.total}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  SelectProps={{
                    native: true
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions} />
              </TableRow>
              <TableRow>
                <TableCell align='center'>CPNumber</TableCell>
                <TableCell align='center'>Title</TableCell>
                <TableCell align='center'>Total Days</TableCell>
              </TableRow>
                </TableHead>
          <TableBody>
          {this.state.loading && <TableRow><TableCell align='center' colSpan={4}><CircularProgress /></TableCell></TableRow>}
            {this.state.cps.map(cp => (<MTSListItem
            CPNumber={cp.CPNumber}
            title={cp.title}
            totalDays={cp.totalDays}
            noImpact={cp.noImpact}
            submittedImpact={cp.submitAttempted}
            key={cp.id}
            onClick={() => this.props.history.push('/cp/' + cp.id + '/impact?team=' + this.props.teamId)}/>))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 25]}
                count={this.state.total}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                SelectProps={{
                  native: true
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions} />
            </TableRow>
          </TableFooter>
        </Table>
      </React.Fragment>
    )
  }
}
export default withRouter(MyTeamList)
