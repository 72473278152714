import React from 'react'
import { Link } from 'react-router-dom'
import PaperSheet from '../common/Paper'
import { Tabs, Tab, Typography, AppBar, CircularProgress, Chip, Avatar, Button, Card } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { withRouter, RouteComponentProps, StaticContext } from 'react-router'
import queryString from 'query-string'
import api, { isErrorResponse } from '../../api'
import Snackbar from '../common/Snackbar'
import NonLabourForm, { LabourFields } from './NonLabourForm'
import ImpactingForm from './ImpactingForm'
import CACForm, { CACFields } from './CACForm'
import ExportButton from '../common/buttons/ExportButton'
import { hasPermissions } from '../common/permissionControl'
import Permissions from '../../permissionsList'
import isPartOfTeam from '../../utils/IsPartOfTeam'
import CustomJoyride from '../common/CustomJoyride'
import ImpactAuditTable from './ImpactAuditTable'
import NoImpactSwitch from '../common/NoImpactSwitch'

interface State {
  selectedTabIndex: number
  submitted: boolean
  submitting: boolean
  error: null | string
  loading: boolean
  impactValues: Impact[]
  team: Team | null
  cp: CP | null
  daysImpacted: number | null
  grades: Grade[]
  activities: Activity[]
  itemValues: { items: NonLabourItem[] }
  types: NonLabourItemType[]
  cac: CAC | null
  isLocked: boolean
  noImpact: boolean
  hover: boolean
  cptp: CPTP | null
  submittedAttempted: boolean
}

interface Props extends RouteComponentProps<{cpID: string}, StaticContext, { cp?: CP }> {
}

class ImpactingTabDisplay extends React.Component <Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      selectedTabIndex: 0,
      submitting: false,
      submitted: false,
      error: null,
      loading: true,
      itemValues: { items: [] },
      team: null,
      cp: null,
      daysImpacted: null,
      impactValues: [],
      grades: [],
      activities: [],
      types: [],
      cac: null,
      isLocked: false,
      noImpact: false,
      hover: false,
      cptp: null,
      submittedAttempted: false
    }
    this.impactedDays = this.impactedDays.bind(this)
  }

  handleNoImpactToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    var getteam = queryString.parse(this.props.location.search).team
    if (!getteam || getteam instanceof Array) return null
    var teamId = parseInt(getteam, 10)
    var cpId = this.props.match.params.cpID
    this.setState({ submitted: false, submitting: true, error: null })
    if (this.state.noImpact === false) {
      var trueresponse = await api<CPTP>('cptp/cp/' + cpId + '/team/' + teamId, { method: 'POST', body: JSON.stringify({ noImpact: true }) })
      if (isErrorResponse(trueresponse)) return this.setState({ submitting: false, error: trueresponse.error })
      this.setState({ submitting: false, noImpact: true })
    } else {
      var falseresponse = await api<CPTP>('cptp/cp/' + cpId + '/team/' + teamId, { method: 'POST', body: JSON.stringify({ noImpact: false }) })
      if (isErrorResponse(falseresponse)) return this.setState({ submitting: false, error: falseresponse.error })
      this.setState({ submitting: false, noImpact: false })
    }
  }

  handleTabChange = (event: any, value: number) => {
    if (window.dirtyFormikExists) {
      if (!window.confirm('You have unsaved changes on this tab. Are you sure you wish to lose them?')) return
    }
    this.setState({ selectedTabIndex: value , submitted: false, submitting: false })
  }

  componentDidMount = async () => {
    var getteam = queryString.parse(this.props.location.search).team
    if (!getteam || getteam instanceof Array) return null
    var teamId = parseInt(getteam, 10)
    var cpId = this.props.match.params.cpID
    var parallelRequests: Promise<void>[] = []
    var parallelRequestsAfterCPLoad: Promise<void>[] = []
    var cp = (this.props.location.state || {}).cp
    if (!cp) {
      parallelRequests.push(api<CP>('/cp/' + cpId).then(
        response => {
          if (isErrorResponse(response)) throw response.error
          else { this.setState({ cp: response.payload }); cp = response.payload }
        }))
    } else {
      this.setState({ cp })
    }
    parallelRequests.push(
      api<NonLabourItem[]>('/item/cp/' + cpId + '/team/' + teamId).then(
        response => {
          if (isErrorResponse(response)) throw response.error
          else {
            // Hide unfilled/fake NLI to viewing users.
            if (!(!hasPermissions([Permissions.IMPACT_CP_ON_TEAM]) && response.payload.filter(item => item.detail).length === 0)) {
              this.setState({ itemValues: { items: response.payload } })
            }
          }
        }))
    parallelRequests.push(
      api<Impact[]>('/impacts/cp/' + cpId + '/team/' + teamId).then(
        response => {
          if (isErrorResponse(response)) throw response.error
          else this.setState({ impactValues: response.payload })
        }))
    parallelRequests.push(
      api<Team>('/team/' + teamId).then(
        response => {
          if (isErrorResponse(response)) throw response.error
          else this.setState({ team: response.payload })
        }))
    parallelRequests.push(
      api<CAC>('cac/cp/' + cpId + '/team/' + teamId).then(
        response => {
          if (isErrorResponse(response)) throw response.error
          else this.setState({ cac: response.payload })
        }))
    parallelRequests.push(
      api<CPTP>('cptp/cp/' + cpId + '/team/' + teamId).then(
        response => {
          if (isErrorResponse(response)) throw response.error
          else this.setState({ isLocked: response.payload.isLocked, noImpact: response.payload.noImpact, cptp: response.payload, submittedAttempted: response.payload.submitted })
        }))

    try {
      await Promise.all(parallelRequests)
    } catch (errorMessage) {
      this.setState({ error: errorMessage })
    }

    // These ones are dependent on having loaded the CP, therefore they cannot be in parallel with the others
    // if the cp is locked, this request receives disabled entities
    // if the cp isn't locked, this request does not receive disabled entities
    parallelRequestsAfterCPLoad.push(
      api<Activity[]>('/team/' + teamId + '/activities?showDisabled=' + this.state.isLocked).then(
        response => {
          if (isErrorResponse(response)) throw response.error
          else this.setState({ activities: response.payload })
        }))
    parallelRequestsAfterCPLoad.push(
      api<Grade[]>('/contract/' + cp!.contractId + '/grade?showDisabled=' + this.state.isLocked).then(
        response => {
          if (isErrorResponse(response)) return
          this.setState({ grades: response.payload })
        }))
    parallelRequestsAfterCPLoad.push(
      api<NonLabourItemType[]>('/types?showDisabled=' + this.state.isLocked).then(
        response => {
          if (isErrorResponse(response)) throw response.error
          else this.setState({ types: response.payload })
        }))

    try {
      await Promise.all(parallelRequestsAfterCPLoad)
    } catch (errorMessage) {
      this.setState({ error: errorMessage })
    }
    this.setState({ loading: false })
    this.impactedDays()
  }

  onLabour = async (values: LabourFields) => {
    var getteam = queryString.parse(this.props.location.search).team
    if (!getteam || getteam instanceof Array) return null
    var teamId = parseInt(getteam, 10)
    var getcp = this.props.match.params.cpID
    this.setState({ submitted: false, submitting: true, error: null })
    var response = await api('/item/array', { method: 'POST', body: JSON.stringify({
      cpId: getcp,
      teamId: teamId,
      items: values.items
    }) })
    if (isErrorResponse(response)) return this.setState({ submitting: false, error: response.error })
    this.setState({ submitted: true, submitting: false, itemValues: values, noImpact: values.items.length > 0 ? false : this.state.noImpact })
    setTimeout(() => this.setState({ submitted: false }), 2000)
  }

  deleteImpactRow = async (id: number) => {
    this.setState({ submitted: false, submitting: true, error: null })
    var response = await api('/impacts/' + id, { method: 'DELETE' })
    if (isErrorResponse(response)) return this.setState({ submitting: false, error: response.error })
    await this.getImpacts()
  }

  submitImpact = async () => {
    this.setState({ noImpact: false })
  }

  getImpacts = async () => {
    var getteam = queryString.parse(this.props.location.search).team
    if (!getteam || getteam instanceof Array) return null
    var teamID = parseInt(getteam, 10)
    var cpID = this.props.match.params.cpID

    var response2 = await api<Impact[]>('/impacts/cp/' + cpID + '/team/' + teamID)
    if (isErrorResponse(response2)) return this.setState({ submitting: false, error: response2.error })
    this.setState({ impactValues: response2.payload, submitting: false, noImpact: response2.payload.length > 0 ? false : this.state.noImpact })

    this.impactedDays()
    setTimeout(() => this.setState({ submitted: false }), 2000)
  }

  onCAC = async (values: CACFields) => {
    var getteam = queryString.parse(this.props.location.search).team
    if (!getteam || getteam instanceof Array) return null
    var teamId = parseInt(getteam, 10)
    var getcp = this.props.match.params.cpID
    this.setState({ submitted: false, submitting: true, error: null })
    var response = await api('cac/cp/' + getcp + '/team/' + teamId, { method: 'POST', body: JSON.stringify({
      cpId: getcp,
      teamId: teamId,
      cac: values
    }) })
    if (isErrorResponse(response)) return this.setState({ submitting: false, error: response.error })
    this.setState({ submitted: true, submitting: false, cac: values })
    setTimeout(() => this.setState({ submitted: false }), 2000)
  }

  impactedDays = () => {
    var daysImpacted = 0
    this.state.impactValues.forEach((impact) => {
      daysImpacted += impact.days
    })
    this.setState({ daysImpacted })
  }

  toggleHover = () => {
    this.setState({ hover: !this.state.hover })
  }

  // Used to find the size and location of the given tab to cover it.
  tabContainerRef = React.createRef<HTMLDivElement>()

  getTabContainerOverlayStyles = () => {
    var tabContainer = this.tabContainerRef.current!
    const styles: React.CSSProperties = {
      position: 'fixed',
      width: tabContainer.clientWidth,
      height: tabContainer.clientHeight,
      top: tabContainer.offsetTop,
      left: tabContainer.offsetLeft,
      zIndex: 100,
      padding: '10px'
    }
    return styles
  }

  render () {
    var teamQueryString = queryString.parse(this.props.location.search).team
    if (!teamQueryString || teamQueryString instanceof Array) return null
    var teamId = parseInt(teamQueryString, 10)
    const { selectedTabIndex } = this.state

    if (this.state.loading) {
      return (
        <PaperSheet title='Impacting'>
          <div style={{ textAlign: 'center' }}>
            {this.state.loading && <CircularProgress />}
          </div>
        </PaperSheet>
      )
    }

    var formsAreEnabled = !this.state.isLocked && (hasPermissions([Permissions.SUPER_CONFIGURATION]) || (hasPermissions([Permissions.IMPACT_CP_ON_TEAM]) && isPartOfTeam(teamId)))
    return (
      <PaperSheet
        title={<React.Fragment>
                  Impacting <Link style={this.state.hover ? { color: 'black', textDecoration: 'underline' } : { color: 'grey', textDecoration: 'none' }}
                                  onMouseEnter={this.toggleHover}
                                  onMouseLeave={this.toggleHover}
                                  to={'/cp/' + this.props.match.params.cpID + '/details'} > {'CP' + this.state.cp!.CPNumber} </Link>
              </React.Fragment>}
        subtitle={
          <React.Fragment>
            <div>
              Title: {this.state.cp!.title} <br />
              Team: {this.state.team!.name} <br />
              Days Currently Impacted: {this.state.daysImpacted}<br />
            </div>
          </React.Fragment>
        }
        rightSubtitle={
          <div>
            {hasPermissions([Permissions.IMPACT_CP_ON_TEAM]) &&
            isPartOfTeam(teamId) &&
            !this.state.submittedAttempted && <NoImpactSwitch handleNoImpact={this.handleNoImpactToggle} noImpact={this.state.noImpact}/>}
          </div>
        }
        rightSideContent={<ExportButton id='export'
                                        exportURL={`cp/${this.props.match.params.cpID}/team/${teamId}/xlsx`}
                                        fileLabel={'CP' + this.state.cp!.CPNumber.toString() + ' - ' + this.state.team!.name}/>}
        helmetTitle={'Impacting CP' + this.state.cp!.CPNumber.toString()}
      >
        <CustomJoyride
          continuous
          scrollToFirstStep
          disableAutoStart
          steps={[
            {
              placementBeacon: 'top',
              target: '#paperSheet',
              content: `This is the impacting page. Arguably the best bit in the whole app.`
            },
            {
              target: '#tabSelector',
              content: `The impacting experience has multiple aspects. Each of these tabs will show a different form to fill out and submit separately.`
            },
            {
              target: '#export',
              content: `You can export the details of this team's impacts on this CP using this button.`
            },
            {
              target: '#tabSelector',
              content: `The form will have its own help interface. Look out for the corresponding red dot at the top.`
            },
            {
              target: '#noImpactSwitch',
              content: `Toggle this switch the submit a no impact`
            }
          ]}
        />
        {this.state.submitting && <div style={this.getTabContainerOverlayStyles()}>
          <Typography align='center'>
            <CircularProgress />
            <br/>
            Submitting
          </Typography>

        </div>}
        {!hasPermissions([Permissions.SUPER_CONFIGURATION]) &&
         hasPermissions([Permissions.IMPACT_CP_ON_TEAM]) &&
         !isPartOfTeam(teamId) && <Typography align='center'>
          <Chip
            avatar={<Avatar><WarningIcon /></Avatar>}
            label={'Impacting is unavailable because you\'re not a member of this team.'}
            color='primary'
            variant='outlined'
          />
        </Typography>}
        {this.state.isLocked && <Typography align='center'>
          <Chip
            avatar={<Avatar><WarningIcon /></Avatar>}
            label='The CP/team is currently locked.'
            color='primary'
            variant='outlined'
          />
        </Typography>}
        {this.state.noImpact && <Typography align='center'>
          <Chip
            avatar={<Avatar><WarningIcon /></Avatar>}
            label='This team was submitted with no impacts or non-labour items. Adding an impact or item will remove this status.'
            color='primary'
            variant='outlined'
          />
        </Typography>}
        {this.state.team!.guideline && <Typography variant='h6' style={{ marginTop: -10 }}>
          Guidelines: {this.state.team!.guideline}
        </Typography>}
        <br />
        <AppBar position='static' id='tabSelector'>
          <Tabs value={selectedTabIndex} variant='fullWidth' onChange={this.handleTabChange}>
            <Tab label='Impacts'/>
            <Tab label='Non-Labour Items' id='non-labour'/>
            <Tab label='Comments, Assumptions & Caveats' id='comments'/>
            <Tab label='Audit' id='audit'disabled/>
          </Tabs>
        </AppBar>
        <TabContainer innerRef={this.tabContainerRef} shouldBlur={this.state.submitting}>
          {selectedTabIndex === 0 && <ImpactingForm isDisabled={!formsAreEnabled}
                                                    submitImpact={this.submitImpact}
                                                    impacts={this.state.impactValues}
                                                    grades={this.state.grades}
                                                    activities={this.state.activities}
                                                    deleteImpactRow={this.deleteImpactRow}
                                                    refreshImpacts={this.getImpacts}
                                                    onRequestSubmittingStatus={state => this.setState({ submitting: state })}
                                                    cpID={this.props.match.params.cpID}/>}
          {selectedTabIndex === 1 && <NonLabourForm isDisabled={!formsAreEnabled}
                                                    types={this.state.types}
                                                    values={this.state.itemValues}
                                                    submitted={this.state.submitted}
                                                    submitting={this.state.submitting}
                                                    onSubmit={this.onLabour} />}
          {selectedTabIndex === 2 && <CACForm isDisabled={!formsAreEnabled}
                                              values={this.state.cac!}
                                              submitted={this.state.submitted}
                                              submitting={this.state.submitting}
                                              onSubmit={this.onCAC} />}
          {/*selectedTabIndex === 3 && <ImpactAuditTable cpID={this.state.cp!.id}/>*/}
          {/* NLIs pretend to have half filled-out items. The lack of a comment (detail - required) is how we determine whether they're real. */}
        </TabContainer>
        {this.state.error && <Snackbar isError message={this.state.error}></Snackbar>}
      </PaperSheet>
    )
  }
}

function TabContainer (props: { children: React.ReactNode, innerRef?: React.RefObject<HTMLDivElement>, shouldBlur?: boolean }) {
  return (
    <div style={{ padding: 8 * 3, filter: props.shouldBlur ? 'blur(8px)' : undefined }} ref={props.innerRef}>
      {props.children}
    </div>
  )
}

export default withRouter(ImpactingTabDisplay)
