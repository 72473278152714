import React, { Component } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core'
import { SessionContextProps, withSessionContext } from './SessionContext'
import LoginPage from './routes/LoginPage'

class SessionWatcher extends Component<SessionContextProps> {
  render () {
    if (!this.props.sessionContext.expired) return null
    return (
      <Dialog open>
        <DialogTitle>Session has expired</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The server has refused your request due to an invalid token. Please login again:
            <LoginPage embedded />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withSessionContext(SessionWatcher)
