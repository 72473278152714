import React from 'react'
import { Formik, Form } from 'formik'
import FormikTextField from '../../common/FormikTextField'
import FormikCheckBox from '../../common/FormikCheckbox'
import SubmitButton from '../../common/buttons/SubmitButton'
import RoleContractSelectorField from '../../common/selects/RoleContractSelectorField'
import { Grid, Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core'
import { passwordCheck, emailCheck, usernameCheck } from '../../../utils/ValidationFunctions'
import PasswordMask from '../../common/PasswordMask'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import UserPasswordReset from './UserPasswordReset'
import api, { isErrorResponse } from '../../../api'
import Fab from '@material-ui/core/Fab'

interface Props {
  onSubmit: (values: Fields) => void
  submitted: boolean,
  submitting: boolean,
  values?: Fields,
  /**
   * Only embedded if editing.
   */
  embedded?: boolean
  setError?: (text: string) => void
  hasMFA?: boolean
  reloadData?: () => void
}

export interface Fields {
  id: number
  name: string
  email: string
  password?: string
  roleId: number | null
  isDisabled: boolean
}

interface State {
  dialogOpen: boolean
  dialogAccepted: boolean
}

export default class UserForm extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      dialogOpen: false,
      dialogAccepted: false
    }
  }

  handleCancel = () => {
    this.setState({ dialogOpen: false })
  }

  handleOk = (values: any) => {
    this.setState({ dialogOpen: false })
    if (values && this.props.onSubmit) {
      this.props.onSubmit(values)
    }
  }

  handleResetMFA = async () => {
    console.log('Hi')
    var result = await api('users/' + this.props.values!.id.toString() + '/mfa-reset', { method: 'POST' })
    if (isErrorResponse(result)) return this.props.setError!('Could not reset user mfa')
    return this.props.reloadData!()
  }

  render () {
    return (
      <Formik
        initialValues={this.props.values || {
          name: '',
          email: '',
          password: '',
          roleId: null,
          isDisabled: false
        } as Fields}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false) // I'm not using Formik's submitting tracking. It doesn't have a concept of submitted, so both states should be tracked by the parent.
          if (values.isDisabled === true) {
            this.setState({ dialogOpen: true })
          } else {
            this.props.onSubmit(values)
          }
        }}
        render={(formProps) => (
          <Form>
            <Grid container spacing={40}>
              <FormikTextField name='name' label='Name' placeholder='User name' type='string' required validate={usernameCheck}/>
              <FormikTextField name='email' label='Email' placeholder='User email' type='string' required validate={emailCheck}/>
              <RoleContractSelectorField name='roleId' label='Role' validate={(v) => !v ? 'A role is required.' : undefined} route='/role' required/>
              {!this.props.embedded && <PasswordMask name='password' label='Password' placeholder='User password' required validate={passwordCheck}/> }
            </Grid>
            <Grid container spacing={40} justify='space-between'>
              <Grid item>
                <FormikCheckBox name={'isDisabled'} inForm label={'Inactive?'}/>
              </Grid>
            </Grid>
            <Grid container spacing={40}>
              {this.props.values && <React.Fragment>
                {this.props.hasMFA && <Grid item>
                  <Fab onClick={() => this.handleResetMFA()} color='primary' variant='extended'>
                    Reset MFA
                  </Fab>
                </Grid>}
                <Grid item>
                  <UserPasswordReset userID={this.props.values.id} userName={this.props.values.name} />
                </Grid>
              </React.Fragment>}
              <Grid item>
                <SubmitButton onClick={formProps.submitForm} submitting={this.props.submitting} submitted={this.props.submitted} />
              </Grid>
            </Grid>
            <Dialog open={this.state.dialogOpen} onClose={this.handleCancel}>
              <DialogTitle>Confirmation</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Making a user inactive means they won't be able to log in, and they will be removed from any teams they are currently on.
                  <br/><br/>If you reactivate this user, they will be able to log in, but they will not be put back on their teams - this must be done manually.
                  <br/><br/>Are you sure you want to do this?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCancel} color='primary'>
                  Cancel
                </Button>
                <Button onClick={() => this.handleOk(formProps.values)} color='primary'>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      />
    )
  }
}
