import React from 'react'
import PaperSheet from '../components/common/Paper'
import PersonIcon from '@material-ui/icons/Person'
import WorkIcon from '@material-ui/icons/Work'
import GroupIcon from '@material-ui/icons/Group'
import AssignmentIcon from '@material-ui/icons/Assignment'
import TypeIcon from '@material-ui/icons/ShoppingCart'
import ReorderIcon from '@material-ui/icons/Reorder'
import withStyles from '@material-ui/core/styles/withStyles'
import { Theme } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import Grid from '@material-ui/core/Grid'
import Cardify from '../components/common/Cardify'
import PermissionControl from '../components/common/permissionControl'
import Permissions from '../permissionsList'
import CustomJoyride from '../components/common/CustomJoyride'

const styles = (theme: Theme) => createStyles({
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing.unit * 2
  }
})

interface Props {
}

class Configuration extends React.Component {
  constructor (props: Props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (
      <PaperSheet title='Configuration'>
        <PermissionControl allowedPermissions={[Permissions.SUPER_CONFIGURATION]}>
          <React.Fragment>
            <CustomJoyride
              continuous
              steps={[
                {
                  target: '#paperSheet',
                  content: `Welcome to the Admin Hub. This is where you control the system`
                },
                {
                  target: '#users',
                  content: 'This is where you access the Users module to add, view and edit the systems users'
                },
                {
                  target: '#teams',
                  content: 'This is where you access the Teams module to add, view and edit any teams'
                },
                {
                  target: '#roles',
                  content: 'This is where you access the Roles module to add, view and edit the different roles'
                },
                {
                  target: '#contracts',
                  content: 'This is where you access the Contracts module to add, view and edit the different contract types'
                },
                {
                  target: '#types',
                  content: 'This is where you access the Non-Labour item types module to add, view and edit the different Non-Labour item types'
                },
                {
                  target: '#activities',
                  content: 'This is where you access the Activities module to add, view and edit the different activities'
                },
                {
                  target: '#grades',
                  content: 'This is where you access the Grades module to add, view and edit any grades'
                }
              ]}
            />
            <Grid container spacing={40} alignItems='flex-end'>
              <Cardify to='/config/users' label='Users' iconLabel={PersonIcon} background='#228227' id='users' />
              <Cardify to='/config/teams' label='Teams' iconLabel={GroupIcon} background='#3C3CC0' id='teams' />
              <Cardify to='/config/roles' label='Roles' iconLabel={WorkIcon} background='#CB2618' id='roles' />
              <Cardify to='/config/contracts' label='Contracts' iconLabel={AssignmentIcon} background='#6E46B5' id='contracts' />
              <Cardify to='/config/types' label='Non Labour Item Types' iconLabel={TypeIcon} background='#D34542' id='types' />
              <Cardify to='/config/activities' label='Activities' iconLabel={ReorderIcon} background='#DF2765' id='activities' />
              <Cardify to='/config/grades' label='Grades' iconLabel='£' background='#1080A6' id='grades' />
            </Grid>
          </React.Fragment>
        </PermissionControl>
      </PaperSheet>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Configuration)
