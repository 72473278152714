import React, { Component } from 'react'
import api, { isErrorResponse, isPagedResponse } from '../../../api'
import UserListItem from './UserListItem'
import { Table, TableBody, TableFooter, TableRow, TablePagination, TableHead, TableCell, TableSortLabel, Dialog, Button, DialogActions, DialogContent, DialogTitle, Grid, CircularProgress } from '@material-ui/core'
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions'
import PaperSheet from '../../common/Paper'
import AddButton from '../../common/buttons/AddButton'
import { hasPermissions } from '../../common/permissionControl'
import Permissions from '../../../permissionsList'
import UserForm, { Fields } from './UserForm'
import { RouteChildrenProps } from 'react-router'
import Snackbar from '../../common/Snackbar'
import CustomJoyride from '../../common/CustomJoyride'
import UserFormScreenshot from '../../../assets/joyride/AddingUser.png'
import UserViewScreenshot from '../../../assets/joyride/ViewingUser.png'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import moment from 'moment'
interface Props extends RouteChildrenProps {
  // None of our own.
}

interface State {
  loading: boolean
  users: User[]
  roles: Role[]
  page: number
  rowsPerPage: number
  error: null | string
  total: number
  column: string
  direction: 'asc' | 'desc'
  dialogOpen: boolean
  submitted: boolean
  submitting: boolean
  showDisabled: boolean
}

export default class UserList extends Component<{}, State> {
  constructor (props: {}) {
    super(props)
    this.state = {
      error: null,
      users: [],
      roles: [],
      loading: false,
      page: 0,
      rowsPerPage: 50,
      total: 0,
      column: 'name',
      direction: 'asc',
      dialogOpen: false,
      submitting: false,
      submitted: false,
      showDisabled: false
    }
    this.loadUser = this.loadUser.bind(this)
    this.loadRoles = this.loadRoles.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)

  }

  async loadUser () {
    console.log(this.state.showDisabled)
    var response = await api<User[]>(`users?column=${this.state.column}&direction=${this.state.direction}&page=${this.state.page + 1}&pageSize=${this.state.rowsPerPage}&showDisabled=${this.state.showDisabled}`, { method: 'GET' })
    if (isErrorResponse(response)) return this.setState({ loading: false, error: response.error })
    if (!isPagedResponse(response)) return
    return this.setState({ users: response.payload, total: response.total, loading: false })
  }
  async loadRoles () {
    var response = await api('/role')
    if (isErrorResponse(response)) return
    this.setState({ roles: response.payload })
  }
  async componentDidMount () {
    this.setState({ loading: true })
    await this.loadUser().catch()
    await this.loadRoles()
    this.setState({ loading: false })
  }
  handleChangePage = (event: any, page: number) => {
    this.setState({ page }, this.loadUser)
  }
  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: event.target.value }, this.loadUser)
  }

  handleSort = (columnName: string) => {
    return () => {
      this.setState({ column: columnName, direction: this.state.direction === 'asc' ? 'desc' : 'asc' }, this.loadUser)
    }
  }

  handleOpen () {
    this.setState({ dialogOpen: true })
  }

  handleClose () {
    this.setState({ dialogOpen: false })
  }

  handleDisableToggle () {
    this.setState({ showDisabled: !this.state.showDisabled }, this.loadUser)
  }

  onSubmit = async (values: Fields) => {
    this.setState({ submitting: true, error: null })
    var response = await api('user', { method: 'POST', body: JSON.stringify(values) })
    if (isErrorResponse(response)) return this.setState({ submitting: false, error: response.error })
    this.setState({ submitted: true, submitting: false })
    setTimeout(() => {
      this.setState({ submitted: false, submitting: false })
    }, 2000)
    this.setState({ dialogOpen: false }, this.loadUser)
  }

  handleTestReport = async () => {
    var reportResponse = await fetch(window.baseURL + 'users/report', {
      headers: {
        'Authorization': `Bearer ${window.sessionContext.token!.tokenString}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    var blob = await reportResponse.blob()
    var fileName = 'User Report ' + moment().format('DD-MM-YYYY') + '.xlsx'

    var a = document.createElement('a')
    a.href = URL.createObjectURL(blob)
    a.download = fileName
    a.click()
  }

  render () {
    const users: any[] = []
    this.state.users.forEach(user => {
      users.push(<UserListItem user={user} key={user.id} roles={this.state.roles} onUpdate={this.loadUser}/>)
    })
    return (
      <PaperSheet title='View Users' rightSideContent={<AddButton title='Add a New User' id='add' onClick={this.handleOpen}/>}>
        <CustomJoyride
          continuous
          showSkipButton
          steps={[
            {
              target: '#add',
              content: (
                <div>
                  Click this link to open the Users form, where you can add a user
                  <br />
                  <img alt='User Form Screenshot' style={{ maxWidth: '100%' }} src={UserFormScreenshot} />
                </div>)
            },
            {
              target: '#list',
              content: (
                <div>
                  This is the User list where you can view all the users in the system, clicking on a user will allow you to view and edit their details
                  <br />
                  <img alt='User View Form Screenshot' style={{ maxWidth: '100%' }} src={UserViewScreenshot} />
                </div>
              )
            },
            {
              target: '#sort',
              content: 'There are sort buttons that appear next to every column name, click it to sort the specific column it appears next to'
            }
          ]}
        />
        <Button onClick={this.handleTestReport}>Export Report</Button>
        {hasPermissions([Permissions.SUPER_CONFIGURATION]) && <Table id='list'>
          <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
          <Grid container justify='space-between'>
              <DialogTitle> Add New User</DialogTitle>
              <DialogActions>
              <Button onClick={this.handleClose} color='primary' >
                Cancel
              </Button>
            </DialogActions>
            </Grid>
            <DialogContent>
              <UserForm
                submitted={false}
                submitting={this.state.submitting}
                onSubmit={this.onSubmit} />
            </DialogContent>
          </Dialog>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormControlLabel control={
                  <Checkbox
                    name={'showDisabled'}
                    checked={this.state.showDisabled}
                    onChange={ () => this.handleDisableToggle() }
                  />}
                  label = 'Show inactive users' />
                </TableCell>
              <TablePagination
                rowsPerPageOptions={[10, 25, 40, 50]}
                count={this.state.total}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                SelectProps={{
                  native: true
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions} />
            </TableRow>
            <TableRow>
              <TableCell align='center' colSpan={6} sortDirection={'asc' || 'desc'}><TableSortLabel id='sort' active={this.state.column === 'name' ? true : false} direction={this.state.column === 'name' ? this.state.direction : undefined} onClick={this.handleSort('name')}>User name</TableSortLabel></TableCell>
              <TableCell align='center' colSpan={6} sortDirection={'asc' || 'desc'}><TableSortLabel active={this.state.column === 'email' ? true : false} direction={this.state.column === 'email' ? this.state.direction : undefined} onClick={this.handleSort('email')}>Email</TableSortLabel></TableCell>
              <TableCell align='center' colSpan={6} sortDirection={'asc' || 'desc'}><TableSortLabel active={this.state.column === 'roleId' ? true : false} direction={this.state.column === 'roleId' ? this.state.direction : undefined} onClick={this.handleSort('roleId')}>Role</TableSortLabel></TableCell>
              <TableCell align='center' colSpan={6} sortDirection={'asc' || 'desc'}><TableSortLabel active={this.state.column === 'isDisabled' ? true : false} direction={this.state.column === 'isDisabled' ? this.state.direction : undefined} onClick={this.handleSort('isDisabled')}>Inactive?</TableSortLabel></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.loading && <TableRow><TableCell align='center'colSpan={4}><CircularProgress /></TableCell></TableRow>}
            {users}
          </TableBody>
          <TableFooter>
            <TableRow>
            <TablePagination
                rowsPerPageOptions={[10, 25, 40, 50]}
                align='right'
                count={this.state.total}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                SelectProps={{
                  native: true
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions} />
            </TableRow>
          </TableFooter>
        </Table>}
        {this.state.error && <Snackbar isError autoHideDuration={false} message={this.state.error} />}
        {this.state.submitted && <Snackbar message='Submitted successfully!' />}
      </PaperSheet>
    )
  }
}
