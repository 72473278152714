import React from 'react'
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles'
import MaterialSnackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const styles = (theme: Theme) => createStyles({
  close: {
    padding: theme.spacing.unit / 2
  },
  errorBackground: {
    backgroundColor: theme.palette.error.dark
  }
})

interface Props extends WithStyles {
  message: string | null
  onRequestClose?: (e: React.SyntheticEvent<any, Event>) => void
  autoHideDuration?: number | false
  isError?: boolean
}

/**
 * A nice pre-configured Snackbar component. This will show immediately upon rendering, but it can let you know
 * if it wants closing or else it'll just hide itself when the user tries to close it.
 */
class Snackbar extends React.Component<Props> {
  state = {
    open: true
  }

  onClose = () => {
    this.setState({ open: false })
  }

  render () {
    var closeFunction = this.props.onRequestClose || this.onClose
    return (
      <MaterialSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        ContentProps={{ classes: { root: this.props.isError ? this.props.classes.errorBackground : undefined } }}
        open={this.state.open}
        autoHideDuration={this.props.isError ? undefined : this.props.autoHideDuration === false ? undefined : this.props.autoHideDuration || 6000}
        onClose={(e, reason) => reason !== 'clickaway' && closeFunction(e)}
        message={this.props.message}
        action={[
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            className={this.props.classes.close}
            onClick={closeFunction}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    )
  }
}

export default withStyles(styles)(Snackbar)
