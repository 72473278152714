import React, { Component } from 'react'
import api, { isErrorResponse } from '../../../api'
import RoleListItem from './RoleListItem'
import { Table, TableBody, TableRow, TableHead, TableCell, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, CircularProgress } from '@material-ui/core'
import PaperSheet from '../../common/Paper'
import AddButton from '../../common/buttons/AddButton'
import { hasPermissions } from '../../common/permissionControl'
import Permissions from '../../../permissionsList'
import RoleForm from './RoleForm'
import Snackbar from '../../common/Snackbar'
import CustomJoyride from '../../common/CustomJoyride'
import RoleFormScreenshot from '../../../assets/joyride/AddingRole.png'
import RoleViewScreenshot from '../../../assets/joyride/ViewingRole.png'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

interface State {
  roles: Role[]
  dialogOpen: boolean
  submitted: boolean
  submitting: boolean
  error: null | string
  deleted: boolean
  showDisabled: boolean
  loading: boolean
}

interface Props {
  values: Fields
}

interface Fields {
  id: number
  name: string
  permissionIds: number[] | null
}

export default class RoleList extends Component<{}, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      roles: [],
      dialogOpen: false,
      submitting: false,
      submitted: false,
      error: null,
      deleted: false,
      showDisabled: false,
      loading: false
    }
    this.loadRole = this.loadRole.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  async loadRole () {
    this.setState({ loading: true })
    var response = await api(`role?showDisabled=${this.state.showDisabled}`) as ApiResponse<Role[]>
    if (response.status === 200) return this.setState({ roles: response.payload, loading: false })
  }

  async componentDidMount () {
    this.loadRole().catch(e => console.log(e))
  }

  handleOpen = () => {
    this.setState({ dialogOpen: true })
  }

  handleCancel = () => {
    this.setState({ dialogOpen: false })
  }

  handleDisableToggle () {
    this.setState({ showDisabled: !this.state.showDisabled }, this.loadRole)
  }

  // this is currently just for roles! please edit it if you want it to be generic
  onDelete = async (id: number) => {
    var response = await api('role/' + id, { method: 'DELETE' })
    if (isErrorResponse(response)) return this.setState({ submitting: false, error: response.error })
    this.setState({ deleted: true }, this.loadRole)
  }

  onSubmit = async (values: Fields) => {
    this.setState({ submitting: true, error: null })
    var response = await api('role', { method: 'POST', body: JSON.stringify(values) })
    if (isErrorResponse(response)) return this.setState({ submitting: false, error: response.error })
    this.setState({ submitted: true, submitting: false })
    setTimeout(() => this.setState({ submitted: false }), 2000)
    this.setState({ dialogOpen: false }, this.loadRole)

  }
  render () {
    const roles: any[] = []
    this.state.roles.forEach(role => {
      roles.push(<RoleListItem role={role} key={role.id} onDelete={() => this.onDelete(role.id)} handleClose={() => this.loadRole()}/>)
    })
    return (
      <PaperSheet title='View Roles' rightSideContent={<AddButton title='Add a New Role' id='add' onClick={this.handleOpen}/>}>
        <CustomJoyride
          continuous
          steps={[
            {
              target: '#add',
              content: (
                <div>
                  Click this link to open the Roles form, where you can add a role
                  <br />
                  <img alt='Role Form Screenshot' style={{ maxWidth: '100%' }} src={RoleFormScreenshot} />
                </div>)
            },
            {
              target: '#list',
              content: (
                <div>
                  This is the Roles list where you can view all the roles in the system, clicking on a role will allow you to view and edit it
                  <br />
                  <img alt='Role View Screenshot' style={{ maxWidth: '100%' }} src={RoleViewScreenshot} />
                </div>
              )
            }
          ]}
        />
        {hasPermissions([Permissions.SUPER_CONFIGURATION]) && <Table id='list' >
          <Dialog open={this.state.dialogOpen} onClose={this.handleCancel}>
            <Grid container justify='space-between'>
              <DialogTitle> Add New Role</DialogTitle>
              <DialogActions>
              <Button onClick={this.handleCancel} color='primary' >
                Cancel
              </Button>
            </DialogActions>
            </Grid>
            <DialogContent>
              <RoleForm
                submitted={false}
                submitting={this.state.submitting}
                onSubmit={this.onSubmit}
                needDelete={false}/>
            </DialogContent>
          </Dialog>
          <TableHead>
            <FormControlLabel control={
              <Checkbox
                name={'showDisabled'}
                checked={this.state.showDisabled}
                onChange={ () => this.handleDisableToggle() }
              />}
            label = 'Show disabled roles' />
            <TableRow>
              <TableCell align='center'>Name</TableCell>
              <TableCell align='center'>Disabled?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.loading && <TableRow>
              <TableCell align='center'colSpan={4}>
                <CircularProgress />
              </TableCell>
            </TableRow>}
            {roles}
          </TableBody>
        </Table>}
        {this.state.error && <Snackbar message={this.state.error}></Snackbar>}
        {this.state.submitted && <Snackbar message='Submitted successfully!' />}
        {this.state.deleted && <Snackbar message='Deleted successfully!' />}
      </PaperSheet>
    )
  }
}
