import React from 'react'
import { Formik, Form } from 'formik'
import FormikTextField from '../../common/FormikTextField'
import SubmitButton from '../../common/buttons/SubmitButton'
import { Grid } from '@material-ui/core'
import FormikCheckBox from '../../common/FormikCheckbox'
import { nameCheck } from '../../../utils/ValidationFunctions'

interface Props {
  onSubmit: (values: Fields) => void
  submitted: boolean,
  submitting: boolean,
  values?: Fields
  disabled?: boolean
}

export interface Fields {
  id: number
  name: string
  description: string
  isDisabled: boolean
}

export default class TypeForm extends React.Component<Props> {
  render () {
    return (
      <Formik
        initialValues={this.props.values || {
          name: '',
          description: '',
          isDisabled: false
        } as Fields}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false) // I'm not using Formik's submitting tracking. It doesn't have a concept of submitted, so both states should be tracked by the parent.
          this.props.onSubmit(values)
        }}
        render={(formProps) => (
          <Form>
            <Grid container spacing={40}>
              <FormikTextField name='name' label='Name' type='string' required inputProps={ { maxLength: 50 } } validate={nameCheck}/>
              <FormikTextField name='description' label='Description' required inputProps={ { maxLength: 255 } }/>
            </Grid>
            <Grid container spacing={40} justify='space-between'>
              <Grid item>
                <FormikCheckBox name={'isDisabled'} inForm />
              </Grid>
              <Grid item>
                <SubmitButton onClick={formProps.submitForm} submitting={this.props.submitting} submitted={this.props.submitted} />
              </Grid>
            </Grid>
          </Form>
        )}
      />
    )
  }
}
