import React, { Component } from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'

interface Props extends RouteComponentProps {
  name: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

class TeamListItem extends Component<Props> {
  render () {
    return (
      <TableRow style={{ cursor: 'pointer' }} hover onClick={this.props.onClick}>
        <TableCell align='center' colSpan={6}>{this.props.name}</TableCell>
      </TableRow>
    )
  }
}

export default withRouter(TeamListItem)
