import React from 'react'
import { withStyles, createStyles, Button, Dialog,
  DialogActions, DialogContent, DialogTitle, Theme, Grid } from '@material-ui/core'
import api, { isErrorResponse } from '../../api'
import ActivitiesForm from '../configuration/activities/ActivitiesForm'
import UserForm from '../configuration/user/UserForm'
import RoleForm from '../configuration/roles/RoleForm'
import ContractsForm from '../configuration/contracts/ContractsForm'
import GradeForm from '../configuration/grade/GradeForm'
import Snackbar from './Snackbar'
import TypeForm from '../configuration/nonLabourTypes/TypeForm'

const styles = (theme: Theme) => createStyles({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none'
  }
})
interface BaseProps {
  open: boolean
  handleClose (): void
  onDelete? (): void
  reloadUserData? (): void
}

interface UserProps extends BaseProps {
  type: 'User'
  user: User
}
interface RoleProps extends BaseProps {
  type: 'Role'
  role: Role
  isDisabled: boolean
}
interface ContractProps extends BaseProps {
  type: 'Contract'
  contract: Contract
}
interface ActivityProps extends BaseProps {
  type: 'Activity'
  activity: Activity
}
interface GradeProps extends BaseProps {
  type: 'Grade'
  grade: Grade
}
interface NLITProps extends BaseProps {
  type: 'NLIT'
  nonLabourItemType: NonLabourItemType
}
type Props = UserProps | RoleProps | ContractProps | ActivityProps | GradeProps | NLITProps
interface State {
  submitting: boolean
  submitted: boolean
  error: string | null
  errorDialogOpen: boolean
  errorLocations: {id: number, CPNumber: number, name: string}[]
}
class EditDialog extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      error: null,
      submitting: false,
      submitted: false,
      errorDialogOpen: false,
      errorLocations: []
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleErrorDialogClose = () => {
    this.setState({ errorDialogOpen: false })
  }

  handleSetError (errText: string) {
    this.setState({ error: errText })
  }

  handleSubmit = async (values: any) => {
    this.setState({ submitting: true, error: null })
    var response: ApiResponse | ApiErrorResponse = { status: 400, error: 'You haven\'t made a request yet.' }
    if (this.props.type === 'User') {
      response = await api('users/' + this.props.user.id.toString(), { method: 'PUT', body: JSON.stringify(values) })
    } else if (this.props.type === 'Role') {
      response = await api('roles/' + this.props.role.id.toString(), { method: 'PUT', body: JSON.stringify(values) })
    } else if (this.props.type === 'Contract') {
      response = await api('contracts/' + this.props.contract.id.toString(), { method: 'PUT', body: JSON.stringify(values) })
    } else if (this.props.type === 'Activity') {
      response = await api('activities/' + this.props.activity.id.toString(), { method: 'PUT', body: JSON.stringify(values) })
    } else if (this.props.type === 'Grade') {
      response = await api('grades/' + this.props.grade.id.toString(), { method: 'PUT', body: JSON.stringify(values) })
    } else if (this.props.type === 'NLIT') {
      // {PUT} /types/:typeId
      response = await api('types/' + this.props.nonLabourItemType.id.toString(), { method: 'PUT', body: JSON.stringify(values) })
    }
    // hopefully the endpoint will only return a 409 when it sees "you can't disable this entity, it's in use"
    if (response.status === 409) {
      this.setState({ submitting: false, errorDialogOpen: true, errorLocations: response.payload })
      return response
    }
    if (isErrorResponse(response)) {
      this.setState({ submitting: false, error: response.error })
      return response
    }
    this.setState({ submitted: true, submitting: false })
    setTimeout(() => this.setState({ submitted: false }), 2000)
    this.props.handleClose()
    return response
  }

  render () {
    return (
      <React.Fragment>
        {/* this is the normal "edit entity" dialog*/}
        <Dialog open={this.props.open} onClose={this.props.handleClose} aria-labelledby='form-dialog-title'>
          <Grid container justify='space-between'>
            <DialogTitle id='form-dialog-title'>{this.props.type} Information</DialogTitle>
            <DialogActions>
              <Button onClick={this.props.handleClose} color='primary' >
                Cancel
              </Button>
            </DialogActions>
          </Grid>
          <DialogContent>
            {this.props.type === 'Activity' && <ActivitiesForm submitting={this.state.submitting} submitted={false} embedded onSubmit={this.handleSubmit} values={this.props.activity} />}
            {this.props.type === 'User' && <UserForm submitting={this.state.submitting} submitted={false} embedded onSubmit={this.handleSubmit} values={this.props.user } setError={this.handleSetError} hasMFA={this.props.user.hasMFA || this.props.user.mfaSecret !== ''} reloadData={this.props.reloadUserData!} />}
            {this.props.type === 'Role' && <RoleForm submitting={this.state.submitting} submitted={false} onSubmit={this.handleSubmit} values={this.props.role} onDelete={this.props.onDelete} needDelete={true} isDisabled={this.props.isDisabled}/>}
            {this.props.type === 'Contract' && <ContractsForm submitting={this.state.submitting} submitted={false} embedded onSubmit={this.handleSubmit} values={this.props.contract} />}
            {this.props.type === 'Grade' && <GradeForm submitting={this.state.submitting} submitted={false} embedded onSubmit={this.handleSubmit} values={this.props.grade} />}
            {this.props.type === 'NLIT' && <TypeForm submitting={this.state.submitting} submitted={false} onSubmit={this.handleSubmit} values={this.props.nonLabourItemType} />}
          </DialogContent>
        </Dialog>

        {/* this is where the "you can't disable this lad, it's in use" dialog starts*/}
        <Dialog open={this.state.errorDialogOpen} onClose={this.handleErrorDialogClose}>
          <Grid container justify='space-between'>
            <DialogTitle>Conflict</DialogTitle>
            <DialogActions>
              <Button onClick={this.handleErrorDialogClose} color='primary' >
                Cancel
              </Button>
            </DialogActions>
          </Grid>
          <DialogContent>
            {/* The shorthand if statement is because otherwise it says "you cant disable this nlit" - not too user-friendly*/}
            You can't disable this {this.props.type === 'NLIT' ? 'Non Labour Item Type' : this.props.type.toLowerCase()} - it's currently in use in these locations:
            <br/>
            <br/>
            {this.state.errorLocations.map((location: {id: number, CPNumber: number, name: string}) => (
              <div key={location.id}>
                CP: {location.CPNumber}
                <br/>
                Team: {location.name}
                <br/>
                <br/>
              </div>
            ))}
            <br/>
            Please remove these before you try to disable this {this.props.type === 'NLIT' ? 'Non Labour Item Type' : this.props.type.toLowerCase()} again.
          </DialogContent>
        </Dialog>
        {this.state.submitted && <Snackbar message='Submitted successfully!' />}
        {this.state.error && <Snackbar message={this.state.error}></Snackbar>}
      </React.Fragment>
    )
  }
}

// We need an intermediary variable for handling the recursive nesting.
const DialogWrapped = withStyles(styles)(EditDialog)
export default DialogWrapped
