import React, { Component } from 'react'
import { Grid, Fab, Typography } from '@material-ui/core'
import SubmitButton from '../common/buttons/SubmitButton'
import { Form, FieldArray, FormikProps } from 'formik'
import NonLabourRow from './NonLabourRow'
import AddButton from '../common/buttons/AddButton'
import PermissionControl from '../common/permissionControl'
import Permissions from '../../permissionsList'
import CustomFormik from '../common/CustomFormik'
import CustomJoyride from '../common/CustomJoyride'
interface Props {
  onSubmit: (values: LabourFields) => void
  submitted: boolean,
  submitting: boolean,
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  values: LabourFields
  types: NonLabourItemType[]
  isDisabled: boolean
}
export interface LabourFields {
  items: NonLabourItem[]
}

export default class NonLabourForm extends Component<Props> {
  render () {
    return (
      <Grid container spacing={0}>
        <CustomFormik
          submitted={this.props.submitted}
          isDisabled={this.props.isDisabled}
          initialValues={this.props.values}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false) // I'm not using Formik's submitting tracking. It doesn't have a concept of submitted, so both states should be tracked by the parent.
            this.props.onSubmit(values)
          }}
          render={(formProps) => (
            <Form style={{ width: '100%' }}>
              <CustomJoyride
                scrollOffset={400}
                continuous
                scrollToFirstStep
                steps={[
                  {
                    placementBeacon: 'top',
                    target: '#nonaddbutton',
                    content: `You are free to add a new row at any time - this will appear as a blank row at the bottom.`
                  },
                  {
                    target: '#removeEmptyRowsButton',
                    content: `An alternative to deleting every row manually is to click this button. It'll remove any rows without an impact or comment in one sweep.`
                  },
                  {
                    target: '#noonlabourrow',
                    content: `You're in control of how many rows you require.`
                  },
                  {
                    target: '#submitButton',
                    content: `Once you're ready, click this button to submit your impacts for this team. They'll then show up on any exports and the totals on the CP details page.`
                  }
                ]}
                />
              <FieldArray
                name='items'
                render={arrayHelpers => (
                  <React.Fragment>
                    <PermissionControl
                     allowedPermissions={[Permissions.IMPACT_CP_ON_TEAM]}
                    >
                      <Grid container spacing={0} justify='space-evenly' >
                        <Grid item xs={2}>
                          <AddButton onClick={() => arrayHelpers.push({ typeId: null, quantity: null, cost: null, detail: null })} id='nonaddbutton'/>
                        </Grid>
                        <Grid item xs={2}>
                          <Fab id='removeEmptyRowsButton' disabled={this.props.isDisabled} variant='extended' color='primary' onClick={() => deleteEmptyRows(formProps)}> Clear empty rows </Fab>
                        </Grid>
                      </Grid>
                    </PermissionControl>
                    {formProps.values.items.map((item: NonLabourItem, index: number) => (
                      <NonLabourRow row={index} key={index} types={this.props.types} delete={() => arrayHelpers.remove(index)} />
                    ))}
                    {formProps.values.items.length === 0 && <Typography align='center' variant='h4' style={{ fontStyle: 'italic' }}>No non-labour items</Typography>}
                  </React.Fragment>
                )}
              />
              {!this.props.isDisabled && <PermissionControl allowedPermissions={[Permissions.IMPACT_CP_ON_TEAM]}>
                <Grid container spacing={0} justify='flex-end'>
                  <SubmitButton submitted={this.props.submitted} submitting={this.props.submitting} onClick={formProps.submitForm}/>
                </Grid>
              </PermissionControl>}
            </Form>
          )}
        />
      </Grid>
    )
  }
}

function deleteEmptyRows (formProps: FormikProps<LabourFields>) {
  var items = formProps.values.items.filter((item) => !((item.quantity || 0) === 0 && (item.cost || 0) === 0 && (item.detail || '').length === 0))
  formProps.setValues({ items })
}
